<template>
  <div id="work-reference" class="main">
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="日報確認"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="p-4">
            <div class="workHeaderArea">
              <!-- 工事選択 -->
              <div>
                <div class="d-flex w-100">
                  <!-- 日付選択 -->
                  <div class="width:20%">
                    <b-calendar
                      v-model="workDate"
                      label-help
                      locale="ja"
                      :date-info-fn="dateClass"
                      :hide-header="true"
                      @context="onCalendarContext"
                    ></b-calendar>
                  </div>
                  <!-- 工事選択 -->
                  <div style="width: 20%">
                    <b-table-simple small bordered class="h-100">
                      <b-thead class="text-center">
                        <b-tr>
                          <b-th class="themeColoreHeader">分類選択</b-th>
                        </b-tr>
                      </b-thead>
                      <tbody>
                        <b-tr>
                          <td class="align-top">
                            <el-tree
                              :data="catergoryTree"
                              :props="defaultProps"
                              @node-click="handleNodeClick"
                              style="max-height: 220px; overflow-y: auto"
                            ></el-tree>
                          </td>
                        </b-tr>
                      </tbody>
                    </b-table-simple>
                  </div>
                  <div class="flex-grow-1 border-bottom border-right">
                    <b-table
                      bordered
                      small
                      selectable
                      select-mode="single"
                      sticky-header
                      :fields="fieldsConstruction"
                      :items="constructionItems"
                      @row-selected="onConstructionRowSelected"
                      style="max-height: 290px; margin-bottom: 0"
                    ></b-table>
                  </div>
                </div>
              </div>
              <!-- 勤務状況 -->
              <div v-if="dailyReportBaseData != null" class="mt-2">
                <!-- タブ -->
                <div class="pt-1">
                  <div class="d-flex justify-content-between">
                    <b-radio-group
                      v-model="workerTypeSelected"
                      button-variant="info"
                      buttons
                      class="btnSelectWorkerType"
                    >
                      <b-form-radio :value="WORKER_ALL_EMPLOYEE">全て ({{dailyReportWorkerCount}})</b-form-radio>
                      <b-form-radio
                        :value="SALARY_TYPE_MONTH"
                      >月給 ({{ dailyReportWorkerMonthCount }})</b-form-radio>
                      <b-form-radio
                        :value="SALARY_TYPE_DAY"
                        v-if="isUseDailySalary || dailyReportWorkerDayCount > 0"
                      >日給 ({{ dailyReportWorkerDayCount }})</b-form-radio>
                      <b-form-radio :value="SALARY_TYPE_HOUR">時給 ({{ dailyReportWorkerHourCount }})</b-form-radio>
                      <b-form-radio
                        :value="WORKER_TYPE_PARTNER_COMPANY"
                      >協力会社 ({{ dailyReportPartnerCount }})</b-form-radio>
                    </b-radio-group>
                    <b-button variant="success" @click="toWorkBase()">日報入力へ</b-button>
                  </div>
                </div>
                <!--  -->
                <div>
                  <WorkHeader
                    :propDate="strWorkDate"
                    :porpWeather="dailyReportBaseData.weather"
                    :propWorkSite="dailyReportBaseData.construction_name"
                    :propOverview="dailyReportBaseData.job_content"
                    :propResponsibleCd="dailyReportBaseData.responsible_cd"
                    :propResponsibleName="dailyReportBaseData.responsible_name"
                    :propManagerCd="dailyReportBaseData.manager_cd"
                    :propManagerName="dailyReportBaseData.manager_name"
                    :propWorkerList="dailyReportWorkerList"
                  ></WorkHeader>
                </div>
              </div>
            </div>
            <div>
              <div class="pb-2">
                <!-- 自社全体 -->
                <div>
                  <div v-show="workerTypeSelected == WORKER_ALL_EMPLOYEE">
                    <div
                      v-for="(workerItem, wIndex) in this.dailyReportWorkerEmployeeList"
                      :key="wIndex"
                      class="worker_state_content"
                    >
                      <WorkState
                        :worker="workerItem"
                        :rowIndex="wIndex"
                        :workDate="workDate"
                        :isPartnerCompany="false"
                      ></WorkState>
                    </div>
                  </div>
                  <div v-show="workerTypeSelected == WORKER_ALL_EMPLOYEE">
                    <div
                      v-for="(workerItem, pIndex) in this.dailyReportPartnerList"
                      :key="pIndex"
                      class="worker_state_content"
                    >
                      <WorkState
                        :worker="workerItem"
                        :rowIndex="pIndex"
                        :workDate="workDate"
                        :isPartnerCompany="true"
                      ></WorkState>
                    </div>
                  </div>
                </div>
                <!-- 月給 -->
                <div v-show="workerTypeSelected == SALARY_TYPE_MONTH">
                  <div
                    v-for="(workerItem, wIndex) in this
                      .dailyReportWorkerMonthList"
                    :key="wIndex"
                    class="worker_state_content"
                  >
                    <WorkState
                      :worker="workerItem"
                      :rowIndex="wIndex"
                      :workDate="workDate"
                      :isPartnerCompany="false"
                    ></WorkState>
                  </div>
                </div>
                <!-- 日給 -->
                <div v-show="workerTypeSelected == SALARY_TYPE_DAY">
                  <div
                    v-for="(workerItem, wIndex) in this
                      .dailyReportWorkerDayList"
                    :key="wIndex"
                    class="worker_state_content"
                  >
                    <WorkState
                      :worker="workerItem"
                      :rowIndex="wIndex"
                      :workDate="workDate"
                      :isPartnerCompany="false"
                    ></WorkState>
                  </div>
                </div>
                <!-- 時給 -->
                <div v-show="workerTypeSelected == SALARY_TYPE_HOUR">
                  <div
                    v-for="(workerItem, wIndex) in this
                      .dailyReportWorkerHourList"
                    :key="wIndex"
                    class="worker_state_content"
                  >
                    <WorkState
                      :worker="workerItem"
                      :rowIndex="wIndex"
                      :workDate="workDate"
                      :isPartnerCompany="false"
                    ></WorkState>
                  </div>
                </div>
                <!-- 協力会社 -->
                <div v-show="workerTypeSelected == WORKER_TYPE_PARTNER_COMPANY">
                  <div
                    v-for="(workerItem, wIndex) in this.dailyReportPartnerList"
                    :key="wIndex"
                    class="worker_state_content"
                  >
                    <WorkState
                      :worker="workerItem"
                      :rowIndex="wIndex"
                      :workDate="workDate"
                      :isPartnerCompany="true"
                    ></WorkState>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
// import store from "../store";
import BaseHeader from "../components/BaseHeader";
import WorkHeader from "../components/Work/WorkHeader";
import WorkState from "../components/Work/WorkStateForReference";
import moment from "moment";
import config from "../const/config";

export default {
  name: "WorkRegist",
  components: {
    BaseHeader,
    WorkHeader,
    WorkState,
  },
  data() {
    return {
      strWeekDay: config.VALUE.strWeekDay,
      catergoryTree: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      //工事現場リスト
      fieldsConstruction: [
        {
          key: "construction_name",
          label: "工事名",
          thClass: "w-45 themeColoreHeader",
        },
        {
          key: "",
          label: "工期",
          thClass: "w-20 themeColoreHeader",
        },
        {
          key: "responsible_name",
          label: "現場代理人",
          thClass: "w-15 themeColoreHeader",
        },
      ],
      constructionItems: [],

      // 対象日付
      workDate: "",
      // 対象工事コード
      constructionCd: "",
      //作業日別工事別データ
      dailyReportBaseData: {
        work_date: null,
        construction_cd: null,
        construction_name: "",
        job_content: "",
        responsible_cd: null,
        responsible_name: "",
        manager_cd: null,
        manager_name: "",
        weather: "",
      },
      dailyReportWorkerList: [],
      workerTypeSelected: 0,
      WORKER_ALL_EMPLOYEE: 0,
      SALARY_TYPE_MONTH: config.VALUE.SALARY_TYPE_MONTH,
      SALARY_TYPE_DAY: config.VALUE.SALARY_TYPE_DAY,
      SALARY_TYPE_HOUR: config.VALUE.SALARY_TYPE_HOUR,
      WORKER_TYPE_PARTNER_COMPANY: config.VALUE.WORKER_TYPE_PARTNER_COMPANY,
      isUseDailySalary: false,
      //
      calendarContext: null,
      calendarActiveYMD: null,
      calendarYm: null,
      constructionMonthDatelist: [],
    };
  },
  computed: {
    strWorkDate: function () {
      if (this.workDate != null) {
        let re = "";
        re += this.getStrDateSlash(this.workDate);
        let setWeekDay = moment(this.workDate).isoWeekday();
        re += " (" + this.strWeekDay[setWeekDay] + ")";
        return re;
      } else {
        return "";
      }
    },

    //自社全体
    dailyReportWorkerEmployeeList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) => item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 締め区分=月給
    dailyReportWorkerMonthList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) =>
            item.salary_type == config.VALUE.SALARY_TYPE_MONTH &&
            item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 締め区分=日給
    dailyReportWorkerDayList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) =>
            item.salary_type == config.VALUE.SALARY_TYPE_DAY &&
            item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 締め区分=時給
    dailyReportWorkerHourList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) =>
            item.salary_type == config.VALUE.SALARY_TYPE_HOUR &&
            item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 協力会社
    dailyReportPartnerList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) => item.worker_type == config.VALUE.WORKER_TYPE_PARTNER_COMPANY
        );
      } else {
        return null;
      }
    }, //--人数
    dailyReportWorkerCount: function () {
      let count = 0;
      if (this.dailyReportWorkerList != null) {
        count = this.dailyReportWorkerList.length;
      }
      return count;
    },
    //自社全体
    dailyReportWorkerEmployeeCount: function () {
      let count = 0;
      if (this.dailyReportWorkerEmployeeList != null) {
        count = this.dailyReportWorkerEmployeeList.length;
      }
      return count;
    },
    // 締め区分=月給
    dailyReportWorkerMonthCount: function () {
      let count = 0;
      if (this.dailyReportWorkerMonthList != null) {
        count = this.dailyReportWorkerMonthList.length;
      }
      return count;
    },
    // 締め区分=日給
    dailyReportWorkerDayCount: function () {
      let count = 0;
      if (this.dailyReportWorkerDayList != null) {
        count = this.dailyReportWorkerDayList.length;
      }
      return count;
    },
    //締め区分=時給
    dailyReportWorkerHourCount: function () {
      let count = 0;
      if (this.dailyReportWorkerHourList != null) {
        count = this.dailyReportWorkerHourList.length;
      }
      return count;
    },
    // 協力会社
    dailyReportPartnerCount: function () {
      let count = 0;
      if (this.dailyReportPartnerList != null) {
        count = this.dailyReportPartnerList.length;
      }
      return count;
    },
  },
  watch: {
    constructionCd: function () {
      //工事変更時
      if (this.hasRequestBaseData()) {
        this.getDailyReportBaseData();
        this.getDailyReportWorkerInfo();
      } else {
        this.dailyReportBaseData = null;
        this.dailyReportWorkerList = [];
      }
      //選択中工事の作業日取得
      this.getConstructionMonthDatelist();
    },
    workDate: function () {
      //日付変更時
      if (this.hasRequestBaseData()) {
        this.getDailyReportBaseData();
        this.getDailyReportWorkerInfo();
      } else {
        this.dailyReportBaseData = null;
        this.dailyReportWorkerList = [];
      }
    },
    //カレンダー月の変更
    calendarYm: function () {
      //選択中工事の作業日取得
      this.getConstructionMonthDatelist();
    },
  },
  mounted: function () {
    ////給与形態オプション設定
    this.setWorkerTypeOption();
    this.workDate = this.getStrDateHyphen(moment());
    // 工事カテゴリ取得
    this.getCatergoryTree();
    // 工期中工事検索
    // this.getWithPeriodConstruction();
    this.getConstructionListByExistedDataInLastWeek();
  },
  methods: {
    toWorkBase: function () {
      if (this.hasRequestBaseData()) {
        // Vuexに選択されている値を格納
        this.$store.commit("setWorkRegistKey", {
          construction_cd: this.constructionCd,
          work_date: this.workDate,
        });
        this.$router.push(config.PAGES.WORK_BASE);
      } 
    },
    //------------------------------
    //給与形態オプション生成
    setWorkerTypeOption: function () {
      let me = this;
      //日給シートの利用有無の取得
      me.isUseDailySalary = false;
      this.getData(config.URL.DAILY_REPORT + "/is_use_dayly_salary")
        .then((response) => {
          if (response.result) {
            me.isUseDailySalary = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日給シートの利用有無取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    //工事分類初期化
    initCategory: function () {
      this.catergoryTree = [
        {
          label: "すべて",
          treeLevel: config.VALUE.TREE_LEVEL_ALL,
          catergoryLCd: null,
          catergoryMCd: null,
          catergorySCd: null,
        },
        {
          label: "直近一週間",
          treeLevel: config.VALUE.TREE_LEVEL_LAST_WEEK,
          catergoryLCd: null,
          catergoryMCd: null,
          catergorySCd: null,
        },
        {
          label: "工期中",
          treeLevel: config.VALUE.TREE_LEVEL_WITH_PERIOD,
          catergoryLCd: null,
          catergoryMCd: null,
          catergorySCd: null,
        },
      ];
    },
    //工事分類取得
    getCatergoryTree: function () {
      let me = this;

      //初期化
      this.initCategory();
      //サーバーから取得
      this.getDataList(config.URL.CONSTRUCTION + "/getCatergoryTree")
        .then((response) => {
          if (response.result) {
            let categoryList = response.container;
            if (categoryList != null || categoryList.length > 0) {
              categoryList.forEach((element) => {
                me.catergoryTree.push(element);
              });
            }
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    //工事全て取得
    getAllConstruction: function () {
      //分類と工期の絞り込み無しで検索
      this.searchConstruction(null, null, false);
    },
    //工事を工期で絞り込み取得
    getWithPeriodConstruction: function () {
      this.searchConstruction(null, null, true);
    },
    //工事分類選択時
    handleNodeClick: function (data) {
      if (data.treeLevel == config.VALUE.TREE_LEVEL_ALL) {
        this.getAllConstruction();
      } else if (data.treeLevel == config.VALUE.TREE_LEVEL_LAST_WEEK) {
        this.getConstructionListByExistedDataInLastWeek();
      } else if (data.treeLevel == config.VALUE.TREE_LEVEL_WITH_PERIOD) {
        this.getWithPeriodConstruction();
      } else {
        this.searchConstruction(data.catergoryLCd, data.catergorySCd, false);
      }
    },
    //直近1週間にデータの登録がある工事を取得
    getConstructionListByExistedDataInLastWeek: function () {
      let me = this;
      //初期化
      this.constructionItems = [];
      //サーバーから取得
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getData(config.URL.CONSTRUCTION, "/getByExistedDataInLastWeek")
        .then((response) => {
          if (response.result) {
            me.constructionItems = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //工事リスト検索
    searchConstruction: function (catergoryLCd, catergorySCd, isWithinPeriod) {
      let me = this;

      let ConstructionSearchConditions = {
        catergory_l_cd: catergoryLCd,
        catergory_s_cd: catergorySCd,
        isWithinPeriod: isWithinPeriod,
      };

      //初期化
      this.constructionItems = [];
      //サーバーから取得
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(
        config.URL.CONSTRUCTION + config.PROCESS.SEARCH,
        ConstructionSearchConditions
      )
        .then((response) => {
          if (response.result) {
            me.constructionItems = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    onConstructionRowSelected: function (items) {
      if (items == null || items[0] == null) {
        this.constructionCd = "";
      } else {
        this.constructionCd = items[0].construction_cd;
      }
    },
    //------------------------------
    hasRequestBaseData: function () {
      if (
        this.workDate != null &&
        this.workDate != "" &&
        this.constructionCd != null &&
        this.constructionCd != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 工事別日別作業内容データ取得
     */
    getDailyReportBaseData: function () {
      let me = this;
      let param = {
        construction_cd: this.constructionCd,
        target_date: this.workDate,
      };
      this.getWithParams(
        config.URL.DAILY_REPORT_BASE + config.PROCESS.FOR_WORK_REGIST,
        param
      )
        .then((response) => {
          if (response.result) {
            me.dailyReportBaseData = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "工事別日別データ取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    /**
     * 日報の対象者を取得する
     */
    getDailyReportWorkerInfo: function () {
      let me = this;
      me.dailyReportWorkerList = null;
      let param = {
        work_date: this.workDate,
        construction_cd: this.constructionCd,
      };
      this.getDataList(
        config.URL.DAILY_REPORT + config.PROCESS.FOR_WORK_REGIST,
        param
      )
        .then((response) => {
          if (response.result) {
            me.dailyReportWorkerList = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日報データ作業者一覧取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // カレンダレイアウト変更
    dateClass: function (ymd, date) {
      let className = " ";
      //曜日の色設定
      const weekday = date.getDay();
      if (weekday == 0) {
        className += "calendar-text-danger";
      } else if (weekday == 6) {
        className += "calendar-text-info";
      }
      //工事の作業日設定
      const calDate = this.getDate(date);
      if (this.constructionMonthDatelist != null) {
        let dateList = this.constructionMonthDatelist.filter(
          (e) => e.targetDate == calDate
        );
        if (dateList != null && dateList.length > 0) {
          //対象日に選択中工事のデータが存在する場合
          className += " table-orenge  ";
        }
      }

      return className;
    },
    /**
     * カレンダーコンテキスト
     */
    onCalendarContext: function (ctx) {
      this.calendarContext = ctx;
      if (this.calendarContext == null) {
        this.calendarActiveYMD = null;
        this.calendarYm = null;
      } else {
        this.calendarActiveYMD = this.calendarContext.activeYMD;
        //現在表示中のカレンダー年月（年月の変化の検知用）
        this.calendarYm = this.getStrYm(this.calendarContext.activeYMD);
      }
    },
    /**
     * 選択中工事の作業日リストの取得
     */
    getConstructionMonthDatelist: function () {
      let me = this;
      this.constructionMonthDatelist = [];

      if (
        this.isNullOrEmpty(this.constructionCd) ||
        this.isNullOrEmpty(this.calendarActiveYMD)
      ) {
        //工事か対象月が不明の場合、取得不可
        return;
      }

      //--リクエスト生成
      //月初の日付を取得
      let startYmd = this.getTargetMonthStartDate(this.calendarActiveYMD);
      //月末の日付を取得
      let endYmd = this.getTargetMonthEndDate(this.calendarActiveYMD);
      let param = {
        construction_cd: this.constructionCd,
        startTargetDate: startYmd,
        endTargetDate: endYmd,
      };
      //--データ取得
      this.getDataList(
        config.URL.DAILY_REPORT_BASE + "/construction_month_datelist",
        param
      )
        .then((response) => {
          if (response.result) {
            me.constructionMonthDatelist = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "工事作業日取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
  },
};
</script>

<style >
#work-reference {
  min-width: 1500px;
}

#work-reference .work_header .navbar {
  padding: 0rem 0rem;
}

.b-calendar .b-calendar-header,
.b-calendar .b-calendar-nav {
  margin-top: 0.2rem;
}
#work-reference .btnSelectWorkerType .btn {
  width: 200px;
}

#work-reference .table-orenge {
  background: rgb(252, 234, 134);
}
.b-calendar .b-calendar-grid-body .col[data-date] {
  border: 1px solid white;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 30px;
  height: 30px;
}
/* 
#work-reference .table-orenge span:not(.active) {
  background: rgb(252, 234, 134); 
}*/
</style>
