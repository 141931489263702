// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Util from './mixin/utility'
import utilityMixin from './mixin/utilityMixin'
import DateUtil from './mixin/dateUtilityMixin'
import axios from 'axios'
import VueAxios from "vue-axios";
import connServer from "./mixin/connServer";
import VueLocalStorage from 'vue-localstorage'


import '@/assets/css/ress.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/global.css'
import '@/assets/css/Work.css'
import '@/assets/css/calendar.css'
import ElementUI from 'element-ui'
import contextMenu from 'vue-context-menu'
import VueCookies from 'vue-cookies';

import locale from 'element-ui/lib/locale/lang/ja'

// 
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ElementUI, { locale })
//
Vue.use(VueLocalStorage)
Vue.use(contextMenu)
Vue.use(VueCookies);

// 
Vue.store = store
// axios
Vue.use(VueAxios, axios);
Vue.config.productionTip = false
Vue.axios.defaults.withCredentials = true;
Vue.axios.defaults.headers.common["Accept"] = "application/json";
Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
Vue.axios.defaults.headers.common["Cache-Control"] = "no-cache";
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// mixin
Vue.mixin(Util)
Vue.mixin(utilityMixin)
Vue.mixin(DateUtil)
Vue.mixin(connServer)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount("#nakamura-kensetsu");