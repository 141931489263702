import Vue from 'vue'
import store from "@/store/"
import Router from 'vue-router'
import config from "../const/config"

import Login from '@/views/Login'
import Home from '@/views/Home'
import WorkRegist from '@/views/WorkRegist'
import WorkReference from '@/views/WorkReference'
import WorkBase from '@/views/WorkBase'
import Setting from '@/views/Setting'
import SharedFolder from '@/views/SharedFolder'
import DataImport from '@/views/DataImport'
import DataExport from '@/views/DataExport'
import DailyWorkAndPaidCsvExport from '@/views/DailyWorkAndPaidCsvExport'
import Schedule from '@/views/Schedule'
import PaidEdit from '@/views/PaidEdit'
import Paid from '@/views/Paid'


Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/Home',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/WorkRegist',
      name: 'WorkRegist',
      component: WorkRegist,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/WorkReference',
      name: 'WorkReference',
      component: WorkReference,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/WorkBase',
      name: 'WorkBase',
      component: WorkBase,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/Setting',
      name: 'Setting',
      component: Setting,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/SharedFolder',
      name: 'SharedFolder',
      component: SharedFolder,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/DataImport',
      name: 'DataImport',
      component: DataImport,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/DataExport',
      name: 'DataExport',
      component: DataExport,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/DailyWorkAndPaidCsvExport',
      name: 'DailyWorkAndPaidCsvExport',
      component: DailyWorkAndPaidCsvExport,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/Schedule',
      name: 'Schedule',
      component: Schedule,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/PaidEdit',
      name: 'PaidEdit',
      component: PaidEdit,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/Paid',
      name: 'Paid',
      component: Paid,
      meta: {
        requiresAuth: true,
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // --- 該当するページがないURLを叩いた場合
  if (to.matched.length <= 0) {
    // ホーム画面へリダイレクト
    next({
      path: config.PAGES.HOME
    });
  }

  // --- 認証関係
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 認証が必要なページを叩いた場合 → 認証APIを呼び出し
    Vue.axios.get(config.URL.SECURED)
      .then((response) => {
        if (response.data.result) {
          // 認証成功
          store.commit(
            config.STORE.USER_INFO.MUTATIONS.setUserInfo,
            response.data.container
          );
          next();
        } else {
          onAuthError(next);
        }
      })
      .catch((e) => {
        console.log(e);
        onAuthError(next);
      });
  } else {
    // 認証が不要なページを叩いた場合
    next();
  }
});

/**
 * 認証エラー時の処理
 * @param {}} next 
 */
function onAuthError(next) {
  if (store.state.userInfo.loggedIn) {
    // ログイン済だった場合は、ログイン画面へ遷移することを知らせるメッセージを表示(App.Vueにメッセージ表示の実装がある)
    store.dispatch(config.STORE.INDEX.ACTION.authErrorModalShow);
  }
  store.commit(config.STORE.USER_INFO.MUTATIONS.logOut);
  next({
    path: config.PAGES.LOGIN
  });
}


export default router;