<template>
  <div id="work-regist" class="main">
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="日報編集"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="p-4">
            <div class="workHeaderArea">
              <!--  -->
              <div class="pt-1">
                <div class="d-flex">
                  <b-radio-group
                    v-model="workerTypeSelected"
                    button-variant="info"
                    buttons
                    class="btnSelectWorkerType"
                  >
                    <b-form-radio :value="SALARY_TYPE_MONTH"
                      >月給 ({{ dailyReportWorkerMonthCount }})</b-form-radio
                    >
                    <b-form-radio
                      :value="SALARY_TYPE_DAY"
                      v-if="isUseDailySalary || dailyReportWorkerDayCount > 0"
                      >日給 ({{ dailyReportWorkerDayCount }})</b-form-radio
                    >
                    <b-form-radio :value="SALARY_TYPE_HOUR"
                      >時給 ({{ dailyReportWorkerHourCount }})</b-form-radio
                    >
                    <b-form-radio :value="WORKER_TYPE_PARTNER_COMPANY"
                      >協力会社 ({{ dailyReportPartnerCount }})</b-form-radio
                    >
                  </b-radio-group>
                  <h6 class="pt-2 mb-0 px-3 text-danger">
                    （ 必ず、３時間以内に一度は休憩を取ること！ ）
                  </h6>
                </div>
              </div>
              <!-- ヘッダー部分 -->
              <WorkHeader
                :propDate="strWorkDate"
                :porpWeather="dailyReportBaseData.weather"
                :propWorkSite="dailyReportBaseData.construction_name"
                :propOverview="dailyReportBaseData.job_content"
                :propResponsibleCd="dailyReportBaseData.responsible_cd"
                :propResponsibleName="dailyReportBaseData.responsible_name"
                :propManagerCd="dailyReportBaseData.manager_cd"
                :propManagerName="dailyReportBaseData.manager_name"
                :propWorkerList="dailyReportWorkerList"
              ></WorkHeader>
            </div>
            <main class="main_area">
              <b-container fluid>
                <div class="pb-2">
                  <!-- 月給 -->
                  <div v-show="workerTypeSelected == 1">
                    <div
                      v-for="(workerItem, wIndex) in this
                        .dailyReportWorkerMonthList"
                      :key="wIndex"
                      class="worker_state_content"
                    >
                      <WorkState
                        :baseTimes="baseTimes"
                        :worker="workerItem"
                        :workerList="dailyReportWorkerMonthList"
                        :rowIndex="wIndex"
                        :workDate="workDate"
                        :isPartnerCompany="false"
                        @getDailyReportList="getDailyReportWorkerInfo"
                        @changeEditMode="changeEditMode"
                        @offEditMode="offEditMode"
                      ></WorkState>
                    </div>
                  </div>
                  <!-- 日給 -->
                  <div v-show="workerTypeSelected == 2">
                    <div
                      v-for="(workerItem, wIndex) in this
                        .dailyReportWorkerDayList"
                      :key="wIndex"
                      class="worker_state_content"
                    >
                      <WorkState
                        :baseTimes="baseTimes"
                        :worker="workerItem"
                        :workerList="dailyReportWorkerDayList"
                        :rowIndex="wIndex"
                        :workDate="workDate"
                        :isPartnerCompany="false"
                        @changeEditMode="changeEditMode"
                        @offEditMode="offEditMode"
                        @getDailyReportList="getDailyReportWorkerInfo"
                      ></WorkState>
                    </div>
                  </div>
                  <!-- 時給 -->
                  <div v-show="workerTypeSelected == 3">
                    <div
                      v-for="(workerItem, wIndex) in this
                        .dailyReportWorkerHourList"
                      :key="wIndex"
                      class="worker_state_content"
                    >
                      <WorkState
                        :baseTimes="baseTimes"
                        :worker="workerItem"
                        :workerList="dailyReportWorkerHourList"
                        :rowIndex="wIndex"
                        :workDate="workDate"
                        :isPartnerCompany="false"
                        @changeEditMode="changeEditMode"
                        @offEditMode="offEditMode"
                        @getDailyReportList="getDailyReportWorkerInfo"
                      ></WorkState>
                    </div>
                  </div>
                  <!-- 協力会社 -->
                  <div
                    v-show="workerTypeSelected == WORKER_TYPE_PARTNER_COMPANY"
                  >
                    <div
                      v-for="(workerItem, wIndex) in this
                        .dailyReportPartnerList"
                      :key="wIndex"
                      class="worker_state_content"
                    >
                      <WorkState
                        :baseTimes="baseTimes"
                        :worker="workerItem"
                        :workerList="dailyReportPartnerList"
                        :rowIndex="wIndex"
                        :workDate="workDate"
                        :isPartnerCompany="true"
                        @changeEditMode="changeEditMode"
                        @offEditMode="offEditMode"
                        @getDailyReportList="getDailyReportWorkerInfo"
                      ></WorkState>
                    </div>
                  </div>
                </div>
              </b-container>
            </main>
          </div>
        </b-container>
      </div>
    </main>
    <WorkStateTemplate
      :propWorkerId="propWorkerId"
      :propWorkerType="propWorkerType"
    ></WorkStateTemplate>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader";
import WorkHeader from "../components/Work/WorkHeader";
import WorkState from "../components/Work/WorkState";
import WorkStateTemplate from "../components/Work/WorkStateTemplateModal";
import config from "../const/config";
import moment from "moment";

export default {
  name: "WorkRegist",
  components: {
    BaseHeader,
    WorkHeader,
    WorkState,
    WorkStateTemplate,
  },
  data() {
    return {
      strWeekDay: config.VALUE.strWeekDay,
      dailyReportBaseData: {
        work_date: null,
        construction_cd: null,
        construction_name: "",
        job_content: "",
        responsible_cd: null,
        responsible_name: "",
        manager_cd: null,
        manager_name: "",
        weather: "",
      },
      propWorkerId: null,
      propWorkerType: null,
      dailyReportWorkerList: null,
      workerTypeSelected: 1,
      SALARY_TYPE_MONTH: config.VALUE.SALARY_TYPE_MONTH,
      SALARY_TYPE_DAY: config.VALUE.SALARY_TYPE_DAY,
      SALARY_TYPE_HOUR: config.VALUE.SALARY_TYPE_HOUR,
      WORKER_TYPE_PARTNER_COMPANY: config.VALUE.WORKER_TYPE_PARTNER_COMPANY,
      baseTimes: {
        baseWorkTimeOfficeWorker: null,
        baseBreakTimeOfficeWorker: null,
        baseWorkTimeFieldWorker: null,
        baseBreakTimeFieldWorker: null,
      },
      isUseDailySalary: false,
    };
  },
  computed: {
    constructionCd: function () {
      return this.$store.state.construction_cd;
    },
    workDate: function () {
      return this.$store.state.work_date;
    },
    strWorkDate: function () {
      if (this.workDate != null) {
        let re = "";
        re += this.getStrDateSlash(this.workDate);
        let setWeekDay = moment(this.workDate).isoWeekday();
        re += " (" + this.strWeekDay[setWeekDay] + ")";
        return re;
      } else {
        return "";
      }
    },
    // 締め区分=月給
    dailyReportWorkerMonthList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) =>
            item.salary_type == config.VALUE.SALARY_TYPE_MONTH &&
            item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 締め区分=日給
    dailyReportWorkerDayList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) =>
            item.salary_type == config.VALUE.SALARY_TYPE_DAY &&
            item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 締め区分=時給
    dailyReportWorkerHourList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) =>
            item.salary_type == config.VALUE.SALARY_TYPE_HOUR &&
            item.worker_type == config.VALUE.WORKER_TYPE_EMPLOYEE
        );
      } else {
        return null;
      }
    },
    // 協力会社
    dailyReportPartnerList: function () {
      if (this.dailyReportWorkerList != null) {
        return this.dailyReportWorkerList.filter(
          (item) => item.worker_type == config.VALUE.WORKER_TYPE_PARTNER_COMPANY
        );
      } else {
        return null;
      }
    },
    //--人数
    // 締め区分=月給
    dailyReportWorkerMonthCount: function () {
      let count = 0;
      if (this.dailyReportWorkerMonthList != null) {
        count = this.dailyReportWorkerMonthList.length;
      }
      return count;
    },
    // 締め区分=日給
    dailyReportWorkerDayCount: function () {
      let count = 0;
      if (this.dailyReportWorkerDayList != null) {
        count = this.dailyReportWorkerDayList.length;
      }
      return count;
    },
    //締め区分=時給
    dailyReportWorkerHourCount: function () {
      let count = 0;
      if (this.dailyReportWorkerHourList != null) {
        count = this.dailyReportWorkerHourList.length;
      }
      return count;
    },
    // 協力会社
    dailyReportPartnerCount: function () {
      let count = 0;
      if (this.dailyReportPartnerList != null) {
        count = this.dailyReportPartnerList.length;
      }
      return count;
    },
  },
  watch: {},
  mounted: function () {
    ////給与形態オプション設定
    this.setWorkerTypeOption();
    // 工事別日別作業内容データを取得
    this.getDailyReportBaseData();
    // 作業者データを取得
    this.getDailyReportWorkerInfo();
    // 基本時間取得
    this.getBaseTimes();
  },
  methods: {
    //給与形態オプション生成
    setWorkerTypeOption: function () {
      let me = this;
      //日給シートの利用有無の取得
      me.isUseDailySalary = false;
      this.getData(config.URL.DAILY_REPORT + "/is_use_dayly_salary")
        .then((response) => {
          if (response.result) {
            me.isUseDailySalary = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日給シートの利用有無取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // 基本時間取得
    getBaseTimes: function () {
      let me = this;
      let param = {
        construction_cd: this.constructionCd,
      };
      this.getWithParams(config.URL.DAILY_REPORT + "/get_base_time", param)
        .then((response) => {
          if (response.result) {
            me.baseTimes = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "基本時間取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    /**
     * 工事別日別作業内容データ取得
     */
    getDailyReportBaseData: function () {
      let me = this;
      let param = {
        construction_cd: this.constructionCd,
        target_date: this.workDate,
      };
      this.getWithParams(
        config.URL.DAILY_REPORT_BASE + config.PROCESS.FOR_WORK_REGIST,
        param
      )
        .then((response) => {
          if (response.result) {
            me.dailyReportBaseData = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "工事別日別データ取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },

    /**
     * 日報の対象者を取得する
     */
    getDailyReportWorkerInfo: function () {
      this.dailyReportWorkerList = null
      let me = this;
      let param = {
        work_date: this.workDate,
        construction_cd: this.constructionCd,
      };
      this.getDataList(
        config.URL.DAILY_REPORT + config.PROCESS.FOR_WORK_REGIST,
        param
      )
        .then((response) => {
          if (response.result) {
            me.dailyReportWorkerList = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日報データ作業者一覧取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },

    //--（child操作）
    //編集モードを終了する
    offEditMode: function (index) {
      this.dailyReportWorkerList[index].editMode = false;
    },
    //編集モードにする
    changeEditMode: function (worker) {
      //全て編集モードを終了する
      this.dailyReportWorkerList.forEach(function (element) {
        element.editMode = false;
      });
      // //対象のみ編集モードにする
      // this.dailyReportWorkerList[index].editMode = true;
      worker.editMode = true;
    },
  },
};
</script>

<style>
/* #work-regist .scroll-content {
  height: calc(100vw - 100px);
  max-height: calc(100vw - 100px);
  overflow: scroll;
} */

#work-regist {
  min-width: 1500px;
}
/* 
#work-regist .main_area {
  padding: 0rem 0rem;
} */

#work-regist .work_header .navbar {
  padding: 0rem 0rem;
}

/* 氏名 */
#work-regist .workStateWorkername {
  background: #e9ecef;
  cursor: pointer;
}

#work-regist .workStateTable .bgEditMode {
  background: rgb(255, 255, 255);
}

#work-regist .workStateTable .bgDeleteMode {
  background: rgb(255, 195, 195);
}
</style>
