<template>
  <!-- 業務概要 セレクトボックス コンポーネント -->
  <div>
    <b-row no-gutters>
      <b-col cols="11">
        <b-form-group
          label-cols="1"
          label-size="sm"
          label-class="text-right"
          :label="propLabel + '.'"
          :disabled="propIsDisaibled"
        >
          <b-form-input
          size="sm"
            ref="focusThis"
            maxlength="40"
            type="search"
            autocomplete="off"
            :value="value"
            @input="onInput"
            @keydown.enter="setEvent"
            @blur="setEvent"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <el-dropdown trigger="click" @command="handleCommand" class="ml-2 my-2">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item,index) in propOptions"
              :key="index"
              :command="item"
            >{{item}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import config from "../../const/config";

export default {
  name: "WorkSelectOverview",
  props: ["value", "propLabel", "propIsDisaibled", "propOptions"],
  data: function () {
    return {
    };
  },
  mounted: function () {
  },
  methods: {
    handleCommand(command) {
      this.$emit("input", command);
      this.$refs.focusThis.focus();
    },
    setEvent: function () {
      this.$emit("setEvent");
    },
    onInput: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>