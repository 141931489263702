<template>
  <b-modal
    v-model="isModalShow"
    id="partner-companeys-worker-select-modal"
    size="lg"
    :centered="true"
    hide-footer
    header-class="modal_header"
  >
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">協力会社選択</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="px-5">
        <!-- 絞り込み検索 -->
        <div class>
          <div class>
            <b-input-group prepend="キーワード">
              <b-input v-model="partialMatch" v-on:keyup.enter="getFilterCompanyList"></b-input>
              <b-input-group-append>
                <b-button size="sm" class="px-3" @click="getFilterCompanyList"
                  >検索</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <!-- 一覧 -->
        <div class="mt-3">
          <b-table
            class="workerSelectTable"
            :tbody-tr-class="tbodyRowClass"
            sticky-header
            small
            bordered
            :fields="fields"
            :items="workerList"
            ref="selectableTable"
            @row-clicked="rowClicked"
          >
            <template #cell(selected)="{ item, field: { key } }">
              <template v-if="item[key]">
                <div class="text-center" aria-hidden="true">&check;</div>
              </template>
              <template v-else>
                <div aria-hidden="true">&nbsp;</div>
              </template>
            </template>
          </b-table>
        </div>
        <!-- ボタン -->
        <div class="text-center">
          <b-row>
            <b-col cols="6" class="text-left">
              <b-button class="px-3" @click="selectAllRows">全て</b-button>
              <b-button class="px-3 ml-1" @click="clearSelected"
                >クリア</b-button
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button class="px-5" variant="success" @click="handleOk"
                >OK</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
import config from "../const/config.js";

export default {
  name: "CompanyWorkersSelectModal",
  props: ["propSelectedList"],
  data: function () {
    return {
      WORKER_TYPE: config.VALUE.WORKER_TYPE_PARTNER_COMPANY,
      // モーダル表示状態
      isModalShow: false,
      // テーブル
      fields: [
        {
          key: "selected",
          label: "選択",
          sortable: false,
          thClass: "thSelected text-center themeColoreHeader",
        },
        {
          key: "worker_cd",
          label: "会社コード",
          sortable: true,
          thClass: "text-center thWorkerCode themeColoreHeader",
          tdClass: "text-center",
        },
        {
          key: "worker_name",
          label: "協力会社名",
          sortable: false,
          thClass: "themeColoreHeader",
        },
      ],
      //部分一致検索
      partialMatch: null,
      //サーバから取得した全社員の一覧を保持
      workerList: [],
      //選択状態の作業者の一覧を保持する
      selectedWorkerList: [],
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        this.getCompanyList();
      } else {
        // モーダル閉じるとき
        this.initData();
      }
    },
  },
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.$root.$emit(
        "bv::hide::modal",
        "partner-companeys-worker-select-modal",
        ""
      );
    },
    // --データ処理
    // 初期化
    initData: function () {
      this.selectSalaryType = null;
      this.partialMatch = null;
      this.workerList = [];
      this.selectedWorkerList = [];
    },

    // 1.1 作業者取得（画面展開時）
    getCompanyList: function () {
      let me = this;
      this.workerList = [];
      // パラメータ作成
      let searchConditions = {};
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(
        config.URL.PARTNER_COMPANY + config.PROCESS.SEARCH,
        searchConditions
      )
        .then((response) => {
          if (response.result) {
            //取得した社員をセット
            me.setWorkerList(response.container);
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          //選択描画更新
          this.setPropList();
        });
    },
    // 1.2 取得した協力会社データを作業員形式で変換してセットする
    setWorkerList: function (dataList) {
      if (dataList == null || dataList <= 0) {
        return;
      }
      let me = this;
      dataList.forEach(function (element) {
        me.workerList.push(
          me.getWorkerData(
            element.partner_company_cd,
            element.partner_company_name
          )
        );
      });
    },
    // 1.3 既存選択状態の作業員情報を選択状態作業員リストにセットする
    setPropList: function () {
      this.setSelected(this.propSelectedList);
      this.setSelectWorkerRow();
    },
    // 1.1 絞り込み検索
    getFilterCompanyList: function () {
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      let me = this;
      this.workerList = [];

      // パラメータ作成
      let searchConditions = {
        partialMatch: this.partialMatch,
      };
      // リクエスト
      this.getDataList(
        config.URL.PARTNER_COMPANY + config.PROCESS.SEARCH,
        searchConditions
      )
        .then((response) => {
          if (response.result) {
            //絞り込み結果をリストにセットする
            me.setWorkerList(response.container);
            //選択状態だった作業員が存在しない場合、保持している選択状態社員リストからコピーする
            // me.addSelectedData();
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          //選択描画
          this.setSelectWorkerRow();
        });
    },
    // 1.3 選択状態だった作業者が、絞込の結果一覧から存在しなくなった場合、選択状態作業者リストからコピーする
    addSelectedData: function () {
      let me = this;

      if (
        this.selectedWorkerList != null &&
        this.selectedWorkerList.length > 0
      ) {
        let list = this.selectedWorkerList.slice().reverse();

        list.forEach(function (element) {
          //選択状態の社員が、絞り込み等によって社員リストに存在しない場合
          let data = me.workerList.filter(
            (el) => el.worker_cd == element.worker_cd
          );
          if (data == null || data.length <= 0) {
            //存在しない場合
            me.workerList.unshift(
              me.getWorkerData(element.worker_cd, element.worker_name)
            );
          }
        });
      }
    },
    //作業者用データに整形
    getWorkerData: function (worker_cd, worker_name) {
      return {
        selected: false,
        worker_cd: worker_cd,
        worker_name: worker_name,
        worker_type: this.WORKER_TYPE,
        salary_type: null,
        worker_count: null,
        is_manager: config.VALUE.INVALID
      };
    },
    //選択済み保持リストに対象作業者を追加
    addSelectWorker: function (worker) {
      let me = this;
      //選択済みの作業者出ないことを確認
      let data = this.selectedWorkerList.filter(
        (el) => el.worker_cd == worker.worker_cd && el.worker_type == me.WORKER_TYPE
      );
      if (data && data.length > 0) {
        //選択済み
        return;
      }
      //追加
      this.selectedWorkerList.push(worker);
    },
    //選択済み保持リストから対象作業者を削除
    removeSelectWorker: function (worker) {
      let me = this;
      //対象の作業者以外に絞込
      let data = this.selectedWorkerList.filter(
        (el) => el.worker_cd != worker.worker_cd && el.worker_type == me.WORKER_TYPE
      );
      //
      if (data && data.length > 0) {
        this.selectedWorkerList = data;
      } else {
        this.selectedWorkerList = [];
      }
    },
    //選択済み保持リストにセットする
    setSelected: function (dataList) {
      let me = this;
      me.selectedWorkerList = [];
      if (dataList != null && dataList.length > 0) {
        dataList.forEach(function (element) {
          if (element.worker_type == me.WORKER_TYPE) {
            me.selectedWorkerList.push({ ...element });
          }
        });
      }
    },
    //選択済みのはずの社員を選択化
    setSelectWorkerRow: function () {
      let me = this;
      if (!this.selectedWorkerList || this.selectedWorkerList.length <= 0) {
        //選択していた社員が存在しない場合
        return;
      }
      this.workerList.forEach(function (element) {
        let data = me.selectedWorkerList.filter(
          (el) => el.worker_cd == element.worker_cd
        );
        if (data && data.length > 0) {
          element.selected = true;
        }
      });
    },
    //--  テーブル選択処理

    //行選択時
    rowClicked(item) {
      if (item.selected) {
        this.$set(item, "selected", false);
        this.removeSelectWorker(item);
      } else {
        this.$set(item, "selected", true);
        this.addSelectWorker(item);
      }
    },
    // 全選択時
    selectAllRows() {
      this.workerList.forEach((item) => {
        this.$set(item, "selected", true);
        this.addSelectWorker(item);
      });
    },
    //全選択解除時
    clearSelected() {
      this.workerList.forEach((item) => {
        this.$set(item, "selected", false);
        this.removeSelectWorker(item);
      });
    },
    // -- 確定時
    handleOk: function () {
      this.$emit("setSelectWorkers", this.selectedWorkerList);
      this.hideModal();
    },
    //行のレイアウト
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item.selected) {
        return ["selectedRow"];
      } else {
        return [""];
      }
    },
  },
};
</script>

<style>
#partner-companeys-worker-select-modal .workerSelectTable {
  min-height: 450px;
  max-height: 450px;
  font-size: 14px;
  overflow-y: auto;
}

#partner-companeys-worker-select-modal .thSelected {
  width: 50px;
}

#partner-companeys-worker-select-modal .thWorkerCode {
  width: 130px;
}

#partner-companeys-worker-select-modal .selectedRow {
  background: rgba(255, 251, 193, 0.616);
}
</style>