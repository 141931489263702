// import { RFC_2822 } from "moment";

export default Object.freeze({
  PAGES: {
    HOME: "/Home",
    LOGIN: "/",
    WORK_BASE:"/WorkBase",
    WORK_REGIST: "/WorkRegist"
  },
  URL: {
    LOGIN: "/api/login",
    LOGOUT: "/api/logout",
    SECURED: "/api/secured",
    SCHEDULE: "/api/schedule",
    CONSTRUCTION: "/api/construction",
    ITEM_NAME: "/api/item_name",
    EMPLOYEE: "/api/employee",
    PARTNER_COMPANY: "/api/partner_company",
    PAID: "/api/paid",
    PAID_EDIT: "/api/paid_edit",
    SETTING: "/api/setting",
    DAILY_REPORT_BASE: "/api/daily_report_base",
    DAILY_REPORT: "/api/daily_report",
    DATA_EXPORT: "/api/data_export",
    DAILY_REPORT_TABLE_CSV_EXPORT: "/api/daily_report_table_csv_export",
    DAILY_WORK_AND_PAID_CSV_EXPORT: "/api/daily_work_and_paid_csv_export",
    DATA_IMPORT: "/api/data_import",
    SHARED_FOLDER: "/api/shared_folder",
    SHARED_FILE: "/api/shared_file",
    SHARED_FILE_CHECK: "/api/shared_file_check"
  },
  PROCESS: {
    UPDATE: "/update",
    INSERT: "/insert",
    SAVE: "/save",
    COPY: "/copy",
    DELETE: "/delete",
    COUNT: "/count",
    SEARCH: "/search",
    UPLOAD: "/upload",
    FOR_WORK_REGIST: "/for_work_regist",
    FOR_ALL_USER: "/for_all_user",
    GET_BASE_WORK_TIME: "/getBaseWorkTime",
    UPDATE_BASE_WORK_TIME: "/updateBaseWorkTime",
    GET_OPERATION_CONTROL_DAYLYSALARY: "/getOperationControlDailySalary",
    GET_PAYROLL_SETTING: "/getPayrollSetting",
    GET_OPE_CONTROL: "/getOpeControl",
    UPDATE_OPE_CONTROL: "/updateOpeControl",
    GET_RECENT_DATE_LIST_BY_CONSTRUCTION: "/getRecentDateListByConstruction"
  },
  VALUE: {
    /** 曜日リスト */
    strWeekDay: ['', '月', '火', '水', '木', '金', '土', '日'],
    WORKTYPE_BREAK: 99,
    PAIDTYPE_DAY: 1,
    PAIDTYPE_HOUR: 2,
    PAIDTYPE_DELETE: 3,
    TIME_TYPE_NO_DATA: 0,
    TIME_TYPE_HAVE_DATE: 1,
    TIME_TYPE_ANOTHOR_CONSTRUCTION: "9",
    YEAR_START_MONTH: 5,
    // 作業者区分：社員
    WORKER_TYPE_EMPLOYEE: 0,
    // 作業者区分：協力会社
    WORKER_TYPE_PARTNER_COMPANY: 9,
    // 社員種別(締区分)：職員
    EMPLOYEE_TYPE_STAFF: 1,
    EMPLOYEE_TYPE_STAFF_STR: "職員",
    // 社員種別(締区分)：作業員
    EMPLOYEE_TYPE_FIELDWORKER: 2,
    EMPLOYEE_TYPE_FIELDWORKER_STR: "作業員",
    TREE_LEVEL_ALL: 0,
    TREE_LEVEL_LAST_WEEK: 98,
    TREE_LEVEL_WITH_PERIOD: 99,
    // 社員給与形態：月給
    SALARY_TYPE_MONTH: 1,
    // 社員給与形態：日給
    SALARY_TYPE_DAY: 2,
    // 社員給与形態：時給
    SALARY_TYPE_HOUR: 3,
    //工事種別：現場
    CONSTRUCTION_TYPE_FIELD: "1",
    //工事種別：事業所
    CONSTRUCTION_TYPE_OFFICE: "2",
    //分類（コントロールマスタ）:就業時間
    CATEGORY_WORK_TIME: "1",
    //分類（コントロールマスタ）:休憩時間
    CATEGORY_BREAK_TIME: "2",
    // 権限有り
    AUTHORIZED: 1,
    //有効
    VALID: 1,
    //無効
    INVALID: 0,
    //--日報データ
    //業務区分：工事
    WORK_TYPE_CONSTRUCTION: 1,
    //業務区分：附帯
    WORK_TYPE_AUXILIARY: 2,
    //業務区分：運転
    WORK_TYPE_DRIVE: 3,
    //業務区分：休憩
    WORK_TYPE_BREAK: 99,
    //インポート対象マスタの数
    IMPORT_FILE_COUNT: 8,
    //有給残管理有り
    PAID_MANAGEMENT: 1,
    //有給残管理無し
    PAID_NO_MANAGEMENT: 0,
    //就業区分:就業中
    EMPLOYEE_STATUS_WORK:0,
    //就業区分:退職者
    EMPLOYEE_STATUS_RETIREE:2
  },
  MESSAGE: {
    SERVER_ERROR: "処理中に問題が発生しました。お手数ですが、再度お試しください。",
    AUTHENTICATION_ERROR: "ユーザーの認証に失敗しました。再度ログインしてください。"
  },
  STORE: {
    INDEX: {
      ACTION: {
        startLoading: "startLoading",
        stopLoading: "stopLoading",
        authErrorModalShow: "authErrorModalShow",
        authErrorModalHide: "authErrorModalHide"
      }
    }
    ,
    USER_INFO: {
      MUTATIONS: {
        setUserInfo: "userInfo/setUserInfo",
        logOut: "userInfo/logOut"
      },
    }
  }
})
