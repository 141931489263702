<template>
  <div id="login">
    <!-- メイン -->
    <div>
      <div class="py-3 text-center border-bottom theme_back_coloer">
        <h4 class="company_name mb-1">中村建設</h4>
        <p class="mb-0" style="font-size: 15px; font-weight: bold">
          日報システム
        </p>
      </div>
    </div>
    <div class="login_main">
      <b-container fluid class="p-4 px-4">
        <b-card header="ログイン" header-class="login_cardheader">
          <b-row>
            <b-col class="mb-2" cols="12">
              <Alert
                :message="validate.login"
                type="warning"
                class="mb-2"
              ></Alert>
            </b-col>
            <b-col cols="12">
              <b-form-group label="ユーザID" label-size="sm">
                <b-form-input
                  id="shain-id"
                  ref="focusUserId"
                  v-model="form.userId"
                  v-on:keyup.enter="loginBtnHandler"
                  maxlength="50"
                  placeholder
                ></b-form-input>
                <Alert
                  :message="validate.userId"
                  type="warning"
                  class="mt-2"
                ></Alert>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="パスワード" label-size="sm">
                <b-form-input
                  id="pass"
                  ref="focusPassword"
                  type="password"
                  v-model="form.password"
                  v-on:keyup.enter="loginBtnHandler"
                  placeholder
                ></b-form-input>
                <Alert
                  :message="validate.password"
                  type="warning"
                  class="mt-2"
                ></Alert>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-checkbox v-model="form.rememberFlg">
                次回ログインからIDの入力を省略
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                class="mt-3 w-100"
                variant="primary"
                @click="loginBtnHandler"
                >ログイン</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </div>
  </div>
</template>

<script>
import config from "../const/config.js";
import Alert from "../components/Alert";

export default {
  name: "Login",
  components: {
    Alert,
  },
  data() {
    return {
      form: {
        userId: null,
        password: null,
        rememberFlg: true,
      },
      validate: {
        login: null,
        userId: null,
        password: null,
      },
      hasError: false,
    };
  },
  mounted: function () {
    // --- ユーザーIDを記憶していた場合
    if (localStorage.nakamura_works_remember_user_id) {
      this.form.userId = localStorage.nakamura_works_remember_user_id;
      this.$refs.focusPassword.focus();
    } else {
      this.$refs.focusUserId.focus();
    }
    // --- 認証エラー時のフラグをfalseにする
    this.$store.dispatch(config.STORE.INDEX.ACTION.authErrorModalHide);
  },
  methods: {
    /**
     * ログインボタン押下時
     */
    loginBtnHandler: function () {
      let valid = this.executeValidate();
      if (!valid) {
        return;
      }
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(config.URL.LOGIN, this.form)
        .then(() => {
          this.loginSuccessHandler();
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            this.validate.login = "ユーザーIDかパスワードが間違っています。";
          } else {
            this.validate.login = config.MESSAGE.SERVER_ERROR;
          }
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * クライアントバリデーション実施
     */
    executeValidate() {
      this.clearValidateMessage();
      let valid = true;
      if (this.form.userId == null || this.form.userId.length <= 0) {
        this.validate.userId = "ユーザーIDを入力してください。";
        valid = false;
      }
      if (this.form.password == null || this.form.password.length <= 0) {
        this.validate.password = "パスワードを入力してください。";
        valid = false;
      }
      return valid;
    },

    /**
     * バリデートメッセージの初期化
     */
    clearValidateMessage() {
      this.validate.login = null;
      this.validate.userId = null;
      this.validate.password = null;
    },

    /**
     * ログイン成功後の処理
     */
    loginSuccessHandler() {
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getData(config.URL.SECURED)
        .then((response) => {
          if (response.result) {
            // --- ログイン成功時
            //ストアに保存
            this.$store.commit(
              config.STORE.USER_INFO.MUTATIONS.setUserInfo,
              response.container
            );
            // ユーザーIDを記憶する場合
            if (this.form.rememberFlg) {
              localStorage.nakamura_works_remember_user_id = this.form.userId;
            } else {
              localStorage.removeItem("nakamura_works_remember_user_id");
            }
            // ホーム画面へ
            this.$router.push(config.PAGES.HOME);
          } else {
            this.validate.login = config.MESSAGE.AUTHENTICATION_ERROR;
          }
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
  },
};
</script>

<style>
/* ログイン画面 カードヘッダ */
#nakamura-kensetsu .login_cardheader {
  background: var(--mainColor);
  color: white;
}

#login .login_main {
  max-width: 500px;
  margin: 0 auto;
}

#login .errorMsg {
  font-size: 13px;
}
</style>
