<template>
  <b-modal
    id="work-state-template-modal"
    v-model="isModalShow"
    :centered="true"
    hide-footer
    size="lg"
    header-class="modal_header"
  >
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">テンプレートの適用</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="mx-1">
        <!-- テンプレート選択 -->
        <div class="mt-1">
          <div>
            <b-card no-body>
              <b-card-header class="py-2">テンプレート選択</b-card-header>
              <b-card-body class="temp_select_card">
                <!-- 休憩時間 -->
                <b-form-group label="手当" label-size="sm" label-class="base_form_label">
                  <b-form-radio-group stacked>
                    <b-form-checkbox-group>
                      <b-form-checkbox value="1">職長</b-form-checkbox>
                      <b-form-checkbox value="2">運転</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-radio-group>
                </b-form-group>
                <!-- 作業時間 -->
                <b-form-group label="作業時間" label-size="sm" label-class="base_form_label">
                  <b-form-radio-group stacked :options="tmpPattern"></b-form-radio-group>
                </b-form-group>
                <!-- 休憩時間 -->
                <b-form-group label="休憩時間" label-size="sm" label-class="base_form_label">
                  <b-form-radio-group stacked :options="breakTiimePattern"></b-form-radio-group>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>
        </div>
        <!-- 下矢印 -->
        <div class="my-2 text-center">
          <b-icon icon="arrow-down" font-scale="2.5"></b-icon>
        </div>
        <!-- 適用先 -->
        <div>
          <div class="mt-2">
            <b-card no-body>
              <b-card-header class="py-2">適用先</b-card-header>
              <b-card-body class="worker_select_card">
                <b-form-group label="社員" label-size="sm" label-class="base_form_label">
                  <!-- <div class="typeName">社員</div> -->
                  <b-form-checkbox-group v-model="copyToEmployees" :options="employees"></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="作業者" label-size="sm" label-class="base_form_label">
                  <!-- <div class="typeName">作業者</div> -->
                  <b-form-checkbox-group v-model="copyToWorkers" :options="workers"></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="協力会社" label-size="sm" label-class="base_form_label">
                  <!-- <div class="typeName">協力会社</div> -->
                  <b-form-checkbox-group v-model="copyToPartner" :options="partnerCompanyWorkers"></b-form-checkbox-group>
                </b-form-group>
              </b-card-body>
            </b-card>
          </div>
        </div>

        <div class="text-center mt-2">
          <!-- <b-button variant>リセット</b-button> -->
          <b-button variant @click="confirmHandle">確定</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
export default {
  name: "WorkStateTemplateModal",
  props: ["propWorkerId", "propWorkerType"],
  data: function () {
    return {
      // モーダル表示状態
      isModalShow: false,
      workerId: "",
      workerType: "",
      selectCopyType: ["1", "2", "99", "100"],
      copyWorkTypeOptions: [
        { value: "1", text: "付帯" },
        { value: "2", text: "運転" },
        { value: "99", text: "休憩" },
        { value: "100", text: "手当" },
      ],
      selectOptions: [
        { value: "", text: "-" },
        { value: "1", text: "山田 太郎" },
        { value: "2", text: "田中 三太郎" },
        { value: "3", text: "松田 太郎" },
        { value: "4", text: "寺田 太郎" },
        { value: "5", text: "野田 太郎" },
        { value: "6", text: "小田 太郎" },
        { value: "7", text: "野田 三太郎" },
      ],
      employees: [
        { value: "1", text: "山田 太郎" },
        { value: "2", text: "田中 三太郎" },
        { value: "3", text: "松田 太郎" },
      ],
      workers: [
        { value: "4", text: "寺田 太郎" },
        { value: "5", text: "野田 太郎" },
        { value: "6", text: "小田 太郎" },
        { value: "7", text: "山田 三太郎" },
      ],
      partnerCompanyWorkers: [{ value: "8", text: "野田 三太郎" }],
      //
      tmpPattern: [
        {
          value: "1",
          text: "[ 工事通常 ] : 工事 11:00-14:00 / 付帯 15:00-17:00 / 運転 19:00-22:00",
        },
        {
          value: "2",
          text: "[ 工事早番 ] : 工事 09:00-17:00 / 運転 18:00-19:00 ",
        },
        {
          value: "3",
          text: "[ 付帯早番 ] : 工事 10:00-14:00 / 付帯 15:00-17:00 ",
        },
        {
          value: "4",
          text: "[ 緊急対応 ] : 工事 13:00-16:00 / 付帯 16:00-17:00 ",
        },
      ],
      breakTiimePattern: [
        {
          value: "1",
          text: "09:00-10:15 / 12:00-13:00",
        },
        {
          value: "2",
          text: "09:00-10:15 / 12:00-13:00 / 15:00-15:15",
        },
      ],
      //
      copyToEmployees: [],
      copyToWorkers: [],
      copyToPartner: [],
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        // モーダル開くとき
        this.copyToEmployees = [];
        this.copyToWorkers = [];
        this.copyToPartner = [];
        let me = this;
        if (this.workerType == "1") {
          this.employees.forEach((item) => {
            me.copyToEmployees.push(item.value);
          });
        } else if (this.workerType == 2) {
        } else if (this.workerType == 3) {
        }
      } else {
        // モーダル閉じるとき
      }
    },
    propWorkerId: function () {
      this.workerId = this.propWorkerId;
    },
    propWorkerType: function () {
      this.workerType = this.propWorkerType;
    },
  },
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      // this.$emit("child-event");
      this.$root.$emit("bv::hide::modal", "work-state-template-modal", "");
    },
    confirmHandle: function () {
      this.$emit("panrentSetCopyData");
      this.hideModal();
    },
  },
};
</script>

<style>
#work-state-template-modal .typeName {
  margin: 5px 0 10px 0;
  padding: 3px 8px;
  /* border: 1px solid rgb(211, 211, 211); */
  background: rgba(225, 225, 225, 0.5);
  color: rgb(67, 67, 67);
  font-size: 15px;
}

#work-state-template-modal .temp_select_card {
  max-height: 300px;
  overflow-y: scroll;
}

/* #work-state-template-modal .tmp_form_label {
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 10px;
  background: rgba(153, 216, 195, 0.253);
} */
</style>