<template>
  <div id="shared-folder" class="main" style>
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="共有フォルダ"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="p-4">
            <div>
              <div>
                <b-row>
                  <b-col cols="4" class="pr-5">
                    <b-table
                      small
                      bordered
                      sticky-header
                      selectable
                      select-mode="single"
                      thead-class="themeColoreHeader"
                      :fields="fieldFoleder"
                      :items="folderList"
                      @row-selected="onFolderRowSelected"
                    ></b-table>
                    <div>
                      <b-button variant="primary" @click="openAddFolderModal">フォルダの追加</b-button>
                      <b-button class="ml-1" variant="primary" @click="openEditFolderModal">編集・削除</b-button>
                    </div>
                  </b-col>
                  <b-col cols="8">
                    <b-table-simple sticky-header bordered small responsive="true" class="h-100">
                      <b-thead>
                        <b-tr>
                          <b-th class="themeColoreHeader">ファイル名</b-th>
                          <b-th class="text-center themeColoreHeader" style="width: 170px ">最終更新日時</b-th>
                          <b-th class="text-center themeColoreHeader" style="width: 120px">容量</b-th>
                          <b-th class="text-center themeColoreHeader" style="width: 150px">ダウンロード</b-th>
                          <b-th class="text-center themeColoreHeader" style="width: 100px">削除</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(item, index) in fileList" :key="index">
                          <b-td class="tdFileName" :title="item.dispFileName">
                            {{
                            item.dispFileName
                            }}
                          </b-td>
                          <b-td class="text-center">{{ item.updateDate }}</b-td>
                          <b-td class="text-right pr-2">{{ item.fileSize }}</b-td>
                          <b-td class="text-center align-middle p-0">
                            <b-button
                              variant="info"
                              class="m-0"
                              size="sm"
                              @click="downloadFile(item)"
                            >
                              <b-icon icon="download"></b-icon>
                            </b-button>
                          </b-td>
                          <b-td class="text-center align-middle p-0">
                            <b-button
                              variant="danger"
                              class="m-0"
                              size="sm"
                              @click="handleDeleteFile(item)"
                            >
                              <b-icon icon="x-circle"></b-icon>
                            </b-button>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="d-flex mt-3">
                      <div class="w-75 mr-2">
                        <b-form-file
                          v-model="uploadFileData"
                          placeholder="ファイルを選択してください"
                          browse-text="ファイル選択"
                          multiple
                        ></b-form-file>
                      </div>
                      <div class="w-25">
                        <b-button
                          class="w-100"
                          variant="info"
                          @click="handleUpladFile"
                          :disabled="isNullOrEmpty(uploadFileData)"
                        >
                          <b-icon icon="cloud-upload" class="mr-2"></b-icon>アップロード
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </main>
    <!-- アップロード状況表示モーダル -->
    <b-modal
      id="modal-upload-state"
      v-model="showUploadStateModal"
      centered
      hide-header
      hide-footer
      body-class="loadingModal"
      :no-close-on-backdrop="true"
    >
      <b-container>
        <div class="mt-2">
          <h5 v-if="isUploadStart">アップロードしています</h5>
          <h5 v-else-if="isUploadEnd">アップロードが完了しました</h5>
          <h5 v-else-if="isUploadFaild" class="text-danger">アップロード中にエラーが発生しました</h5>
        </div>
        <div class="mt-2">
          <h6>{{progressValue}}%</h6>
        </div>
        <div class="mt-2">
          <b-progress :max="PROGRES_MAX" height="2rem">
            <b-progress-bar
              v-if="isUploadFaild"
              variant="danger"
              show-progress
              :value="progressValue"
              :label="`${(progressValue)}%`"
            ></b-progress-bar>
            <b-progress-bar
              v-else
              show-progress
              :value="progressValue"
              :label="`${(progressValue)}%`"
            ></b-progress-bar>
          </b-progress>
        </div>
        <div class="mt-2 text-right">
          <div>
            <b-button v-if="isUploadFaild" size="sm" @click="closeUploadState">閉じる</b-button>
            <b-button v-else size="sm" :disabled="!isUploadEnd" @click="closeUploadState">閉じる</b-button>
          </div>
        </div>
      </b-container>
    </b-modal>
    <AddFolderModal @parent-event="fetchFolders"></AddFolderModal>
    <EditFolderModal @parent-event="fetchFolders" :folderInfo="selectFolder"></EditFolderModal>
    <Confirm
      ref="confirmFileDelete"
      title="ファイルを削除します。"
      :message="confirmMsgFileDelete"
      focusCancel="true"
      @parent-ok-event="deleteFile()"
    ></Confirm>
  </div>
</template>

<script>
import config from "../const/config";
import BaseHeader from "../components/BaseHeader";
import AddFolderModal from "../components/SharedFolder/AddFolderModal";
import EditFolderModal from "../components/SharedFolder/EditFolderModal";
import Confirm from "../components/Confirm";

export default {
  name: "shared_folder",
  components: {
    BaseHeader,
    AddFolderModal,
    EditFolderModal,
    Confirm
  },
  data() {
    return {
      //フォルダ一覧
      fieldFoleder: [{ key: "folderName", label: "フォルダ" }],
      folderList: [],
      selectFolder: null,
      //アップロードファイル
      uploadFileData: null,
      //ファイル一覧
      fileList: [],
      folderData: [],
      //プログレスバーの値
      progressValue: 0,
      PROGRES_MAX: 100,
      PROGRESS_UPLOAD_END: 95,
      //アップロード状況表示モーダル
      showUploadStateModal: false,
      uploadState: 0,
      UPLOAD_STATE_START: 0,
      UPLOAD_STATE_END: 1,
      UPLOAD_STATE_FAILD: 99,
      confirmMsgFileDelete:""
    };
  },
  mounted() {
    //フォルダ一覧取得
    this.fetchFolders();
  },
  watch: {
    /**
     * フォルダ選択変更時
     */
    selectFolder: function () {
      if (this.selectFolder == null) {
        this.fileList = [];
      } else {
        this.fetchFiles();
      }
    },
  },
  computed: {
    isUploadFaild: function () {
      return this.uploadState == this.UPLOAD_STATE_FAILD;
    },
    isUploadStart: function () {
      return this.uploadState == this.UPLOAD_STATE_START;
    },
    isUploadEnd: function () {
      return this.uploadState == this.UPLOAD_STATE_END;
    },
  },
  methods: {
    //-------------------------------
    // フォルダ関連
    //-------------------------------
    /**
     * フォルダ一覧の取得
     */
    fetchFolders() {
      let me = this;
      this.selectFolder = null;
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getData(config.URL.SHARED_FOLDER)
        .then((response) => {
          if (response.result) {
            // フォルダ一
            this.folderList = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * フォルダ選択時
     */
    onFolderRowSelected: function (items) {
      if (items != null && items.length > 0) {
        this.selectFolder = items[0];
      }
    },
    /**
     * フォルダ追加モーダル表示
     */
    openAddFolderModal: function () {
      this.$bvModal.show("add-folder-modal");
    },
    /**
     * ファルダ編集モーダル表示
     */
    openEditFolderModal: function () {
      if (this.selectFolder == null) {
        this.showErrorAlert(
          "フォルダが選択されていません。",
          "フォルダ編集・削除"
        );
        return;
      }
      this.$bvModal.show("edit-folder-modal");
    },
    //-------------------------------
    // ファイル関連
    //-------------------------------
    /**
     * ファイル一覧の取得
     */
    fetchFiles: function () {
      let me = this;
      if (this.selectFolder == null) {
        //フォルダ未選択のため取得不可
        return;
      }
      let folderId = this.selectFolder.folderId;
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getData(config.URL.SHARED_FILE, folderId)
        .then((response) => {
          if (response.result) {
            me.fileList = response.container;
          } else {
            me.showErrorAlert(response.detail.error, "ファイル一覧取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * ファイル削除ボタン押下時
     */
    handleDeleteFile: function (item) {
      this.deleteFileId = item.fileId;
      //確認メッセージ
      this.confirmMsgFileDelete =  "・対象ファイル \n" + item.dispFileName ;
      this.$refs.confirmFileDelete.openDialog();
    },
    /**
     * ファイルの削除
     */
    deleteFile() {
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.deleteData(config.URL.SHARED_FILE, this.deleteFileId)
        .then((response) => {
          if (response.result) {
            //成功
            me.successDeleteFile();
          } else {
            //失敗
            me.showErrorAlert(response.detail.error, "ファイル削除");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * ファイル削除成功時
     */
    successDeleteFile: function () {
      this.showUpdateSuccess("成功しました。", "ファイル削除");
      //ファイル一覧更新
      this.fetchFiles();
    },
    /**
     * ファイルダウンロード
     */
    downloadFile: function (item) {
      let me = this;
      //
      let fileId = item.fileId;
      let folderId = this.selectFolder.folderId;
      //ダウンロード
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.downloadFileGetByKey(config.URL.SHARED_FILE, folderId, fileId)
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * 1. アップロードボタン押下時
     */
    handleUpladFile: function () {
      //ファイル選択チェック
      if (this.isNullOrEmpty(this.uploadFileData)) {
        this.showErrorAlert(
          "ファイルが選択されていません。",
          "ファイルアップロード"
        );
        return;
      }
      //フォルダ選択
      if (this.selectFolder == null) {
        this.showErrorAlert(
          "フォルダが選択されていません。",
          "ファイルアップロード"
        );
        return;
      }

      let fileNameList = [];
      let fileSize = 0;
      this.uploadFileData.forEach((element) => {
        fileNameList.push(element.name);
        fileSize += element.size;
      });
      //アップロードファイルサイズ超過チェック
      if (fileSize > process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE) {
        this.showErrorAlert(
          "ファイルサイズが最大値を超えています。",
          "ファイルアップロード"
        );
        return;
      }

      this.getFileExist(fileNameList);
    },
    /**
     * 2. アップロードファイルがフォルダ内での存在情報を取得し確認メッセージを表示
     */
    getFileExist: function (nameList) {
      let req = {
        folderId: this.selectFolder.folderId,
        fileNames: nameList,
      };
      let me = this;
      this.postData(config.URL.SHARED_FILE_CHECK, req)
        .then((response) => {
          if (response.result) {
            //アップロード確認メッセージの表示
            me.showUploadConfirm(response.container);
          } else {
            me.showErrorMsg(response.detail.error, "ファイルアップロード");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // 3. アップロード確認メッセージ表示
    showUploadConfirm: function (checkInfo) {
      //メッセージ生成
      let msg = "";
      if (checkInfo.isDuplicated) {
        msg += "※同じファイルが存在します。上書きしてよろしいですか。";
        //重複ファイルが存在する
        checkInfo.duplicatedFileNames.forEach((element) => {
          msg += "\n " + element;
        });
      }
      this.$confirm(msg, "アップロードを開始します", {
        confirmButtonText: "開始",
        cancelButtonText: "中止",
      })
        .then((result) => {
          if (result) {
            //アップロード開始
            this.uploadFilePostProgress();
          }
        })
        .catch(() => {});
    },
    /**
     * 4. ファイルのアップロード（進捗状況も取得）
     */
    uploadFilePostProgress: function () {
      let me = this;

      //アップロード状況表示の初期化・表示
      this.uploadState = this.UPLOAD_STATE_START;
      this.progressValue = 0;
      this.showUploadStateModal = true;

      //--リクエスト生成
      let uploadCondition = new FormData();
      uploadCondition.set("folderId", this.selectFolder.folderId);
      this.uploadFileData.forEach((element) => {
        uploadCondition.append("multipartFile", element);
      });

      //進捗を取得するために"onUploadProgress"を利用
      let configs = {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: this.setProgresValue,
      };
      this.setCsrfHeader();
      return new Promise(() => {
        this.axios
          .post(config.URL.SHARED_FILE, uploadCondition, configs)
          .then((response) => {
            if (response.data.result) {
              //成功
              me.successUploadFile();
            } else {
              //失敗
              me.showErrorAlert(
                response.data.detail.error,
                "アップロードエラー"
              );
              //アップロード状況を失敗とする
              me.uploadState = me.UPLOAD_STATE_FAILD;
            }
          })
          .catch(() => {
            me.showServerError();
            //アップロード状況を失敗とする
            me.uploadState = me.UPLOAD_STATE_FAILD;
          });
      });
    },
    // (4). 進捗状況をプログレスバーの値にセット
    setProgresValue(e) {
      // プログレスバーを計算して変更
      let setValue = Math.floor((e.loaded * 100) / e.total);
      if (setValue <= this.PROGRESS_UPLOAD_END) {
        this.progressValue = setValue;
      } else if (setValue > this.PROGRES_MAX - this.PROGRESS_UPLOAD_END) {
        //サーバ側処理が完了する前に100%となることを防ぐ（レスポンス返答後100%とする)
        this.progressValue =
          setValue - (this.PROGRES_MAX - this.PROGRESS_UPLOAD_END);
      }
    },
    /**
     * 5. ファイルアップロード成功時
     */
    successUploadFile: function () {
      //プログレスバーを最大値（終）とする
      this.progressValue = this.PROGRES_MAX;
      //アップロード状況を完了(100%)とする
      this.uploadState = this.UPLOAD_STATE_END;
      //選択ファイルをクリアする
      this.uploadFileData = null;
    },
    /**
     * アップロード状況モーダル閉じるボタン押下時
     */
    closeUploadState: function () {
      this.showUploadStateModal = false;
      //ファイル一覧更新
      this.fetchFiles();
    },
  },
};
</script>

<style>
#shared-folder .main_area {
  min-width: 1200px;
}
#shared-folder .main_area th {
  word-break: keep-all;
}

#shared-folder .main_area td {
  height: 32px;
  line-height: 32px;
}

#shared-folder .tdFileName {
  max-width: 415px;
  overflow-x: hidden;
}

#shared-folder .b-table-sticky-header {
  max-height: 650px;
}

#modal-upload-state .modal-content {
  background: rgba(255, 255, 255, 0.945);
}
</style>
