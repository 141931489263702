<template>
  <div>
    <b-modal
      id="schedule-edit-modal"
      size="lg"
      :centered="true"
      hide-footer
      header-class="modal_header"
    >
      <!-- モーダルヘッダー -->
      <template v-slot:modal-header>
        <h5 class="modal-title py-0">スケジュール登録</h5>
        <b-button class="close" @click="hideModal" aria-label="閉じる">
          <span aria-hidden="true">×</span>
        </b-button>
      </template>
      <!-- モーダルボディ -->
      <template v-slot:default>
        <div class="px-5">
          <div>
            <b-row>
              <b-col cols="4">
                <b-form-group label="日付">
                  <b-input readonly class="text-center" :value="getDefaultShowDate(propTargetDate)"></b-input>
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group label="氏名">
                  <b-input readonly :value="userName"></b-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="mt-3">
            <b-form-group label="内容">
              <b-textarea rows="5" v-model="propContent"></b-textarea>
            </b-form-group>
          </div>
          <div class="mt-3">
            <b-row>
              <b-col cols="6">
                <b-button @click="handleDelete" variant="danger">削除</b-button>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-button class="px-5" variant="success" @click="handleOk">確定登録</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-modal>
    <Confirm
      ref="confirmDelete"
      title="スケジュールを削除します。"
      message=""
      focusCancel="true"
      @parent-ok-event="deleteData()"
    ></Confirm>
  </div>
</template>

<script>
import config from "../../const/config";
import Confirm from "../Confirm";

export default {
  name: "ScheduleEditModal",
  components: { Confirm },
  props: ["propContent", "userCd", "userName", "propTargetDate"],
  data: function () {
    return {};
  },
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      // 親処理（スケジュール情報を取り直す想定）
      this.$emit("parent-event");
      // 閉じる
      this.$root.$emit("bv::hide::modal", "schedule-edit-modal", "");
    },
    // 確定ボタン押下時
    handleOk: function () {
      if (this.propContent == null || this.propContent == "") {
        this.showWorningMsg("内容が入力されていません。", "");
        return;
      }
      //登録
      this.save();
    },
    // スケジュール登録処理
    save: function () {
      let me = this;

      //リクエストパラメータ
      let param = {
        schedule_date: this.propTargetDate,
        user_cd: this.userCd,
        content: this.propContent,
        update_user: "",
      };
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(config.URL.SCHEDULE + config.PROCESS.SAVE, param)
        .then((response) => {
          if (response.result) {
            me.hideModal();
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    // 削除ボタン押下時
    handleDelete: function () {
      this.$refs.confirmDelete.openDialog();
    },
    // スケジュール削除処理
    deleteData: function () {
      let me = this;

      // リクエストパラメータ
      let param = {
        schedule_date: this.propTargetDate,
        user_cd: this.userCd,
        update_user: "",
      };
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(config.URL.SCHEDULE + config.PROCESS.DELETE, param)
        .then((response) => {
          if (response.result) {
            me.hideModal();
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
  },
};
</script>

<style>
</style>