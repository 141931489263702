<template>
  <div class="workStateArea" @click="clearPopover">
    <!-- 勤務時間編集テーブル -->
    <b-table-simple small fixed class="workStateTable text-center">
      <!-- 先頭のみ表示のヘッダ -->
      <b-thead v-if="this.rowIndex == 0">
        <b-tr>
          <b-th class="workStateWorkername">{{ this.isPartnerCompany ? "会 社 名" : "氏 名" }}</b-th>
          <b-th colspan="8"  class="workStateContent">
            {{
            this.isPartnerCompany ? "勤務者人数 / 勤務内容" : "手当 / 休み / 勤務内容"
            }}
          </b-th>
          <b-th>区分</b-th>
          <b-th colspan="96" class="Nken_BorderRightBold">勤 務 時 間</b-th>
          <b-th colspan="5">時 間 計</b-th>
          <b-th colspan="2" class="Nken_MonthTotal__TitleCel">月 間 計</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="dailyReportData != null">
        <!-- ヘッダー・手当行 ---------------------------------------------------------------------->
        <b-tr class="text-center">
          <!-- 担当者氏名 -->
          <b-th class="workStateWorkername" :rowspan="dailyReportData.workContentList.length + 1">
            <div>{{ dailyReportData.workerName }}</div>
          </b-th>

          <!-- 協力会社の場合 手当の代わりに勤務者人数 -->
          <b-th v-if="this.isPartnerCompany" class="p-0" colspan="8">
            <div class="Nken_WorkerCountBlock">
              <div class="Nken_WorkerCountBlock__TitleLabelArea">勤務者人数</div>
              <div class="Nken_WorkerCountBlock__InputArea">
                <b-form-input
                  v-model="dailyReportData.workerCount"
                  type="number"
                  size="sm"
                  class="text-right partnerCompanyWorkerCount no-spin"
                  :readonly="true">
                </b-form-input>
              </div>
              <div class="pt-1 px-1">人</div>
            </div>
          </b-th>
          <!-- 社員・作業者の場合 手当 -->
          <!-- 職長 -->
          <b-th v-if="!this.isPartnerCompany" class="Nken_WorkStateContent__SupplementInfoCel">職長</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel ' + (dailyReportData.isForeman == VALID ? ' isforeman ' : '')"
          ></b-th>
          <!-- 送迎 -->
          <b-th v-if="!this.isPartnerCompany" class="Nken_WorkStateContent__SupplementInfoCel">送迎</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel ' + (dailyReportData.isTransferGo == VALID ? ' isTransfer ' : '')"
          >往</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel ' + (dailyReportData.isTransferBack == VALID? ' isTransfer ' : '')"
          >複</b-th>
          <!-- 有給 -->
          <b-th v-if="!this.isPartnerCompany" class="Nken_WorkStateContent__SupplementInfoCel">有給</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel ' + (dailyReportData.isPaid  == VALID ? ' isPaid' : '')"
          >
            <span v-if="dailyReportData.paidType == VALID">1d</span>
            <span v-else-if="dailyReportData.paidType ==2">{{dailyReportData.paidHour}}h</span>
          </b-th>
          <!-- 無勤 -->
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel ' + (dailyReportData.isAbsence  == VALID? ' isAbsence ' : '')"
          >無勤</b-th>
          <!-- ---------------------------------------------------------------------------------- -->
          <b-th class="colWorkTyp"></b-th>
          <!-- 時刻（ 0時～23時 ） -->
          <b-th
            v-for="hourNo in 24"
            :key="hourNo"
            colspan="4"
            :class="getWorkTimeHeader(hourNo - 1)"
          >{{hourNo - 1 }}</b-th>
          <!-- 時間計 -->
          <b-th class="thSumTime midnightBackcolor">内深夜</b-th>
          <b-th class="thSumTime">8h内</b-th>
          <b-th class="thSumTime">8h外</b-th>
          <b-th class="thSumTime midnightBackcolor">外深夜</b-th>
          <b-th class="thSumTime">計</b-th>
          <!-- ↓ 2023/10 -->
          <b-th class="Nken_MonthTotal__TypeTitleCel">8h内</b-th>
          <b-th class="Nken_MonthTotal__TypeTitleCel">8h外</b-th>
          <!-- ↑ 2023/10 -->
        </b-tr>
        <!---時間チャート行（工事・附帯・運転・休憩行ごと）---------------------------------------------------------------------->
        <b-tr v-for="(rowItem, rowIIndex) in dailyReportData.workContentList" :key="rowIIndex">
          <!-- 業務内容備考 業務行 -->
          <b-td v-if="!isBreakRow(rowItem.type)" colspan="8" class="p-0 workSelectNote">
            <span class="pl-1">{{rowItem.content}}</span>
          </b-td>
          <!-- 勤務計 休憩行-->
          <b-th colspan="2" class="Nken_WorkStateContent__DailyTimeTotalCel" v-if="isBreakRow(rowItem.type)">勤務計</b-th>
          <b-td
            colspan="2"
            v-if="isBreakRow(rowItem.type)"
            class="Nken_WorkStateContent__DailyTimeTotalCel thSumAll "
          >{{ getSumTimeTimeZoneAll(0, 95) }}</b-td>
          <!-- 休憩計 休憩行-->
          <b-th colspan="2" class="Nken_WorkStateContent__DailyTimeTotalCel" v-if="isBreakRow(rowItem.type)">休憩計</b-th>
          <b-td
            colspan="2"
            v-if="isBreakRow(rowItem.type)"
            class="Nken_WorkStateContent__DailyTimeTotalCel breakTimeBackcolor "
          >{{ getSumTimeRow(rowItem.timeData) | emptyFilters }}</b-td>
          <!-- 種別名 共通-->
          <b-th :class="getTypeClass(rowItem.type)">{{ getWorkTypeName(rowItem.type) }}</b-th>
          <!-- 時間列 共通-->
          <b-td
            v-for="(timeItem, timeIndex) in rowItem.timeData"
            :key="timeIndex"
            :class="getColClass(rowItem.type, timeIndex, timeItem)"
            v-b-popover.html.click.top="{ variant: 'warning', content: getOtherConstructionName(timeItem, timeIndex) }"
          ></b-td>
          <!-- 時間計 業務行 -------------->
          <b-td
            v-if="!isBreakRow(rowItem.type)"
            :class="getSumTimeMidnightIn(rowItem.timeData) != 0 ? 'midnightIn' : '' "
          >{{minutesToPercentage(getSumTimeMidnightIn(rowItem.timeData))}}</b-td>
          <b-td
            v-if="!isBreakRow(rowItem.type)"
            :class="
              getSumTimeBaseIn(rowItem.timeData) != 0 ? 'baseIn' : ''
            "
          >{{minutesToPercentage(getSumTimeBaseIn(rowItem.timeData))}}</b-td>
          <b-td
            v-if="!isBreakRow(rowItem.type)"
            :class="getSumTimeBaseOut(rowItem.timeData) != ''? 'eightOver': ''"
          >{{getSumTimeBaseOut(rowItem.timeData) | emptyFilters}}</b-td>
          <b-td
            v-if="!isBreakRow(rowItem.type)"
            :class=" getSumTimeMidnightOut(rowItem.timeData) != 0? 'midnightOut': ''"
          >{{minutesToPercentage(getSumTimeMidnightOut(rowItem.timeData)) }}</b-td>
          <!-- 区分別計 業務行 -->
          <b-td
            class="tdSumTime"
            v-if="!isBreakRow(rowItem.type)"
          >{{getSumTimeRow(rowItem.timeData) | emptyFilters}}</b-td>
          <!-- 時間計 休憩行 ------------->
          <b-td
            v-if="isBreakRow(rowItem.type)"
          >{{ minutesToPercentage(getSumTimeMidNightInAll()) | emptyFilters}}</b-td>
          <b-td
            v-if="isBreakRow(rowItem.type)"
          >{{minutesToPercentage( getSumTimeBaseInAll(dailyReportData.workContentList))}}</b-td>
          <b-td
            v-if="isBreakRow(rowItem.type)"
          >{{getSumTimeBaseOutAll(dailyReportData.workContentList) | emptyFilters}}</b-td>
          <b-td
            v-if="isBreakRow(rowItem.type)"
          >{{minutesToPercentage(getSumTimeMidnightOutAll(dailyReportData.workContentList)) }}</b-td>
          <!-- 勤務計 休憩行)-->
          <b-td v-if="isBreakRow(rowItem.type)" class="thSumAll">{{getSumTimeTimeZoneAll(0, 95) }}</b-td>
          <!-- ↓ 2023/10 -->
          <!-- 月間計  ------------->
          <b-td v-if="rowIIndex == 0">{{dailyReportData.monthTotalWorkTimeIn8}}</b-td>
          <b-td v-if="rowIIndex == 0">{{dailyReportData.monthTotalWorkTimeOut8}}</b-td>
          <!-- ↑ 2023/10 -->
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import utilsMixin from "../../mixin/utilityMixin";
import config from "../../const/config";

export default {
  name: "WorkStateForReference",
  components: {},
  mixins: [utilsMixin],
  props: {
    worker: { type: Object, default: () => ({ editMode: false }) },
    rowIndex: { default: 0 },
    workDate: { default: null },
    isPartnerCompany: { default: false },
  },
  data() {
    return {
      // 日報データ
      dailyReportData: null,
      // 勤務時間チャート描画管理
      timeChartEdit: false,
      startAreaKey: null,
      endAreaKey: null,
      targetDataList: null,
      // 8h境界時刻（index）
      overEightHour: null,
      // 業務区分（定数）
      VALID: config.VALUE.VALID,
      INVALID: config.VALUE.INVALID,
      WORK_TYPE_CONSTRUCTION: config.VALUE.WORK_TYPE_CONSTRUCTION,
      WORK_TYPE_AUXILIARY: config.VALUE.WORK_TYPE_AUXILIARY,
      WORK_TYPE_DRIVE: config.VALUE.WORK_TYPE_DRIVE,
      WORK_TYPE_BREAK: config.VALUE.WORK_TYPE_BREAK,
    };
  },
  filters: {
    emptyFilters: function (value) {
      if (value == 0.0) {
        return "";
      } else if (value == 0) {
        return "";
      }
      return value;
    },
  },
  computed: {},
  mounted: function () {
    this.getDailyReport();
  },
  methods: {
    /**日報情報取得 */
    getDailyReport: function () {
      // -- 日報データ取得
      let me = this;
      let param = {
        construction_cd: this.worker.construction_cd,
        work_date: this.workDate,
        worker_cd: this.worker.worker_cd,
        worker_type: this.worker.worker_type,
        includeEmptyTimeData: this.INVALID,
      };
      this.getWithParams(config.URL.DAILY_REPORT, param)
        .then((response) => {
          if (response.result) {
            me.dailyReportData = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日報データ取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // 描画：区分名取得
    getWorkTypeName: function (workType) {
      if (workType == this.WORK_TYPE_CONSTRUCTION) {
        return "工事";
      } else if (workType == this.WORK_TYPE_AUXILIARY) {
        return "附帯";
      } else if (workType == this.WORK_TYPE_DRIVE) {
        return "運転";
      } else if (workType == this.WORK_TYPE_BREAK) {
        return "休憩";
      } else {
        return "";
      }
    },
    // 描画：休憩行判定
    isBreakRow: function (type) {
      if (type == this.WORK_TYPE_BREAK) {
        return true;
      } else {
        return false;
      }
    },    
    //--------------------------------
    //-- 時間チャート操作
    //--------------------------------
    clearPopover: function () {
      this.$root.$emit("bv::hide::popover");
    },
    getOtherConstructionName: function (item, index) {
      if (item != "9") {
        //他工事データではない場合
        return null;
      }
      console.log("ClickOtherConstruction, index = " + index);
      // return this.dailyReportData.otherConstructionList[index];
      return this.dailyReportData.otherConstructionNames;
    },
    //--------------------------------
    //  タイムチャート計算
    //--------------------------------
    // 時間数取得
    getTimeCount: function (timeItem) {
      if (timeItem == 1) {
        return 1;
      } else {
        return 0;
      }
    },
    // 分をパーセンテージに変換
    minutesToPercentage: function (value) {
      let re = this.timeConvertMinToPercentage(value);
      if (re == "0.00") {
        return "";
      }
      return re;
    },
    //8h外切替時間を取得する
    getOverEightHours: function () {
      let rowCount = 0; //区分数
      let count = 0; //時間カウント
      let lo = this;

      this.dailyReportData.workContentList.forEach(function (element) {
        if (element.type != lo.WORK_TYPE_BREAK) {
          //休憩を除く区分
          rowCount++;
        }
      });

      let overEightHour = 0;
      for (let timeIndex = 0; timeIndex < 95; timeIndex++) {
        // 0時から24時まで順にカウントしていく
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
          count += this.getTimeCount(
            this.dailyReportData.workContentList[rowIndex].timeData[timeIndex]
          );
        }
        if (count >= 32) {
          //8時間越
          overEightHour = timeIndex;
          break;
        }
      }
      this.overEightHour = overEightHour;
    },
    //チャートのレイアウト変更描画クラス取得
    getColClass: function (type, timeZone, value) {
      if (this.overEightHour == null) {
        this.getOverEightHours();
      }
      let setClass = " tdTime ";

      //--1時間ごと横線描画用
      if (timeZone % 4 == 3) {
        setClass += " endHour";
      }
      if (timeZone % 4 == 0) {
        setClass += " startHour";
      }
      //時間チャート最終列
      if (timeZone == 95) {
        setClass += " Nken_BorderRightBold ";
      }
      //
      if (value == 0) {
        return setClass;
      }
      if (value == config.VALUE.TIME_TYPE_ANOTHOR_CONSTRUCTION) {
        setClass += " anothorConstructionTime";
        return setClass;
      }

      //8h外判定
      let isEightOver = false;
      if (this.overEightHour != 0 && this.overEightHour < timeZone) {
        isEightOver = true;
      }

      //
      if (type == this.WORK_TYPE_BREAK) {
        setClass += " breakTimeBackcolor";
      } else if (timeZone < 20) {
        setClass += " midnightIn";
      } else if (timeZone < 68) {
        if (isEightOver) {
          setClass += " eightOver";
        } else {
          setClass += " baseIn";
        }
      } else if (timeZone < 88) {
        if (isEightOver) {
          setClass += " eightOver";
        } else {
          setClass += " baseIn";
        }
      } else if (timeZone < 96) {
        if (this.overEightHour == 0 || timeZone <= this.overEightHour) {
          setClass += " midnightIn";
        } else {
          setClass += " midnightOut";
        }
      }
      return setClass;
    },
    // インデックス範囲の合計分を取得（行単位合計）
    getSumTimeMinutes: function (timeDataList, fromIndex, endIndex) {
      let sumMinutes = 0;
      for (let i = fromIndex; i <= endIndex; i++) {
        sumMinutes += this.getTimeCount(timeDataList[i]);
      }
      return sumMinutes * 15;
    },
    //内深夜の合計(全行)時間取得
    getSumTimeMidNightInAll: function () {
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }
      this.dailyReportData.workContentList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMidnightIn(item.timeData);
        }
      });
      return sumMinutes;
    },
    //内深夜の合計(行単位)時間取得
    getSumTimeMidnightIn(item) {
      //0~19
      if (this.timeChartEdit) {
        return "";
      }
      let sumMinutes = 0;
      //  0-5時
      sumMinutes += this.getSumTimeMinutes(item, 0, 19);
      // 22-24時
      let endIndex = 95;
      if (this.overEightHour != 0 && this.overEightHour < 95) {
        endIndex = this.overEightHour;
      }
      sumMinutes += this.getSumTimeMinutes(item, 88, endIndex);
      return sumMinutes;
    },
    //8h内の合計の合計(全行)時間取得
    getSumTimeBaseInAll(rowList) {
      //20~67
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }

      rowList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeBaseIn(item.timeData);
        }
      });
      return sumMinutes;
    },
    //8h内の合計(行単位)時間取得
    getSumTimeBaseIn(item) {
      //20~67
      if (this.timeChartEdit) {
        return "";
      }
      let end = 87;
      if (
        this.overEightHour != null &&
        this.overEightHour != 0 &&
        end > this.overEightHour
      ) {
        end = this.overEightHour;
      }
      let sumMinutes = this.getSumTimeMinutes(item, 20, end);
      return sumMinutes;
    },
    //8h外の合計(全行)時間取得
    getSumTimeBaseOutAll(rowList) {
      //68~87
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }
      if (this.overEightHour == null || this.overEightHour == 0) {
        return "";
      }
      rowList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMinutes(
            item.timeData,
            lo.overEightHour + 1,
            87
          );
        }
      });
      let re = this.timeConvertMinToPercentage(sumMinutes);
      return re;
    },
    //外深夜の合計(全行)時間取得
    getSumTimeMidnightOutAll(rowList) {
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }
      rowList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMidnightOut(item.timeData);
        }
      });
      return sumMinutes;
    },
    //外深夜の合計(行単位)時間取得
    getSumTimeMidnightOut(item) {
      //88~95
      let sumMinutes = 0;
      if (this.timeChartEdit) {
        return "";
      }

      if (this.overEightHour != 0) {
        //8時間を超えていて、超えた位置が深夜以降の場合
        if (this.overEightHour >= 88) {
          sumMinutes = this.getSumTimeMinutes(item, this.overEightHour + 1, 95);
        } else {
          sumMinutes = this.getSumTimeMinutes(item, 88, 95);
        }
      }

      return sumMinutes;
    },
    //8h外の合計(行単位)時間取得
    getSumTimeBaseOut(item) {
      //68~87
      if (this.timeChartEdit) {
        return "";
      }
      if (this.overEightHour == null || this.overEightHour == 0) {
        return "";
      }

      // let sumMinutes = this.getSumTimeMinutes(item, 68, 87);
      let sumMinutes = this.getSumTimeMinutes(item, this.overEightHour + 1, 87);
      let re = this.timeConvertMinToPercentage(sumMinutes);
      if (re == "0.00") {
        return "";
      }
      return re;
    },

    //合計分を取得（行単位合計）
    getSumTimeRow(item) {
      //0~95
      if (this.timeChartEdit) {
        return "";
      }
      let sumMinutes = this.getSumTimeMinutes(item, 0, 95);
      let re = this.timeConvertMinToPercentage(sumMinutes);
      if (re == "0.00") {
        return "";
      }
      return re;
    },
    //インデックス範囲の合計分を取得（全勤務行合計）
    getSumTimeTimeZoneAll(fromIndex, endIndex) {
      if (this.timeChartEdit) {
        return "";
      }
      let lo = this;
      let sumMinutes = 0;
      this.dailyReportData.workContentList.forEach((elsement) => {
        if (elsement.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMinutes(
            elsement.timeData,
            fromIndex,
            endIndex
          );
        }
      });
      let re = this.timeConvertMinToPercentage(sumMinutes);
      return re;
    },

    //--------------------------------
    //--------------------------------
    // 区分ごとの背景色を設定
    getTypeClass: function (item) {
      let className = "";
      if (item == 1) {
        className += "workTypeConstruction";
      } else if (item == 2) {
        className += "workTypeAncillary";
      } else if (item == 3) {
        className += "workTypeDriving";
      }
      return className;
    },
    //勤務時間ヘッダーレイアウトクラス(0～23)
    getWorkTimeHeader: function (timeHour) {
      let re = "";
      if (timeHour <= 4) {
        re += " midnightBackcolor ";
      } else if (timeHour >= 22) {
        re += " midnightBackcolor ";
      }

      if (timeHour == 23) {
        re += " Nken_BorderRightBold ";
      }
      return re;
    },
  },
};
</script>

<style>
/* コンテキストメニュー */
.ctx-menu {
  min-width: 180px;
}

.btnEditEnd {
  font-size: 0.7rem;
  padding: 5px;
}

input.partnerCompanyWorkerCount {
  font-size: 0.8rem;
  height: 100%;
  max-width: 100px;
  padding: 3px;
}
</style>