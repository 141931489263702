<template>
  <div class="work_header">
    <nav class="navbar navbar-expand-md navbar-dark theme_colorjustify-content-between mt-0">
      <b-table-simple small bordered style="margin-bottom: 0">
        <b-tbody>
          <b-tr>
            <!-- 日付 -->
            <b-td rowspan="2" class="text-center" style="width: 140px">
              {{ propDate }}
            </b-td>
            <!-- 天気 -->
            <b-th rowspan="2" class="overflow" style="width: 50px">天候</b-th>
            <b-td rowspan="2" class="overflow" style="width: 145px; max-width: 200px" :title="porpWeather">
              {{ porpWeather }}
            </b-td>
            <!-- 現場名 -->
            <b-th style="width: 130px" class="overflow-hidden">現 場 名</b-th>
            <b-td style="width: 715px; max-width: 715px" class="overflow" :title="propWorkSite">{{ propWorkSite }}</b-td>
            <!-- 現場代理人 -->
            <b-th style="width: 95px" class="overflow">現場代理人</b-th>
            <b-td style="max-width: 100px" class="overflow" :title="propResponsibleName">{{ propResponsibleName }}</b-td>
            <!-- 主任 -->
            <b-th style="width: 150px" class="overflow">主任(監理)技術者</b-th>
            <b-td style="max-width: 100px" class="overflow" :title="propManagerName">{{ propManagerName }}</b-td>
          </b-tr>
          <b-tr>
            <!-- 業務概要 -->
            <b-th>本日の業務概要</b-th>
            <b-td :title="propOverview" style="width: 715px; max-width: 715px" class="overflow">{{ propOverview }}</b-td>
            <!-- 担当技術者 -->
            <b-th>担当技術者</b-th>
            <b-td colspan="3" style="max-width: 280px; white-space: nowrap" class="overflow" :title="workerName">{{ workerName }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </nav>
    <!-- <div class="w-100 border-bottom" style="height:50px;background:white;">
      <h6 class="my-0" style="margin-left:40px;line-height:50px;">{{porpSubArea}}</h6>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "workHeader",
  props: [
    "propDate",
    "porpWeather",
    "propWorkSite",
    "propOverview",
    "propChief",
    "propWorkerList",
    "propResponsibleCd",
    "propResponsibleName",
    "propManagerCd",
    "propManagerName",
  ],
  data: function () {
    return {
      items: [
        {
          text: "home",
          href: "#",
        },
      ],
    };
  },
  computed: {
    workerName: function () {
      if (this.propWorkerList != null) {
        let managerList = this.propWorkerList.filter((el) => el.is_manager == 1 && el.worker_cd != this.propResponsibleCd);
        if (managerList != null) {
          return managerList
            .map(function (elm) {
              return elm.worker_name;
            })
            .join("、");
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.work_header td,
.work_header th {
  font-size: 0.9rem;
  vertical-align: middle;
  word-break: keep-all;
  overflow: hidden;
  background: rgba(230, 230, 230, 0.479);
  border: 1px solid rgb(51, 51, 51);
}
.work_header th {
  text-align: center;
}
.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
