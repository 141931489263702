<template>
  <div id="data-export" class="main">
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="データ移出 （ WEB日報入力 ＞＞ 給与システム ）"></BaseHeader>
    </header>
    <main class="main_area">
      <b-container fluid>
        <div class="m-4" style="width: 700px">
          <div class="mt-2">
            <b-table-simple bordered>
              <b-tr>
                <b-th class="themeColoreHeader" style="width:170px">職員・作業員</b-th>
                <b-td>
                  <b-radio-group v-model="employeeType" :options="employeeTypeOptions"></b-radio-group>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="themeColoreHeader">作業日報期間</b-th>
                <b-td v-if="employeeType == 1" class="d-flex">
                  <b-input
                    size="sm"
                    type="date"
                    class="inputDateEdit mr-2"
                    v-model="opeControlerData.staff_daily_report_from"
                    :disabled="isDateEdit"
                  ></b-input>~
                  <b-input
                    size="sm"
                    type="date"
                    class="inputDateEdit ml-2"
                    v-model="opeControlerData.staff_daily_report_to"
                    :disabled="isDateEdit"
                  ></b-input>
                  <b-button size="sm" class="m-auto" @click="isDateEdit = !isDateEdit">
                    <span v-if="isDateEdit">変更 有効化</span>
                    <span v-else>変更 無効化</span>
                  </b-button>
                </b-td>
                <b-td v-if="employeeType == 2" class="d-flex">
                  <b-input
                    size="sm"
                    type="date"
                    class="inputDateEdit mr-2"
                    v-model="opeControlerData.fieldworker_daily_report_from"
                    :disabled="isDateEdit"
                  ></b-input>~
                  <b-input
                    size="sm"
                    type="date"
                    class="inputDateEdit ml-2"
                    v-model="opeControlerData.fieldworker_daily_report_to"
                    :disabled="isDateEdit"
                  ></b-input>
                  <b-button size="sm" class="m-auto" @click="isDateEdit = !isDateEdit">
                    <span v-if="isDateEdit">変更 有効化</span>
                    <span v-else>変更 無効化</span>
                  </b-button>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="themeColoreHeader">出力社員選択</b-th>
                <b-td>
                  <b-radio-group v-model="selectType" :options="selectTypeOptions"></b-radio-group>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="themeColoreHeader">出力データ選択</b-th>
                <b-td>
                  <b-radio-group v-model="selectFile" :options="selectFileOptions"></b-radio-group>
                </b-td>
              </b-tr>
            </b-table-simple>
          </div>
          <!-- 個別選択 -->
          <div>
            <b-collapse v-model="visible">
              <b-row>
                <b-col cols="4">
                  <h6>個別選択</h6>
                </b-col>
                <b-col cols="8" class="text-right">
                  <b-button size="sm" @click="selectAllRows">全選択</b-button>
                  <b-button size="sm" class="m-1" @click="clearSelected">全選択解除</b-button>
                </b-col>
              </b-row>
              <b-table
                class="workerSelectTable"
                sticky-header
                small
                bordered
                selectable
                select-mode="multi"
                :fields="fields"
                :items="employeeList"
                ref="selectableTable"
                @row-selected="onRowSelected"
              >
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <div class="text-center" aria-hidden="true">&check;</div>
                  </template>
                  <template v-else>
                    <div aria-hidden="true">&nbsp;</div>
                  </template>
                </template>
                <template #cell(employee_type)="employeeType">
                  <template>
                    <div
                      class="text-center"
                      aria-hidden="true"
                    >{{ getEmployeeTypeName(employeeType.value) }}</div>
                  </template>
                </template>
              </b-table>
            </b-collapse>
          </div>
          <div>
            <b-button variant="success" @click="execute">処理開始</b-button>
          </div>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import config from "../const/config";
import BaseHeader from "../components/BaseHeader";

export default {
  name: "data-export",
  components: {
    BaseHeader,
  },
  data() {
    return {
      employeeType: null,
      employeeTypeOptions: [
        { value: 1, text: "職員" },
        { value: 2, text: "作業員" },
      ],
      selectType: null,
      selectTypeOptions: [
        { value: 0, text: "全社員" },
        { value: 1, text: "個別選択" },
      ],
      selectFile: null,
      selectFileOptions: [
        { value: 0, text: "日報入力データ" },
        { value: 1, text: "工事別日別作業内容データ" }
      ],
      // 期間
      opeControlerData: this.getInitOpeControlData(),
      // 個別選択用
      fields: [
        {
          key: "selected",
          label: "選択",
          sortable: false,
          thClass: "thSelected themeColoreHeader text-center",
        },
        {
          key: "employee_cd",
          label: "コード",
          sortable: false,
          thClass: "themeColoreHeader text-center",
        },
        {
          key: "employee_name",
          label: "社員名",
          sortable: false,
          thClass: "themeColoreHeader text-center",
        },
        {
          key: "employee_type",
          label: "種別",
          sortable: false,
          thClass: "themeColoreHeader text-center",
        },
      ],
      employeeList: [],
      selected: [],
      visible: true,
      isDateEdit: true,
    };
  },
  watch: {
    selectType: function () {
      if (this.selectType == 0) {
        this.selectAllRows();
      }
    },
    employeeType: function () {
      this.getAllEmployee();
    },
  },
  mounted: function () {
    this.getOpeControlData();
    this.employeeType = 1;
    this.selectType = 0;
    this.selectFile = 0;
  },
  methods: {
    //全社員取得
    getAllEmployee: function () {
      let me = this;
      // パラメータ作成
      let employeeSearchConditions = {
        employee_type: this.employeeType,
        except_employee_status: config.VALUE.EMPLOYEE_STATUS_RETIREE
      };
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(
        config.URL.EMPLOYEE + config.PROCESS.SEARCH,
        employeeSearchConditions
      )
        .then((response) => {
          if (response.result) {
            me.clearSelected();
            me.employeeList = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          me.selectAllRows();
        });
    },
    //日報期間取得
    getOpeControlData: function () {
      let me = this;
      this.getDataList(config.URL.SETTING + config.PROCESS.GET_OPE_CONTROL)
        .then((response) => {
          if (response.result) {
            // console.log(response.container);
            me.opeControlerData = response.container;
            me.dateConvert();
          } else {
            me.showErrorMsg(response.detail.error, "日報期間取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    dateConvert: function () {
      let me = this;
      let staff_dateFrom = me.opeControlerData.staff_daily_report_from;
      let staff_dateTo = me.opeControlerData.staff_daily_report_to;
      me.opeControlerData.staff_daily_report_from = me.getDate(staff_dateFrom);
      me.opeControlerData.staff_daily_report_to = me.getDate(staff_dateTo);
      let fieldworker_dateFrom =
        me.opeControlerData.fieldworker_daily_report_from;
      let fieldworker_dateTo = me.opeControlerData.fieldworker_daily_report_to;
      me.opeControlerData.fieldworker_daily_report_from =
        me.getDate(fieldworker_dateFrom);
      me.opeControlerData.fieldworker_daily_report_to =
        me.getDate(fieldworker_dateTo);
    },
    /**
     * システム運用制御
     */
    getInitOpeControlData: function () {
      return {
        staff_closing_state: null,
        staff_year_month: "",
        staff_daily_report_from: "",
        staff_daily_report_to: "",
        fieldworker_closing_state: "",
        fieldworker_year_month: "",
        fieldworker_daily_report_from: "",
        fieldworker_daily_report_to: "",
        dailySalarySheetUse: "",
      };
    },

    /**
     * 社員種別名称取得
     */
    getEmployeeTypeName: function (employeeType) {
      if (employeeType == config.VALUE.EMPLOYEE_TYPE_STAFF) {
        return config.VALUE.EMPLOYEE_TYPE_STAFF_STR;
      } else if (employeeType == config.VALUE.EMPLOYEE_TYPE_FIELDWORKER) {
        return config.VALUE.EMPLOYEE_TYPE_FIELDWORKER_STR;
      } else {
        return "";
      }
    },

    //個別選択操作
    onRowSelected(items) {
      if (this.selectType == 0) {
        //全社員
        this.selectAllRows();
      } else {
        // 個別選択
        this.selected = items;
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    /**
     * 処理開始
     */
    execute: function () {
      const lo = this;
      // 作業日報期間
      let startAt;
      let endAt;
      if (this.employeeType == 1) {
        startAt = this.opeControlerData.staff_daily_report_from;
        endAt = this.opeControlerData.staff_daily_report_to;
      }
      if (this.employeeType == 2) {
        startAt = this.opeControlerData.fieldworker_daily_report_from;
        endAt = this.opeControlerData.fieldworker_daily_report_to;
      }
      var query = {
        start_at: startAt,
        end_at: endAt,
      };
      // 対象社員
      let selectedEmployeeList = [];
      if (this.selectType == 0) {
        selectedEmployeeList = this.employeeList.map((x) => x.employee_cd);
      } else {
        selectedEmployeeList = this.selected.map((x) => x.employee_cd);
      }
      var request = {
        employee_type: this.employeeType,
        select_type: this.selectType,
        employee_list: selectedEmployeeList,
        select_file: this.selectFile,
      };
      // 出力処理
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);

      this.downloadFilePost(config.URL.DATA_EXPORT, query, request)
        .catch((e) => {
          lo.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
  },
};
</script>

<style>
#data-export .workerSelectTable {
  max-height: 420px;
  font-size: 14px;
}

#data-export .inputDateEdit{
  text-align: center;
  width:160px
}
</style>
