// import Vue from 'vue'
/* eslint-disable */
import axios from "axios"
export default {
  methods: {
    /**
     * POSTでデータを送信
     * @param {*} data 引き渡しデータ
     * @param {*} procClassName 処理クラス名
     */
    async postData(procClassName, data) {

      this.$store.commit("setIsLoading", true);
      // sleep(1);
      //let url = "http://192.52.150.148:8080/api/" + procClassName;
      let url = "http://" + window.location.host + "/assist_system_server/api/" + procClassName;
      let headers = { "Content-Type": "application/json;charset=utf-8" };

      let lo = this;
      return await axios({
        method: "post",
        url: url,
        withCredentials: true,
        headers: headers,
        data: data,
        // timeout: 1000
      }).then(function (response) {
        return response;
      }).catch(function (e) {
        if (e.response) {
          if (e.response.data) {
            if (e.response.data.error) {
              let code = e.response.data.error.code;
              let msg = e.response.data.error.message;
              if (code == "0001") {
                alert("認証に失敗しました。ログイン画面へ移動します。")
                lo.removeCookie("loginRememberSessionKey")
                lo.$router.push("/");
              } else {
                alert(msg)
              }
            }
          }
        } else {
          alert(e);
        }
        return e;
      }).finally(() => {
        this.$store.commit("setIsLoading", false);
      })
    },
    /**
     * POSTでデータを送信（ログイン認証用）
     * @param {*} procClassName 
     * @param {*} data 
     */
    async postLoginData(procClassName, data) {

      this.$store.commit("setIsLoading", true);
      // sleep(1);
      //let url = "http://192.52.150.148:8080/login/" + procClassName;
      let url = "http://" + window.location.host + "/assist_system_server/login/" + procClassName;
      let headers = { "Content-Type": "application/json;charset=utf-8" };

      let lo = this;
      return await axios({
        method: "post",
        url: url,
        withCredentials: true,
        headers: headers,
        data: data,
        // timeout: 1000
      }).then(function (response) {
        return response;
      }).catch(function (e) {
        if (e.response.data) {
          console.log(e.response.data)
        }
      }).finally(() => {
        this.$store.commit("setIsLoading", false);
      })
    },

    //クッキー取得
    getCookie: function (name) {
      let result = null;
      let cookieName = name + "=";
      let allcookies = document.cookie;

      let position = allcookies.indexOf(cookieName);
      if (position != -1) {
        let startIndex = position + cookieName.length;

        let endIndex = allcookies.indexOf(";", startIndex);
        if (endIndex == -1) {
          endIndex = allcookies.length;
        }
        result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
      }

      return result;
    },
    //クッキー設定
    setCookie: function (name, value) {
      this.removeCookie(name)
      let expire = new Date();
      expire.setTime(expire.getTime() + 1000 * 3600 * 24 * 365);
      document.cookie = name + "=" + value + ";expires=" + expire.toUTCString();
    },
    //クッキー削除
    removeCookie: function (name) {
      document.cookie = name + "=;max-age=0";
      document.cookie = name + "=;max-age=0";
    },
  }
};