import moment from 'moment'
import config from "../const/config"
export default {
  methods: {
    startParse: function (start) {
      return moment(start).format('YYYY-MM-DD')
    },
    endParse: function (end) {
      return moment(end).format('YYYY-MM-DD')
    },
    // 値が半角英数の場合はtrueを返す（空文字はOK,半角スペースはNG）
    isHanEisu: function (str) {
      str = (str == null) ? "" : str;
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true;
      } else {
        return false;
      }
    },
    //分 → 時間と分を取得
    timeConvertMinToHourMin: function (baseMin) {
      let houre = Math.floor(baseMin / 60);
      let min = baseMin % 60;
      return {
        houre: houre,
        min: min
      }
    },
    isNullOrEmpty: function (value) {
      if (value == null || value == "") {
        return true;
      }
      return false;
    },
    timeConvertMinToPercentage: function (baseMin) {
      let houre = Math.floor(baseMin / 60);
      let min = baseMin % 60;
      min = min / 60 * 100;
      return houre + "." + this.zeroPadding(min, 2);
    },
    replaceHyphen: function (str, replaveStr) {
      if (!str) {
        return null
      } else {
        return str.replace(/-/g, replaveStr);
      }
    },
    //桁数チェック
    checkDigits: function (str, digits) {
      if (str == null || digits == "") {
        return false;
      }
      if (str.length == digits) {
        return true;
      } else {
        return false;
      }

    },
    zeroPadding: function (num, length) {
      return ('000000000000' + num).slice(-length);
    },
    showWorningMsg: function (msg = "", title = "") {
      this.$alert(msg, title, {
        confirmButtonText: "OK",
        type: "worning",
      });
    },
    showErrorMsg: function (msg = "エラーが発生しました", title = "エラー") {
      this.$bvToast.toast(msg, {
        title: title,
        variant: 'danger',
        solid: true
      });
    },
    showErrorAlert: function (msg = "", title = "エラー") {
      this.$alert(msg, title, {
        confirmButtonText: "OK",
        type: "error",
      });
    },
    showServerError: function (error = "", title = "サーバ通信エラー") {
      console.trace(error);
      this.$bvToast.toast(config.MESSAGE.SERVER_ERROR, {
        title: title,
        variant: 'danger',
        solid: true
      });
    },
    showSuccessToast: function (msg = "", title = "") {
      this.$bvToast.toast(msg, {
        title: title,
        variant: 'success',
        solid: true
      });
    },
    showUpdateSuccess: function (msg = "成功しました。", title = "更新処理") {
      this.$alert(msg, title, {
        confirmButtonText: "OK",
      });
    },
    registUpdateSuccess: function (msg = "成功しました。", title = "登録処理") {
      this.$alert(msg, title, {
        confirmButtonText: "OK",
      });
    }
  }
}