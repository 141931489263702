<template>
  <div
    id="nakamura-kensetsu"
    v-loading.fullscreen.lock="isLoading"
    element-loading-text="しばらくお待ち下さい..."
    customClass="loading"
  >
    <div
      class="d-flex h-100"
      v-if="this.$route.path != '/' && this.$store.state.userInfo.loggedIn"
    >
      <header class="sticky-top" style="height: 100vh">
        <SideMenu></SideMenu>
      </header>
      <div class="flex-grow-1">
        <router-view />
      </div>
    </div>
    <div v-else class="h-100">
      <!-- ログイン画面表示用 -->
      <router-view />
      <div class="text-muted text-center mt-2" style="font-size: 13px">
        {{ version }}
      </div>
    </div>
  </div>
</template>

<script>
import config from "./const/config";
import Util from "./mixin/utility";
import SideMenu from "./components/SideMenu/SideMenu.vue";

export default {
  mixins: [Util],
  name: "App",
  components: { SideMenu },
  data() {
    return {
      version: "バージョン：1.0.2",
    };
  },
  computed: {
    // Vuexに定義したStateを、利用できるようにする
    isLoading() {
      return this.$store.state.isLoading;
    },
    authErrorModalShow() {
      return this.$store.getters.getAuthErrorModalShow;
    },
    loggedIn() {
      return this.$store.state.userInfo.loggedIn;
    },
  },
  watch: {
    authErrorModalShow(val) {
      if (val) {
        this.showErrorAlert(config.MESSAGE.AUTHENTICATION_ERROR, "認証エラー");
      } else {
        this.$store.dispatch(config.STORE.INDEX.ACTION.authErrorModalHide);
      }
    },
  },
};
</script>
