<template>
  <div id="paid-edit" class="main" style>
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="有給入力"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div>
            <div class="p-4">
              <div>
                <b-row>
                  <!-- 取得数・残数表示エリア -->
                  <b-col cols="12">
                    <div class="paidInfoArea">
                      <b-table-simple fixed bordered class="mb-0">
                        <b-tr>
                          <!-- 社員(社員選択) -->
                          <b-th class="worker_name themeColoreHeader">
                            <h5>社員</h5>
                          </b-th>
                          <b-td
                            class="worker_name text-left"
                            @click="showSelectWorkerModal"
                          >{{ employeeData.employee_name }}</b-td>
                          <!-- 年間取得可能数 -->
                          <b-th class="possibleCount themeColoreHeader">
                            <h5>年間取得可能数</h5>
                          </b-th>
                          <b-td class="possibleCount text-right">
                            <span class="paidValue mr-1">
                              {{
                              getPaidDaysAnnual()
                              }}
                            </span>
                            <span class="paidUnit">日</span>
                            <span class="mx-2">と</span>
                            <span class="paidValue mr-1">
                              {{
                              getPaidTimeAnnual()
                              }}
                            </span>
                            <span class="paidUnit">時間</span>
                          </b-td>
                          <!-- 年間取得数 -->
                          <b-th class="themeColoreHeader">
                            <h5>年間取得数</h5>
                          </b-th>
                          <b-td class="text-right">
                            <span class="paidValue mr-1">
                              {{
                              getSumPaid()
                              }}
                            </span>
                            <span class="paidUnit">日</span>
                            <span class="mx-2">と</span>
                            <span class="paidValue mr-1">
                              {{
                              getSumPaidHour()
                              }}
                            </span>
                            <span class="paidUnit">時間</span>
                          </b-td>
                          <!-- 有給残数 -->
                          <b-th class="themeColoreHeader">
                            <h5>有給残数</h5>
                          </b-th>
                          <b-td class="text-right">
                            <span class="paidValue mr-1">
                              {{
                              getSumZanPaid()
                              }}
                            </span>
                            <span class="paidUnit">日</span>
                            <span class="mx-2">と</span>
                            <span class="paidValue mr-1">
                              {{
                              getSumZanPaidHour()
                              }}
                            </span>
                            <span class="paidUnit">時間</span>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </b-col>
                </b-row>
                <!-- 年度切り替え -->
                <b-row class="mt-2">
                  <b-col>
                    <div>
                      <b-form-radio-group
                        buttons
                        button-variant="outline-secondary"
                        v-model="targetStartYear"
                      >
                        <b-form-radio :value="thisStartYear">今年度</b-form-radio>
                        <b-form-radio :value="nextStartYear">翌年度</b-form-radio>
                      </b-form-radio-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <!-- カレンダー -->
              <div class="mt-2" v-show="yList.length > 0">
                <b-table-simple bordered class="paidDateListTable">
                  <!-- 上部ヘッダ -->
                  <b-thead>
                    <b-tr>
                      <b-th>年 月</b-th>
                      <b-th v-for="item in 31" :key="item" style="width: 30px">{{ item }}</b-th>
                      <b-th class="paidDayBackcolor" style="width: 60px">日数計</b-th>
                      <b-th class="paidHourBackcolor" style="width: 60px">時間計</b-th>
                      <b-th>年 月</b-th>
                    </b-tr>
                  </b-thead>
                  <!-- 5月~10月 -->
                  <b-tbody>
                    <b-tr v-for="(monthData, mIndex) in yList" :key="mIndex" v-show="mIndex <= 5">
                      <!-- 年月表示 -->
                      <b-th class="colYm">{{ monthData.ym }}</b-th>
                      <!-- 日 -->
                      <b-td
                        v-for="(dayItem, dIndex) in monthData.dayList"
                        :key="dIndex"
                        :class="getBackcolor(dayItem)"
                        :colspan="dayItem.paidDayCount"
                        @mousedown="tdClick(monthData.dayList, dIndex)"
                        @mouseover="tdMousover(dIndex)"
                        @mouseup="tdMousuup"
                      >
                        <!-- 一日有給 -->
                        <div
                          v-if="dayItem.isPaid && dayItem.paidType == 1"
                          class="h-100 w-100 pt-1"
                        >1d</div>
                        <!-- 時間有給 -->
                        <div
                          v-else-if="dayItem.isPaid && dayItem.paidType == 2"
                          class="h-100 w-100 pt-1"
                        >{{ dayItem.paidHour }}h</div>
                        <!-- 週末 -->
                        <div v-else-if="dayItem.weekDay == 7" class="h-100 w-100"></div>
                        <!-- 本日 -->
                        <div v-else-if="dayItem.isToday" class="h-100 w-100"></div>
                        <!--  -->
                        <div v-else></div>
                      </b-td>
                      <!--  -->
                      <b-td class="paidDayBackcolor">
                        {{
                        getMonthSumPaid(monthData.dayList) | emptyFilters
                        }}
                      </b-td>
                      <b-td class="paidHourBackcolor">
                        {{
                        getMonthSumPaidHour(monthData.dayList) | emptyFilters
                        }}
                      </b-td>
                      <!-- 年月表示 -->
                      <b-th class="colYm">{{ monthData.ym }}</b-th>
                    </b-tr>
                  </b-tbody>
                  <!-- 中間ヘッダ -->
                  <b-thead>
                    <b-tr>
                      <b-th>年 月</b-th>
                      <b-th v-for="item in 31" :key="item">{{ item }}</b-th>
                      <b-th class="paidDayBackcolor">日数計</b-th>
                      <b-th class="paidHourBackcolor">時間計</b-th>
                      <b-th>年 月</b-th>
                    </b-tr>
                  </b-thead>
                  <!-- 11月~翌年4月 -->
                  <b-tbody>
                    <b-tr v-for="(monthData, mIndex) in yList" :key="mIndex" v-show="mIndex > 5">
                      <!-- 年月表示 -->
                      <b-th class="colYm">{{ monthData.ym }}</b-th>
                      <!-- 日 -->
                      <b-td
                        v-for="(dayItem, dIndex) in monthData.dayList"
                        :key="dIndex"
                        :class="getBackcolor(dayItem)"
                        :colspan="dayItem.paidDayCount"
                        @mousedown="tdClick(monthData.dayList, dIndex)"
                        @mouseover="tdMousover(dIndex)"
                        @mouseup="tdMousuup"
                      >
                        <div v-if="dayItem.isReqTarget" class="h-100 w-100"></div>
                        <div
                          v-else-if="dayItem.isPaid && dayItem.paidType == 1"
                          class="h-100 w-100 pt-1"
                        >1d</div>
                        <div
                          v-else-if="dayItem.isPaid && dayItem.paidType == 2"
                          class="h-100 w-100 pt-1"
                        >{{ dayItem.paidHour }}h</div>
                        <!-- 週末 -->
                        <div v-else-if="dayItem.weekDay == 7" class="h-100 w-100"></div>
                        <!--  -->
                        <div v-else-if="dayItem.isToday" class="h-100 w-100"></div>
                        <div v-else></div>
                      </b-td>
                      <b-td class="paidDayBackcolor">
                        {{
                        getMonthSumPaid(monthData.dayList) | emptyFilters
                        }}
                      </b-td>
                      <b-td class="paidHourBackcolor">
                        {{
                        getMonthSumPaidHour(monthData.dayList) | emptyFilters
                        }}
                      </b-td>
                      <!-- 年月表示 -->
                      <b-th class="colYm">{{ monthData.ym }}</b-th>
                    </b-tr>
                  </b-tbody>
                  <!-- 下部ヘッダ -->
                  <b-thead>
                    <b-tr>
                      <b-th>年 月</b-th>
                      <b-th v-for="item in 31" :key="item">{{ item }}</b-th>
                      <b-th class="paidDayBackcolor">{{ getSumPaid() }}d</b-th>
                      <b-th class="paidHourBackcolor">{{ getSumPaidHour() }}h</b-th>
                      <b-th>年 月</b-th>
                    </b-tr>
                  </b-thead>
                </b-table-simple>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </main>
    <WorkerSelectModal v-model="employeeData" :propsIsPaidManagement="PAID_MANAGEMENT"></WorkerSelectModal>
    <PaidEditModal
      @parent-event="getPaidData"
      :propDateList="updateTargetDateList"
      :propEmployeeCd="employeeData.employee_cd"
    ></PaidEditModal>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader";
import WorkerSelectModal from "../components/WorkerSelectModal";
import PaidEditModal from "../components/Paid/PaidEditModal";
import moment from "moment";
import config from "../const/config";

export default {
  name: "paidEdit",
  components: {
    BaseHeader,
    WorkerSelectModal,
    PaidEditModal,
  },
  data() {
    return {
      //対象年度の開始年
      targetStartYear: 0,
      //今年度の開始年
      thisStartYear: 0,
      //翌年度の開始年
      nextStartYear: 0,
      //対象社員データ
      employeeData: {
        employee_cd: null,
        employee_name: null,
      },
      //
      baseList: [],
      //有給データ
      paidData: { paid_days_annual: 0, paid_time_annual: 0, paidList: [] },
      //描画データ
      yList: [],
      //--更新日付選択
      flg: false,
      startAreaKey: null,
      endAreaKey: null,
      targetDataList: null,
      // 選択した更新対象日保持
      updateTargetDateList: [],
      //有給残管理有り
      PAID_MANAGEMENT: config.VALUE.PAID_MANAGEMENT,
    };
  },
  filters: {
    emptyFilters: function (value) {
      if (value == 0) {
        return "";
      }
      return value;
    },
  },
  computed: {
    isThisYear: function () {
      return this.thisStartYear == this.targetStartYear;
    },
  },
  watch: {
    //社員変更時
    employeeData: function () {
      //有給データを付与
      this.getPaidData();
    },
    //対象年度の変更時
    targetStartYear: function () {
      if (this.targetStartYear == 0) {
        return;
      }
      //カレンダーを構成する基本データ作成
      this.cleateBaseInnitList();
      //有給データを付与した描画用カレンダーデータを生成
      this.addPaidData();
    },
  },
  mounted: function () {
    this.createStartYear();
    this.showSelectWorkerModal();
  },
  methods: {
    //有給データ初期化
    initPaidData: function () {
      this.paidData = {
        paid_days_annual: 0,
        paid_time_annual: 0,
        paidList: [],
      };
      this.yList = [];
    },
    //年度の開始年を設定
    createStartYear: function () {
      //現在日付から、年度の開始年を取得
      let today = moment();
      let todayMonth = today.month() + 1;
      this.thisStartYear = today.year();
      if (todayMonth < config.VALUE.YEAR_START_MONTH) {
        //昨年が年度開始年
        let lastYear = today.subtract(1, "years");
        this.thisStartYear = lastYear.year();
      }
      this.targetStartYear = this.thisStartYear;
      //翌年度の開始年
      this.nextStartYear = this.thisStartYear + 1;
    },
    //1.基本カレンダーデータ生成
    cleateBaseInnitList: function () {
      this.yList = [];
      let baseList = [];
      let targetYear = this.targetStartYear;
      let targetMonth = config.VALUE.YEAR_START_MONTH;
      //年度の空データ生成
      for (let i = 0; i < 12; i++) {
        if (targetMonth == 13) {
          //年の切り替わり
          targetMonth = 1;
          targetYear++;
        }
        //月ごとの空データ生成
        baseList.push(this.getCreateBaseMouthData(targetYear, targetMonth));
        targetMonth++;
      }
      this.baseList = baseList;
    },
    //1.1月の基本データ生成（有給情報未設定状態）
    getCreateBaseMouthData: function (vYear, vMonth) {
      //--対象年月の最終日を取得
      let m = moment({ year: vYear, month: vMonth - 1, day: 1 });
      let lastDate = this.getTargetMonthEndDate(m);
      let lastDay = this.getDay(lastDate);

      //月の基本データリストを生成
      let daylist = [];
      for (let dayNo = 1; dayNo <= 31; dayNo++) {
        let noneDay = false;
        let weekDay = 0;
        let ymd = null;
        if (dayNo > lastDay) {
          //存在しない日にち（31日分のリストのため）
          noneDay = true;
        } else {
          let dm = null;
          dm = moment({ year: vYear, month: vMonth - 1, day: dayNo });
          weekDay = dm.isoWeekday();
          ymd = this.getDate(dm);
        }
        //有給情報初期値（未設定）の日別データ
        let dayData = {
          strHyphenDate: ymd,
          day: dayNo,
          isPaid: false,
          paidType: null,
          paidDayCount: null,
          paidHour: null,
          isToday: false,
          isNoneDay: noneDay,
          weekDay: weekDay,
          isReqTarget: false,
        };
        daylist.push(dayData);
      }
      let ym = "";
      if (vMonth == 5 || vMonth == 1) {
        ym = vYear + " / " + vMonth;
      } else {
        ym = "" + vMonth;
      }
      let mBaseData = {
        ym: ym,
        endDay: lastDay,
        dayList: daylist,
      };
      return mBaseData;
    },
    //--------------------------
    // 有給の登録・更新対象日付選択
    //--------------------------
    updateReqTarget: function (index) {
      this.targetDataList[index].isReqTarget = true;
    },
    rangeUpdateReqTarget: function () {
      if (this.startAreaKey != null && this.endAreaKey != null) {
        let start = this.startAreaKey;
        let end = this.endAreaKey;
        if (start > end) {
          start = this.endAreaKey;
          end = this.startAreaKey;
        }
        for (start; start <= end; start++) {
          this.updateReqTarget(start);
        }
      }
    },
    // 日付クリック
    tdClick: function (dayList, dIndex) {
      if (this.employeeData.employee_cd == null) {
        //社員が選択されていない場合
        return;
      }
      if (dayList[dIndex].isNoneDay) {
        //存在しない日付の場合
        return;
      } else if (dayList[dIndex].isPaid) {
        //有給が登録済みの日付の場合
        this.updateTargetDateList = [];
        this.updateTargetDateList.push(dayList[dIndex]);
        this.showPaidEditModal();
        return;
      } else {
        this.flg = true;
        this.startAreaKey = dIndex;
        this.endAreaKey = dIndex;
        this.targetDataList = dayList;
        this.updateReqTarget(dIndex);
      }
    },
    // 日付離す
    tdMousuup: function () {
      let me = this;
      let hasPaidData = false;
      let inWeekEnd = false;
      //編集停止
      this.flg = false;
      //初期化
      this.updateTargetDateList = [];

      //選択更新
      this.rangeUpdateReqTarget();

      // 選択した日付を、更新対象日付リストにセット
      this.targetDataList.forEach(function (element) {
        if (element.isReqTarget) {
          if (element.isPaid) {
            // /登録済み有給データ有り
            hasPaidData = true;
          }
          if (element.weekDay == 7) {
            // 週末（日曜）が含まれる
            inWeekEnd = true;
          }
          me.updateTargetDateList.push({ ...element });
        }
      });

      if (hasPaidData) {
        //-- 登録済み有給データ有り
        this.showErrorAlert("取得済みの日付が選択されています。");
        this.getPaidData();
      } else if (inWeekEnd) {
        //-- 週末（日曜）が含まれる
        this.showErrorAlert("日曜日が選択されています。");
        this.getPaidData();
      } else {
        //有給情報登録・編集モーダルを開く
        this.showPaidEditModal();
      }

      // --初期化
      this.startAreaKey = null;
      this.endAreaKey = null;
      this.targetDataList = null;
    },
    // 日付ホバー
    tdMousover: function (dIndex) {
      if (!this.flg || this.targetDataList[dIndex].isNoneDay) {
        return;
      }
      this.endAreaKey = dIndex;
      this.rangeUpdateReqTarget();
    },
    // 有給情報変更モーダル表示
    showPaidEditModal: function () {
      //締日チェックをし、OKであればモーダルを表示する
      let me = this;
      if (this.updateTargetDateList.length <= 0) {
        return;
      }
      //登録・更新対象日の開始日を取得
      let strHyphenDate = this.updateTargetDateList[0].strHyphenDate;
      let param = {
        startTargetDate: strHyphenDate,
        employee_cd: this.employeeData.employee_cd,
      };
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(config.URL.PAID_EDIT + "/checkClosedDate", param)
        .then((response) => {
          if (response.result) {
            //編集登録モーダル表示
            me.$bvModal.show("paid-edit-modal");
          } else {
            //error発生
            me.$alert(response.detail.error, "エラー", {
              confirmButtonText: "OK",
              type: "error",
              callback: () => {
                me.getPaidData();
              },
            });
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //--------------------------
    // 社員選択
    //--------------------------
    // 社員変更モーダル表示
    showSelectWorkerModal: function () {
      this.$bvModal.show("worker-select-modal");
    },
    //--------------------------
    // 取得済みの有給情報を取得
    //--------------------------
    //2.有給登録情報を取得
    getPaidData: function () {
      //初期化
      this.initPaidData();
      //
      if (this.employeeData.employee_cd == null) {
        return;
      }
      //有給登録情報を取得
      let me = this;
      let param = {
        employee_cd: this.employeeData.employee_cd,
      };
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(config.URL.PAID_EDIT + "/getYearPaidList", param)
        .then((response) => {
          if (response.result) {
            // console.log(response.container);
            if (response.container != null) {
              me.paidData = response.container;
            }
            me.addPaidData();
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //2.有給データ付与
    addPaidData: function () {
      let me = this;
      let showData = [];
      this.baseList.forEach(function (baseMonthItem) {
        //有給データ情報付与
        showData.push(me.createShowData(baseMonthItem, me.paidData.paidList));
      });
      this.yList = showData;
    },
    //2.1有給情報を付与した月のデータを返却
    createShowData: function (baseMonthItem, paidList = []) {
      let dayList = [];
      baseMonthItem.dayList.forEach(function (element) {
        //深いコピー
        let dayData = { ...element };

        //日付が一致する有給データを取得
        let data = paidList.filter(
          (el) => el.paid_date == dayData.strHyphenDate
        );
        if (data && data.length > 0) {
          //一致する日付が存在した場合
          dayData.isPaid = true;
          //有給種別をセット
          dayData.paidType = data[0].paid_type;
          if (dayData.paidType == 1) {
            //1日有給
            dayData.paidDayCount = 1;
          } else {
            //時間有給
            dayData.paidHour = data[0].paid_hour;
          }
        }

        dayList.push(dayData);
      });

      return {
        ym: baseMonthItem.ym,
        endDay: baseMonthItem.lastDay,
        dayList: dayList,
      };
    },
    // 1日有給の取得日数合計(月単位)を取得
    getMonthSumPaid: function (dateList) {
      let count = 0;
      dateList.forEach(function (item) {
        if (item.paidType == 1) {
          count += item.paidDayCount;
        }
      });
      return count;
    },
    // 時間有給の取得時間合計(月単位)を取得
    getMonthSumPaidHour: function (dateList) {
      let count = 0;
      dateList.forEach(function (item) {
        if (item.paidType == 2) {
          count += item.paidHour;
        }
      });
      return count;
    },
    //--------------------------
    // 有給の全体値
    //--------------------------
    //年間取得可能数（日）を取得
    getPaidDaysAnnual: function () {
      if (this.paidData == null) {
        return 0;
      }
      if (this.isThisYear) {
        return this.paidData.paid_days_annual;
      } else {
        return 0;
      }
    },
    //年間取得可能数（時間ｓ）を取得
    getPaidTimeAnnual: function () {
      if (this.paidData == null) {
        return 0;
      }
      if (this.isThisYear) {
        return this.paidData.paid_time_annual;
      } else {
        return 0;
      }
    },
    //年間取得数（1日有給）
    getSumPaid: function () {
      let count = 0;
      let lo = this;
      this.yList.forEach(function (item) {
        count += lo.getMonthSumPaid(item.dayList);
      });
      return count;
    },
    //年間取得数（時間有給）
    getSumPaidHour: function () {
      let count = 0;
      let lo = this;
      this.yList.forEach(function (item) {
        count += lo.getMonthSumPaidHour(item.dayList);
      });
      return count;
    },
    //有給残数（1日有給）
    getSumZanPaid: function () {
      if (this.paidData == null) {
        return 0;
      }
      if (this.isThisYear) {
        if (this.paidData.paid_days_annual) {
          return this.paidData.paid_days_annual - this.getSumPaid();
        } else {
          return 0;
        }
      } else {
        return 0 - this.getSumPaid();
      }
    },
    //有給残数（時間有給）
    getSumZanPaidHour: function () {
      if (this.paidData == null) {
        return 0;
      }
      if (this.isThisYear) {
        if (this.paidData.paid_time_annual) {
          return this.paidData.paid_time_annual - this.getSumPaidHour();
        } else {
          return 0;
        }
      } else {
        return 0 - this.getSumPaidHour();
      }
    },
    //--------------------------
    // 描画
    //--------------------------
    //日付セルの背景色設定
    getBackcolor: function (dayItem) {
      let setClass = "p-0 ";

      if (dayItem.isNoneDay) {
        setClass = " right_up_border";
      } else if (dayItem.isReqTarget) {
        setClass += " reqTargetBackcolor";
      } else if (dayItem.isPaid) {
        if (dayItem.paidType == 1) {
          setClass += " paidDayBackcolor";
        } else {
          setClass += " paidHourBackcolor";
        }
      } else if (dayItem.isToday) {
        setClass += " todayBackColor";
      } else if (dayItem.weekDay == 6 || dayItem.weekDay == 7) {
        setClass += " weekEndBackcolor";
      }

      return setClass;
    },
  },
};
</script>

<style>
#paid-edit .main_area {
  min-width: 1650px;
}

/* シルバー背景 */
.silverArea {
  border-radius: 5px;
  background: rgba(238, 238, 238, 0.363);
}
.paidInfoArea .paidValue{
  font-size: 22px;;
}
.paidInfoArea .paidUnit{
  font-size: 15px;;
}

.paidInfoArea th {
  text-align: center;
  word-break: keep-all;
  width: 150px;
}
.paidInfoArea td {
  word-break: keep-all;
}
/* 年間取得可能数 */
.paidInfoArea th.possibleCount {
  width: 190px;
}
/* 社員名 */
.paidInfoArea th.worker_name {
  width: 160px;
}
.paidInfoArea td.worker_name {
  width: 330px;
}

/** 有給日付テーブル-------------------------------*/
#paid-edit .paidDateListTable td,
#paid-edit .paidDateListTable th {
  border-color: var(--darkBorderColor);
  text-align: center;
  padding: 5px;
}
#paid-edit .paidDateListTable th {
  font-size: 14px;
  height: 14px;
  line-height: 14px;
}
#paid-edit .paidDateListTable td {
  font-size: 18px;
  line-height: 39px;
  height: 50px;
  width: 40px;
}
#paid-edit .paidDateListTable .colYm {
  text-align: right;
  padding: 5px 15px;
  width: 80px;
}
/* 斜線 */
#paid-edit .paidDateListTable td.right_up_border {
  background-image: linear-gradient(
    -53deg,
    /*角度*/ transparent 47%,
    black 50%,
    /*斜線の色*/ black 50%,
    /*斜線の色*/ transparent 51%,
    transparent
  );
}
</style>
