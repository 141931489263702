<template>
  <div id="schedule" class="main">
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="スケジュール入力"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="p-4">
            <div>
              <div>
                <b-row>
                  <b-col cols="6">
                    <div class="changeDateArea">
                      <b-input-group size="lg">
                        <b-input
                          readonly
                          v-model="showTargetYearMonth"
                          class="text-center showOnry"
                          style="max-width: 300px"
                        ></b-input>
                        <b-input-group-append>
                          <b-button @click="lastMonth" variant>＜＜</b-button>
                          <b-button @click="nextMonth" variant>＞＞</b-button>
                          <b-button class="ml-1" @click="nowMonth" variant
                            >今月</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="mt-2">
                <b-table-simple
                  bordered
                  class="monthCalendar boldBorder"
                  style="min-height: 40vh"
                >
                  <b-thead>
                    <b-tr>
                      <b-th
                        rowspan="2"
                        class="workerName rightBoldBorder bottomBoldBorder"
                        >氏 名</b-th
                      >
                      <b-th
                        v-for="(item, index) in baseDateList"
                        :key="index"
                        :class="getWeekColorClass(true, item.weekDay)"
                        >{{ item.day }}</b-th
                      >
                    </b-tr>
                    <b-tr>
                      <b-th
                        class="colDay"
                        v-for="(item, index) in baseDateList"
                        :key="index"
                        :class="
                          getWeekColorClass(true, item.weekDay) +
                          ' bottomBoldBorder'
                        "
                        >{{ strWeekDay[item.weekDay] }}</b-th
                      >
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(item, index) in scheduleList"
                      :key="index"
                      :class="(index + 1) % 5 == 0 ? 'bottomBoldBorder' : ''"
                    >
                      <b-td class="text-left workerName rightBoldBorder">{{
                        item.userName
                      }}</b-td>
                      <!-- 日 -->
                      <b-td
                        v-for="(citem, cIndex) in item.dateList"
                        :key="cIndex"
                        @click="dateSelectHandle(item, citem)"
                        :class="
                          getWeekColorClass(false, citem.weekDay) +
                          '  align-middle'
                        "
                        v-b-popover.hover="citem.content"
                      >
                        <div v-if="citem.hasSchedule" class="w-100">
                          <b-icon variant="info" icon="circle-fill"></b-icon>
                        </div>
                        <div v-else></div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </main>
    <scheduleEditModal
      :propContent="editScheduleContent"
      :userCd="editUserCd"
      :userName="editUserName"
      :propTargetDate="editTargetDate"
      @parent-event="getScheduleData"
    ></scheduleEditModal>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader";
import moment from "moment";
import scheduleEditModal from "../components/Schedule/ScheduleEditModal.vue";
import config from "../const/config";

export default {
  name: "schedule",
  components: {
    BaseHeader,
    scheduleEditModal,
  },
  data() {
    return {
      baseDateList: [],
      scheduleList: [],
      targetDate: null,
      showTargetYearMonth: "",
      strWeekDay: config.VALUE.strWeekDay,
      editUserCd: "",
      editUserName: "",
      editTargetDate: "",
      editScheduleContent: null,
    };
  },
  computed: {},
  watch: {
    targetDate: function () {
      this.baseDateList = [];
      this.scheduleList = [];
      if (this.targetDate != null) {
        this.showTargetYearMonth = this.getStrYmKanji(this.targetDate);
        this.createList();
      } else {
        this.showTargetYearMonth = "";
      }
    },
  },
  mounted: function () {
    this.targetDate = moment();
  },
  methods: {
    // --月の変更
    nowMonth: function () {
      this.targetDate = moment();
    },
    lastMonth: function () {
      if (this.targetDate != null) {
        this.targetDate = moment(this.targetDate).subtract(1, "M").format();
      }
    },
    nextMonth: function () {
      if (this.targetDate != null) {
        this.targetDate = moment(this.targetDate).add(1, "M").format();
      }
    },
    // 日付選択時
    dateSelectHandle: function (item, citem) {
      this.editUserCd = item.userCd;
      this.editUserName = item.userName;
      this.editTargetDate = citem.date;
      this.editScheduleContent = citem.content;
      this.showScheduleEdioModal();
    },
    // 編集モーダル表示
    showScheduleEdioModal: function () {
      this.$bvModal.show("schedule-edit-modal");
    },
    // --画面作成
    createList: function () {
      let today = this.getStrDateHyphen(moment());
      //月初
      let startYmd = this.getTargetMonthStartDate(this.targetDate);
      //月末
      let endYmd = this.getTargetMonthEndDate(this.targetDate);
      //最終日
      let endDate = this.getDay(endYmd);
      for (let i = 1; i <= endDate; i++) {
        let dateValue = moment(startYmd).add(i - 1, "d");
        this.baseDateList.push(this.initData(i, dateValue, today));
      }
      this.getScheduleData();
    },
    initData: function (dayValue, dateValue, todayValue) {
      let setWeekDay = moment(dateValue).isoWeekday();
      let isToday = false;
      if (todayValue == this.getStrDateHyphen(dateValue)) {
        isToday = true;
      }
      return {
        date: this.getStrDateHyphen(dateValue),
        day: dayValue,
        weekDay: setWeekDay,
        hasSchedule: false,
        content: "",
        isToday: isToday,
      };
    },
    getScheduleData: function () {
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      let lo = this;
      // --スケジュールをサーバから取得
      //月初
      let startYmd = this.getNowMonthStartDate(this.targetDate);
      //月末
      let endYmd = this.getNowMonthEndDate(this.targetDate);

      // パラメータ作成
      let param = {
        startTargetDate: startYmd,
        endTargetDate: endYmd,
      };
      // リクエスト
      this.getDataList(config.URL.SCHEDULE + config.PROCESS.FOR_ALL_USER, param)
        .then((response) => {
          if (response.result) {
            // スケジュールセット
            lo.createShowEmployeeScheduleDataList(response.container);
          } else {
            lo.showServerError();
          }
        })
        .catch((e) => {
          // 空データで作成
          lo.createShowEmployeeScheduleDataList([]);
          lo.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    createShowEmployeeScheduleDataList: function (employeeScheduleDataList) {
      let me = this;
      me.scheduleList = [];
      employeeScheduleDataList.forEach(function (element) {
        me.scheduleList.push(
          me.createShowData(
            element.user_cd,
            element.user_name,
            element.scheduleDateList
          )
        );
      });
    },
    createShowData: function (userCd, userName, dateList) {
      let setDateList = [];

      this.baseDateList.forEach(function (element) {
        let data = dateList.filter((el) => el.schedule_date == element.date);
        if (data && data.length > 0) {
          //有給データあり
          let addDate = { ...element };
          addDate.hasSchedule = true;
          addDate.content = data[0].content;
          setDateList.push(addDate);
        } else {
          //有給無し
          setDateList.push({ ...element });
        }
      });

      let reData = {
        userCd: userCd,
        userName: userName,
        dateList: setDateList,
      };

      return reData;
    },
    //曜日の色をセット
    getWeekColorClass: function (isTh, weekDay) {
      let setClass = " ";
      if (isTh) {
        if (weekDay == 7) {
          setClass += "weekEndBackcolor sundayColor";
        } else if (weekDay == 6) {
          setClass += " weekEndBackcolor saturdayColor ";
        }
      } else {
        setClass += "p-0 ";
        if (weekDay == 7) {
          setClass += " weekEndBackcolor ";
        } else if (weekDay == 6) {
          setClass += " weekEndBackcolor ";
        }
      }
      return setClass;
    },
  },
};
</script>

<style>
#schedule .main_area {
  min-width: 1200px;
}

/* 月度変更 --------------------------------------*/
#schedule .changeDateArea {
  width: 500px;
}

#schedule .monthCalendar .colDay {
  width: 42px;
  max-width: 42px;
}
</style>
