<template>
  <b-button v-if="propAuth == AUTHORIZED" @click="clickLinkBtn()" variant="light" :class="buttonClass">
    <b-icon :icon="propBtnIcon" :variant="propBtnIconVariant" class="mr-2"></b-icon>{{ propBtnText }}
  </b-button>
</template>

<script>
/* eslint-disable */
import config from "../../const/config";

export default {
  name: "SideMenuBtn",
  props: {
    //リンクPath
    propsLinkPagePath: { type: String, default: "" },
    //ボタン関連ページパスリスト
    propsRelatedPages: { type: Array, default: () => [] },
    //ボタンアイコン
    propBtnIcon: { type: String, default: "" },
    //ボタンアイコンカラー
    propBtnIconVariant: { type: String, default: "" },
    //ボタンタイトル
    propBtnText: { type: String, default: "" },
    //権限
    propAuth: { type: Number, default: config.VALUE.AUTHORIZED },
  },
  data: function () {
    return {
      AUTHORIZED: config.VALUE.AUTHORIZED,
    };
  },
  computed: {
    buttonClass: function () {
      let selectedMe = false;
      let nowPage = this.$route.path;
      this.propsRelatedPages.forEach((element) => {
        if (nowPage == element) {
          selectedMe = true;
        }
      });

      let resultClass = "Nken_SideMenu__LinkBtn";
      if (selectedMe) {
        resultClass += " Nken_SideMenu__LinkBtn_Selected";
      }

      return resultClass;
    },
  },
  methods: {
    clickLinkBtn: function () {
      if (this.propsLinkPagePath == "") {
        return;
      }

      this.$emit("clickLinkBtn");
      this.$router.push(this.propsLinkPagePath);
    },
  },
};
</script>

<style scoped>
.Nken_SideMenu__LinkBtn {
  font-size: 0.9rem;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: rgb(41, 41, 41);
  background: #ffffff;
  border-radius: 0;
  text-align: left;
}

.Nken_SideMenu__LinkBtn_Selected {
  background: #0375cc !important;
  color: rgb(255, 255, 255) !important;
}

.Nken_SideMenu__LinkBtn_Selected .b-icon {
  color: rgb(255, 255, 255) !important;
}
</style>
