<template>
  <b-modal
    id="add-folder-modal"
    size="lg"
    :centered="true"
    hide-footer
    header-class="modal_header"
    :no-close-on-backdrop="false"
    v-model="isModalShow"
  >
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">フォルダ追加</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="px-5">
        <div>
          <b-form-group label="フォルダ名">
            <b-input type="text" v-model="folderName"></b-input>
          </b-form-group>
        </div>
        <div>
          <b-row no-gutters="true">
            <b-col cols="6">
              <!-- <b-button @click="handleCancel" variant="danger">キャンセル</b-button> -->
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button @click="handleAdd" variant="success">追加</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import config from "../../const/config";

export default {
  name: "AddFolderModal",
  props: [],
  data: function () {
    return {
      // モーダル表示状態
      isModalShow: false,
      //フォルダ名
      folderName: null,
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        this.folderName = null;
      } else {
        // モーダル閉じるとき
      }
    },
  },
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.isModalShow = false;
    },
    isValid: function () {
      if (this.isNullOrEmpty(this.folderName)) {
        this.showErrorAlert("フォルダ名が入力されていません。", "フォルダ追加");
        return false;
      }
      return true;
    },
    handleCancel:function(){
      this.hideModal;
    },
    handleAdd: function () {
      if (!this.isValid()) {
        return;
      }

      this.addFolder();
    },
    // フォルダ追加
    addFolder: function () {
      // パラメータ作成
      let req = {
        folderName: this.folderName,
      };
      // リクエスト
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(config.URL.SHARED_FOLDER, req)
        .then((response) => {
          if (response.result) {
            //成功
            me.successAddFolder();
          } else {
            //失敗
            me.showErrorAlert(response.detail.error, "フォルダ作成");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    successAddFolder: function () {
      this.showUpdateSuccess("成功しました。","フォルダ作成");
      this.$emit("parent-event");
      this.hideModal();
    },
  },
};
</script>

<style>
#worker-select-modal .workerSelectTable {
  max-height: 450px;
  font-size: 14px;
}
</style>