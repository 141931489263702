import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import userInfo from "./modules/userInfo.js"

Vue.use(Vuex)
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    userInfo,
  },
  state: {
    userId: "",
    userName: "",
    employee_cd: "",
    construction_cd: "",
    work_date: "",
    targetDate: "",
    isLoading: false,
    authErrorModalShow: false,
  },
  getters: {
    getTargetDate: state => {
      return state.targetDate;
    },
    getAuthErrorModalShow: state => {
      return state.authErrorModalShow;
    }
  },
  mutations: {
    setIsLoading: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    setAuthErrorModalShow: (state, authErrorModalShow) => {
      state.authErrorModalShow = authErrorModalShow;
    },
    setLoginUserInfo: function (state, param) {
      state.userId = param.userId;
      state.userName = param.userName;
    },
    //就業日報時間編集画面のキーを更新
    setWorkRegistKey: function (state, param) {
      state.construction_cd = param.construction_cd;
      state.work_date = param.work_date;
    }
  },
  actions: {
    startLoading({ commit }) {
      commit("setIsLoading", true);
    },
    stopLoading({ commit }) {
      commit("setIsLoading", false);
    },
    authErrorModalShow({ commit }) {
      commit("setAuthErrorModalShow", true);
    },
    authErrorModalHide({ commit }) {
      commit("setAuthErrorModalShow", false);
    },
  },
  plugins: [createPersistedState({ key: 'vuex_nakamura_k', storage: window.sessionStorage })],
})