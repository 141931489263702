<template>
  <b-modal
    id="construction-select-modal"
    size="xl"
    :centered="true"
    hide-footer
    header-class="modal_header"
    v-model="isModalShow"
  >
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">工事選択</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="px-3">
        <b-row class="constructionArea">
          <b-col cols="5">
            <b-card header="分類" class="h-100">
              <!-- 分類ツリー -->
              <el-tree
                :data="catergoryTree"
                node-key="1"
                class="mt-2"
                style="max-height: 380px; overflow-y: auto"
                :props="defaultProps"
                ref="tree"
                @node-click="handleNodeClick"
              ></el-tree>
            </b-card>
          </b-col>
          <b-col cols="7">
            <div>
              <div>
                <b-table
                  class="constructionSelectTable mb-0 h-100"
                  sticky-header
                  small
                  bordered
                  selectable
                  select-mode="single"
                  :fields="fields"
                  :items="constructionItems"
                  @row-selected="onRowSelected"
                  style="margin-bottom: 0"
                ></b-table>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="text-center mt-4" ref="test">
          <b-button class="px-5" variant="success" @click="handleOk">OK</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import config from "../../const/config";
export default {
  name: "ConstructionSelectModal",
  props: [],
  data: function () {
    return {
      isModalShow: false,
      catergoryTree: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectConstruction: null,
      fields: [
        {
          key: "construction_cd",
          label: "コード",
          sortable: true,
          thClass: "thCode themeColoreHeader",
          tdClass: "text-center",
        },
        {
          key: "construction_name",
          label: "工事名",
          sortable: false,
          thClass: "themeColoreHeader",
        },
      ],
      constructionItems: [],
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        this.getWithPeriodConstruction();
        this.getCatergoryTree();
      }
    },
  },
  methods: {
    initCategory: function () {
      this.catergoryTree = [
        {
          label: "すべて",
          treeLevel: config.VALUE.TREE_LEVEL_ALL,
          catergoryLCd: null,
          catergoryMCd: null,
          catergorySCd: null,
        },
        {
          label: "工期中",
          treeLevel: config.VALUE.TREE_LEVEL_WITH_PERIOD,
          catergoryLCd: null,
          catergoryMCd: null,
          catergorySCd: null,
        },
      ];
    },
    //工事分類取得
    getCatergoryTree: function () {
      let me = this;

      //初期化
      this.initCategory();
      //サーバーから取得
      this.getDataList(config.URL.CONSTRUCTION + "/getCatergoryTree")
        .then((response) => {
          if (response.result) {
            let categoryList = response.container;
            if (categoryList != null || categoryList.length > 0) {
              categoryList.forEach((element) => {
                me.catergoryTree.push(element);
              });
            }
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "工事分類取得エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    //全て取得
    getAllConstruction: function () {
      //分類と工期の絞り込み無しで検索
      this.searchConstruction(null, null, false);
    },
    //工期で絞り込み取得
    getWithPeriodConstruction: function () {
      this.searchConstruction(null, null, true);
    },
    //
    handleNodeClick: function (data) {
      if (data.treeLevel == config.VALUE.TREE_LEVEL_ALL) {
        this.getAllConstruction();
      } else if (data.treeLevel == config.VALUE.TREE_LEVEL_WITH_PERIOD) {
        this.getWithPeriodConstruction();
      } else {
        this.searchConstruction(data.catergoryLCd, data.catergorySCd, false);
      }
    },
    //工事検索
    searchConstruction: function (catergoryLCd, catergorySCd, isWithinPeriod) {
      let me = this;

      let ConstructionSearchConditions = {
        catergory_l_cd: catergoryLCd,
        catergory_s_cd: catergorySCd,
        isWithinPeriod: isWithinPeriod,
      };
      //初期化
      this.constructionItems = [];
      //サーバーから取得
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(
        config.URL.CONSTRUCTION + config.PROCESS.SEARCH,
        ConstructionSearchConditions
      )
        .then((response) => {
          if (response.result) {
            me.constructionItems = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.$root.$emit("bv::hide::modal", "construction-select-modal", "");
    },
    onRowSelected(items) {
      this.selectConstruction = items[0];
    },
    handleOk: function () {
      if (this.selectConstruction == null) {
        this.$alert("工事が選択されていません。", "", {
          confirmButtonText: "OK",
        });
      } else {
        this.$emit("constructionSelect", this.selectConstruction);
        this.hideModal();
      }
    },
  },
};
</script>

<style>
#construction-select-modal .constructionSelectTable {
  max-height: 510px;
  font-size: 14px;
}

#construction-select-modal .constructionSelectTable table {
  max-height: 510px;
}

.constructionArea {
  min-height: 510px;
}

th.thCode {
  text-align: center;
  width: 80px;
}
</style>