<template>
  <div id="work-base" class="main">
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="日報入力"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="pt-2 px-4">
            <div>
              <b-row>
                <b-col cols="8">
                  <!-- 工事 -->
                  <div>
                    <b-form-group label="工事" :label-size="titleLabelSize" label-class="itemTitle">
                      <b-input-group size="sm">
                        <b-select :options="constructionOptions" v-model="constructionCd"></b-select>
                        <b-input-group-append>
                          <b-button @click="showConstructionSelectModal" variant="primary">工事変更</b-button>
                          <b-button
                            class="ml-3"
                            variant="primary"
                            @click="showSelectDateModalForCopy"
                            :disabled="isUnselectedConstruction"
                          >前回作業日コピー</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <!-- 業務概要 -->
                  <div class="pt-2">
                    <b-form-group label="業務概要" :label-size="titleLabelSize" label-class="itemTitle">
                      <!-- <b-card> -->
                      <b-row>
                        <b-col cols="6">
                          <WorkSelectOverview
                            v-model="workData.job_content_1"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="1"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_2"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="2"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_3"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="3"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_4"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="4"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_5"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="5"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                        </b-col>
                        <b-col cols="6">
                          <WorkSelectOverview
                            v-model="workData.job_content_6"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="6"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_7"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="7"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_8"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="8"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_9"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="9"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                          <WorkSelectOverview
                            v-model="workData.job_content_10"
                            :propIsDisaibled="isUnselectedConstruction"
                            propLabel="10"
                            :propOptions="workOverviewOptions"
                            @setEvent="handleRegist(false)"
                          ></WorkSelectOverview>
                        </b-col>
                      </b-row>
                      <!-- </b-card> -->
                    </b-form-group>
                  </div>
                  <!-- 担当技術者 -->
                  <div class="pt-1">
                    <b-form-group
                      label="担当技術者"
                      :label-size="titleLabelSize"
                      label-class="itemTitle"
                    >
                      <div>
                        <b-button
                          :size="buttonBaseSize"
                          class="px-5"
                          variant="primary"
                          @click="showEmployeeWorkersSlectModal(true)"
                          :disabled="isUnselectedConstruction"
                        >社員選択</b-button>
                      </div>
                      <div class="mt-1">
                        <b-card no-body class="selectManagerArea">
                          <b-card-body class="p-2">
                            <div class="d-flex flex-wrap align-content-start">
                              <div
                                v-for="(item, index) in workData.worker"
                                :key="`employee-${index}`"
                                class="selectWorkerName"
                                v-show="item.worker_type == 0 && item.is_manager == 1"
                              >
                                {{ item.worker_name }}
                                <b-icon icon="x" @click="handleCancelSelectWorker(item)" role="button"></b-icon>
                              </div>
                            </div>
                          </b-card-body>
                        </b-card>
                      </div>
                    </b-form-group>
                  </div>
                  <!-- 従事者 -->
                  <div class="pt-1">
                    <b-form-group label="従事者" :label-size="titleLabelSize" label-class="itemTitle">
                      <div>
                        <b-button
                          :size="buttonBaseSize"
                          class="px-5"
                          variant="primary"
                          @click="showEmployeeWorkersSlectModal(false)"
                          :disabled="isUnselectedConstruction"
                        >社員選択</b-button>
                        <b-button
                          :size="buttonBaseSize"
                          class="ml-1 px-5"
                          variant="primary"
                          @click="showPartnerCompanyWorkersSlectModal"
                          :disabled="isUnselectedConstruction"
                        >協力会社選択</b-button>
                      </div>
                      <div class="mt-1">
                        <b-card no-body class="selectWorkerArea">
                          <b-card-body class="p-2">
                            <div class="d-flex flex-wrap align-content-start pb-1">
                              <div
                                v-for="(item, index) in workData.worker"
                                :key="`employee-${index}`"
                                class="selectWorkerName"
                                v-show="item.worker_type == 0 && item.is_manager == 0"
                              >
                                {{ item.worker_name }}
                                <b-icon icon="x" @click="handleCancelSelectWorker(item)" role="button"></b-icon>
                              </div>
                            </div>
                            <div class="d-flex flex-wrap align-content-start pb-1">
                              <div
                                v-for="(item, index) in workData.worker"
                                :key="`partner-${index}`"
                                class="selectWorkerName selectWorkerNameCompany"
                                v-show="item.worker_type == 9"
                              >
                                {{ item.worker_name }}
                                <b-icon icon="x" @click="handleCancelSelectWorker(item)" role="button"></b-icon>
                              </div>
                            </div>
                          </b-card-body>
                        </b-card>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="4" class="pl-4">
                  <div class="d-flex">
                    <!-- 日付選択 -->
                    <div>
                      <b-form-group
                        label="作業日付"
                        :label-size="titleLabelSize"
                        label-class="itemTitle"
                      >
                        <div class="mt-0 text-center">
                          <b-calendar
                            v-model="selectedDate"
                            @selected="onSelectedDateChange"
                            label-help
                            locale="ja"
                            :date-info-fn="dateClass"
                            @context="onCalendarContext"
                          ></b-calendar>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="flex-grow-1 text-right">
                      <b-button
                        :disabled="isNotExistData"
                        size="sm"
                        variant="danger"
                        @click="onDeleteAll"
                      >日報削除</b-button>
                    </div>
                  </div>
                  <!-- 天気 -->
                  <div class="pt-2">
                    <b-form-group label="天気" :label-size="titleLabelSize" label-class="itemTitle">
                      <div class="text-center">
                        <div class="mt-2">
                          <div class="weatherArea d-flex justify-content-between">
                            <!-- 天気１ -->
                            <div>
                              <b-form-radio-group
                                class="watherRadioGroup"
                                buttons
                                stacked
                                button-variant="outline-primary "
                                :options="watherOptions"
                                v-model="workData.weather_1"
                                :disabled="isUnselectedConstruction"
                                @change="handleRegist"
                              ></b-form-radio-group>
                            </div>
                            <!-- 天気２ -->
                            <div>
                              <b-form-radio-group
                                class="watherRadioGroup"
                                buttons
                                stacked
                                button-variant="outline-primary"
                                :options="watherOptions"
                                v-model="workData.weather_2"
                                :disabled="isUnselectedConstruction"
                                @change="handleRegist"
                              ></b-form-radio-group>
                            </div>
                            <!-- 天気３ -->
                            <div>
                              <b-form-radio-group
                                class="watherRadioGroup"
                                buttons
                                stacked
                                button-variant="outline-primary"
                                :options="watherOptions"
                                v-model="workData.weather_3"
                                :disabled="isUnselectedConstruction"
                                @change="handleRegist"
                              ></b-form-radio-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="mt-3 text-right">
                    <b-button
                      size="lg"
                      variant="success"
                      class="px-5 py-3 mr-0"
                      :disabled="isUnselectedConstruction"
                      @click="onGoToWorkRegist"
                    >
                      <b-icon icon="table" class="mr-3"></b-icon>日報編集へ
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>
      </div>
    </main>
    <!-- 工事現場選択モーダル -->
    <constructionSelectModal
      @constructionSelect="addAndSelectConstruction"
      v-model="workData.construction_code"
    ></constructionSelectModal>
    <!-- 担当技術者選択モーダル -->
    <EmployeeWorkersSlectModal
      :propSelectedList="workData.worker"
      :propIsManager="isManagerMode "
      @setSelectWorkers="updateWorkersTypeEmployee"
    ></EmployeeWorkersSlectModal>
    <PartnerCompanysSelectModal
      :propSelectedList="workData.worker"
      @setSelectWorkers="updateWorkersTypePartnerCompany"
    ></PartnerCompanysSelectModal>
    <!-- 前回作業日指定用モーダル -->
    <CopyWorkModal
      ref="dateSelectModal"
      :propDateList="recentBaseDailyReportDateList"
      @parent-event="copyBeforeDateWorkData"
    ></CopyWorkModal>
    <Confirm
      ref="confirmDeleteAllData"
      title="日報データを削除します。"
      :message="confirmMsgDeleteAllData"
      focusCancel="true"
      @parent-ok-event="deleteAll()"
    ></Confirm>
    <Confirm
      ref="confirmDeleteWorker"
      title="下記の作業者を削除します。"
      :message="confirmMsgDeleteWorker"
      focusCancel="true"
      @parent-ok-event="cancelSelectWorker()"
    ></Confirm>
  </div>
</template>

<script>
import moment from "moment";

import config from "../const/config";
import BaseHeader from "../components/BaseHeader";
import constructionSelectModal from "../components/Work/ConstructionSelectModal";
import WorkSelectOverview from "../components/Work/WorkSelectOverview";
import EmployeeWorkersSlectModal from "../components/WorkersSelectModal";
import PartnerCompanysSelectModal from "../components/PartnerCompanysSelectModal";
import CopyWorkModal from "../components/Work/CopyWorkModal";
import Confirm from "../components/Confirm.vue";

export default {
  name: "WorkBase",
  components: {
    BaseHeader,
    constructionSelectModal,
    WorkSelectOverview,
    EmployeeWorkersSlectModal,
    PartnerCompanysSelectModal,
    CopyWorkModal,
    Confirm,
  },
  data() {
    return {
      buttonBaseSize: "sm",
      titleLabelSize: "md",
      // 作業日付
      targetDate: "",
      // 作業日付（フォームで選択されている日付）
      selectedDate: "",
      // 工事コド
      constructionCd: "",
      isNotExistData: true,
      workData: {
        target_date: null,
        construction_code: "",
        weather_1: "0",
        weather_2: "0",
        weather_3: "0",
        job_content_1: "",
        job_content_2: "",
        job_content_3: "",
        job_content_4: "",
        job_content_5: "",
        job_content_6: "",
        job_content_7: "",
        job_content_8: "",
        job_content_9: "",
        job_content_10: "",
        worker: [],
      },
      //直近作業日データリスト
      recentBaseDailyReportDateList: [],
      // 工事オプション
      constructionOptions: [],
      //業務概要オプション
      workOverviewOptions: [],
      // 天気オプション
      watherOptions: [],
      // コピー対象の前回作業日
      selectCopyTargetDate: null,
      //担当技術者FLG
      isManagerMode: 0,
      //
      calendarContext: null,
      calendarActiveYMD: null,
      calendarYm: null,
      constructionMonthDatelist: [],
      //
      confirmMsgDeleteAllData: "",
      confirmMsgDeleteWorker: "",
      updateWorkerList:[],
    };
  },
  computed: {
    isUnselectedConstruction: function () {
      //工事が選択されていない場合変更不可
      return this.constructionCd == "";
    },
  },
  watch: {
    targetDate: function () {
      this.workData.target_date = this.targetDate;
      if (
        !this.isNullOrEmpty(this.targetDate) &&
        !this.isNullOrEmpty(this.constructionCd)
      ) {
        this.getBaseDailyReportWithWorker(this.constructionCd, this.targetDate);
      }
    },
    constructionCd: function () {
      this.workData.construction_code = this.constructionCd;
      if (
        !this.isNullOrEmpty(this.targetDate) &&
        !this.isNullOrEmpty(this.constructionCd)
      ) {
        this.getBaseDailyReportWithWorker(this.constructionCd, this.targetDate);
      }

      //業務概要オプション取得
      this.getWorkOverviewOptions();
      //選択中工事の作業日取得
      this.getConstructionMonthDatelist();
    },
    //カレンダー月の変更
    calendarYm: function () {
      //選択中工事の作業日取得
      this.getConstructionMonthDatelist();
    },
  },
  mounted: function () {
    if (this.$store.state.construction_cd != null) {
      this.constructionCd = this.$store.state.construction_cd;
    }

    if (this.$store.state.work_date != null) {
      this.targetDate = this.$store.state.work_date;
      this.selectedDate = this.$store.state.work_date;
    } else {
      this.targetDate = this.getStrDateHyphen(moment());
      this.selectedDate = this.getStrDateHyphen(moment());
    }

    // 現場オプション取得
    this.getConstructionOptions();
    // 天気オプション取得
    this.getWatherOptions();
  },
  methods: {
    /**
     * 工事日変更時
     */
    onSelectedDateChange: function (ymd) {
      this.$confirm("日付を変更します。よろしいですか？", "確認", {
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      })
        .then(() => {
          this.targetDate = ymd;
        })
        .catch(() => {
          //更新キャンセル
          this.selectedDate = this.targetDate;
        });
    },
    /**
     * 日報データ全削除
     */
    onDeleteAll: function () {
      //
      let msg = "";
      if (this.workData != null && this.workData.worker.length > 0) {
        msg += "作業者の日報時間データも削除されます。よろしいですか。";
      }
      this.confirmMsgDeleteAllData = msg;
      this.$refs.confirmDeleteAllData.openDialog();
    },
    deleteAll: function () {
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.deleteDataMultiKey(
        config.URL.DAILY_REPORT_BASE,
        this.constructionCd,
        this.targetDate
      )
        .then((response) => {
          if (response.result) {
            //成功
            me.showUpdateSuccess("日報データが削除されました。", "日報削除");
          } else {
            //失敗
            me.showErrorAlert(response.detail.error, "日報削除");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          me.getBaseDailyReportWithWorker(me.constructionCd, me.targetDate);
        });
    },
    onGoToWorkRegist: function () {
      var valid = true;
      if (this.isNullOrEmpty(this.targetDate)) {
        valid = false;
        this.$alert("日付を指定してください", "エラー", {
          confirmButtonText: "OK",
          type: "error",
        }).then(() => {
          return;
        });
      }
      if (this.isNullOrEmpty(this.constructionCd)) {
        valid = false;
        this.$alert("工事を指定してください", "エラー", {
          confirmButtonText: "OK",
          type: "error",
        }).then(() => {
          return;
        });
      }
      if (valid) {
        // Vuexに選択されている値を格納
        this.$store.commit("setWorkRegistKey", {
          construction_cd: this.constructionCd,
          work_date: this.targetDate,
        });
        // 就業日報時間編集画面へ移動
        this.registData(true);
        // this.$router.push(config.PAGES.WORK_REGIST);
      }
    },
    /**
     * 工事別日別作業内容データ取得API呼び出し
     */
    getBaseDailyReportWithWorker: function (constructionCd, targetDate) {
      this.getConstructionMonthDatelist();
      //
      this.isNotExistData = true;
      let me = this;
      // クリア
      this.clearWorkData();
      //サーバーから取得
      let params = {
        construction_cd: constructionCd,
        target_date: targetDate,
      };
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getWithParams(config.URL.DAILY_REPORT_BASE, params)
        .then((response) => {
          if (response.result) {
            if (response.container != null) {
              this.workData = response.container;
              this.isNotExistData = false;
            }
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "作業内容データ取得エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "作業内容データ取得エラー");
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * 工事選択セレクトボックス オプション取得
     * ログインユーザが登録したことがある工事を取得し、工事オプションにセットする
     */
    getConstructionOptions: function () {
      let me = this;
      // サーバーから取得
      let process = "/getContstructionsRegistHistory";
      this.constructionOptions = [];
      this.getDataList(config.URL.CONSTRUCTION + process)
        .then((response) => {
          if (response.result) {
            response.container.forEach((element) => {
              me.constructionOptions.push({
                value: element.construction_cd,
                text: element.construction_name,
              });
            });
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "工事履歴取得エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "工事履歴取得エラー");
        });
    },
    /**
     * 業務概要セレクトボックス オプション取得
     */
    getWorkOverviewOptions: function () {
      let me = this;
      let param = { construction_cd: this.constructionCd };
      this.workOverviewOptions = [];
      this.getWithParams(
        config.URL.ITEM_NAME + "/getWorkOverviewNameList",
        param
      )
        .then((response) => {
          if (response.result) {
            // 工事職務概要（文字配列List<String>）をセット
            me.workOverviewOptions = response.container;
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "業務概要候補取得エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "業務概要候補取得エラー");
        });
    },
    /**
     * 天気セレクト オプション取得
     */
    getWatherOptions: function () {
      let me = this;
      this.watherOptions = [{ value: null, text: " - " }];
      this.getDataList(config.URL.ITEM_NAME + "/getWeatherItemList")
        .then((response) => {
          if (response.result) {
            // 工事職務概要（文字配列List<String>）をセット
            let itemList = response.container;
            itemList.forEach((element) => {
              this.watherOptions.push({
                value: element.item_cd,
                text: element.item_name,
              });
            });
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "天気候補取得エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "天気候補取得エラー");
        });
    },
    // 工事現場選択モーダルを表示する
    showConstructionSelectModal: function () {
      this.$bvModal.show("construction-select-modal");
    },
    addAndSelectConstruction: function (construction) {
      //選択肢に表示済みかチェック
      let data = this.constructionOptions.filter(
        (el) => el.value == construction.construction_cd
      );
      if (data == null || data.length <= 0) {
        //追加
        this.constructionOptions.push({
          value: construction.construction_cd,
          text: construction.construction_name,
        });
      }
      this.constructionCd = construction.construction_cd;
    },
    //担当技術者（社員）選択モーダルを表示する
    showEmployeeWorkersSlectModal: function (isManager) {
      if (isManager) {
        this.isManagerMode = config.VALUE.VALID;
      } else {
        this.isManagerMode = config.VALUE.INVALID;
      }
      this.$bvModal.show("employee-workers-select-modal");
    },
    showPartnerCompanyWorkersSlectModal: function () {
      this.$bvModal.show("partner-companeys-worker-select-modal");
    },
    /**
     * 日付選択モーダルを表示する（前回作業日コピー用）
     */
    showSelectDateModalForCopy: function () {
      let me = this;
      //選択されている工事の工事コードから直近の工事データを取得する
      let param = {
        construction_cd: this.constructionCd,
        targetDate: this.targetDate,
      };
      this.recentBaseDailyReportDateList = [];
      this.getDataList(
        config.URL.DAILY_REPORT_BASE +
          config.PROCESS.GET_RECENT_DATE_LIST_BY_CONSTRUCTION,
        param
      )
        .then((response) => {
          if (response.result) {
            // スケジュールセット
            me.recentBaseDailyReportDateList = response.container;
            me.$refs.dateSelectModal.showModal();
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "前回作業日取得エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "前回作業日取得エラー");
        });
    },
    /**
     * 前回作業日のデータをコピーする（業務概要と担当技術者）
     */
    copyBeforeDateWorkData: function (targetValue) {
      if (
        this.isNullOrEmpty(this.targetDate) ||
        this.isNullOrEmpty(this.constructionCd) ||
        this.isNullOrEmpty(targetValue)
      ) {
        return;
      }

      //選択された日付と工事コードをキーに作業のコピー処理を行う
      const param = {
        construction_cd: this.constructionCd,
        target_date: this.targetDate,
        copy_date: targetValue,
      };
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postDataWithKey(
        config.URL.DAILY_REPORT_BASE,
        config.PROCESS.COPY,
        param
      )
        .then((response) => {
          if (response.result) {
            this.getBaseDailyReportWithWorker(
              this.constructionCd,
              this.targetDate
            );
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "データコピーエラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "データコピーエラー");
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    // 担当技術者の削除（×ボタン）押下時
    handleCancelSelectWorker: function (cancelWorker) {
      this.updateWorkerList = null;
      this.confirmMsgDeleteWorker = cancelWorker.worker_name;
      
      let me = this;
      // " × "を押下された担当者を除くリストを作成する
      let newList = me.workData.worker.filter(function (item) {
        return !(
          item.worker_cd == cancelWorker.worker_cd &&
          item.worker_type == cancelWorker.worker_type
        );
      });
      this.updateWorkerList = newList;
      //確認ダイアログ
      this.$refs.confirmDeleteWorker.openDialog();
    },
    //担当技術者の更新
    cancelSelectWorker:function(){
      this.workData.worker = this.updateWorkerList;
      //工事別日別作業内容データ 新規登録/更新呼び出し
      this.registData();
    },
    /***
     * 1.1 社員の担当者を更新
     */
    updateWorkersTypeEmployee: function (afterWorkerList) {
      this.updateWorker(
        afterWorkerList,
        config.VALUE.WORKER_TYPE_EMPLOYEE,
        config.VALUE.WORKER_TYPE_PARTNER_COMPANY
      );
    },
    /***
     * 1.1 協力会社の担当者を更新
     */
    updateWorkersTypePartnerCompany: function (afterWorkerList) {
      this.updateWorker(
        afterWorkerList,
        config.VALUE.WORKER_TYPE_PARTNER_COMPANY,
        config.VALUE.WORKER_TYPE_EMPLOYEE
      );
    },
    /**
     * 1.2 作業担当者更新
     */
    updateWorker: function (
      afterWorkerList,
      ME_WORKER_TYPE,
      NOT_ME_WORKER_TYPE
    ) {
      let me = this;

      let deleteWorkersList = [];
      let deleteWorkersName = "";
      let addWorkersList = [];
      let addWorkersName = "";
      let editWorkersList = [];
      let editWorkerName = "";

      //削除対象の取得
      me.workData.worker.forEach(function (beforeWorker) {
        if (beforeWorker.worker_type == NOT_ME_WORKER_TYPE) {
          //スキップ
          return;
        }
        //変更後の担当者リストに含まれない作業者を、削除対象とする
        let result = null;
        if (afterWorkerList != null && afterWorkerList.length > 0) {
          result = afterWorkerList.filter(
            (el) =>
              el.worker_cd == beforeWorker.worker_cd &&
              el.worker_type == ME_WORKER_TYPE
          );
        }

        if (result == null || result.length <= 0) {
          //削除対象
          deleteWorkersList.push({ ...beforeWorker });
          //表示メッセージ用
          if (deleteWorkersName != "") {
            deleteWorkersName += "、";
          } else {
            deleteWorkersName += "・削除対象\n";
          }
          deleteWorkersName += beforeWorker.worker_name;
        }
      });
      //追加対象の取得
      if (afterWorkerList != null && afterWorkerList.length > 0) {
        afterWorkerList.forEach(function (afterWorker) {
          //変更後の担当者リストにのみ含まれる作業者を、追加対象とする
          var result = me.workData.worker.filter(
            (el) =>
              el.worker_cd == afterWorker.worker_cd &&
              el.worker_type == ME_WORKER_TYPE
          );
          if (result == null || result.length <= 0) {
            //追加対象
            addWorkersList.push({ ...afterWorker });
            //表示メッセージ用
            if (addWorkersName != "") {
              addWorkersName += "、 ";
            } else {
              addWorkersName += "・追加対象\n";
            }
            addWorkersName += afterWorker.worker_name;
          } else {
            //担当技術者FLGの変更対象
            me.workData.worker.forEach(function (el) {
              if (
                el.worker_cd == afterWorker.worker_cd &&
                el.worker_type == ME_WORKER_TYPE &&
                el.is_manager != afterWorker.is_manager
              ) {
                //
                editWorkersList.push({ ...afterWorker });
                //表示メッセージ用
                if (editWorkerName != "") {
                  editWorkerName += "、 ";
                } else {
                  editWorkerName += "・担当技術者の変更対象\n";
                }
                editWorkerName += afterWorker.worker_name;
              }
            });
          }
        });
      }

      //--更新内容確認のメッセージを表示し、OKであれば更新
      let hasDeleteWorker =
        deleteWorkersList != null && deleteWorkersList.length > 0;
      let hasAddWorker = addWorkersList != null && addWorkersList.length > 0;
      let hasEditWorker = editWorkersList != null && editWorkersList.length > 0;

      if (hasDeleteWorker || hasAddWorker || hasEditWorker) {
        //更新する担当者情報表示準備
        let msg = "";
        if (hasDeleteWorker) {
          msg += deleteWorkersName;
        }
        if (hasAddWorker) {
          if (hasDeleteWorker) {
            msg += "\n\n";
          }
          msg += addWorkersName;
        }
        if (hasEditWorker) {
          if (hasDeleteWorker || hasAddWorker) {
            msg += "\n\n";
          }
          msg += editWorkerName;
        }

        //確認メッセージ表示
        this.$confirm(msg, "下記の作業者を更新します。", {
          confirmButtonText: "更新",
          cancelButtonText: "キャンセル",
        })
          .then(() => {
            //--作業者データの入れ替え実行
            //削除
            if (hasDeleteWorker) {
              deleteWorkersList.forEach(function (deleteWorker) {
                me.workData.worker.some(function (v, i) {
                  if (
                    v.worker_cd == deleteWorker.worker_cd &&
                    v.worker_type == deleteWorker.worker_type
                  )
                    me.workData.worker.splice(i, 1);
                });
              });
            }
            //追加
            if (hasAddWorker) {
              addWorkersList.forEach((element) => {
                me.workData.worker.push(element);
              });
            }
            if (hasEditWorker) {
              //
              me.workData.worker.forEach(function (element) {
                //
                var editWorker = editWorkersList.filter(
                  (el) =>
                    el.worker_cd == element.worker_cd &&
                    el.worker_type == ME_WORKER_TYPE
                );
                if (editWorker != null && editWorker.length > 0) {
                  element.is_manager = editWorker[0].is_manager;
                }
              });
            }
            //更新
            me.registData();
          })
          .catch(() => {
            //更新キャンセル
          });
      } else {
        this.$alert("", "変更はありませんでした。", {
          confirmButtonText: "OK",
        });
      }
    },
    // カレンダレイアウト変更
    dateClass: function (ymd, date) {
      let className = "";
      //曜日の色設定
      const weekday = date.getDay();
      if (weekday == 0) {
        className += "calendar-text-danger";
      } else if (weekday == 6) {
        className += "calendar-text-info";
      }

      //工事の作業日設定
      const calDate = this.getDate(date);
      if (this.constructionMonthDatelist != null) {
        let dateList = this.constructionMonthDatelist.filter(
          (e) => e.targetDate == calDate
        );
        if (dateList != null && dateList.length > 0) {
          //対象日に選択中工事のデータが存在する場合
          className += " table-orenge  ";
        }
      }

      return className;
    },
    handleRegist: function (isGetData = true) {
      this.registData(false, isGetData);
    },
    /**
     * 登録処理
     */
    registData: function (goToWorkRegist = false, isGetData = true) {
      if (
        this.isNullOrEmpty(this.targetDate) ||
        this.isNullOrEmpty(this.constructionCd)
      ) {
        return;
      }
      const query = {
        construction_cd: this.constructionCd,
        target_date: this.targetDate,
      };
      let me = this;
      if (isGetData) {
        this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      }
      this.postDataWithQuery(config.URL.DAILY_REPORT_BASE, query, this.workData)
        .then((response) => {
          if (response.result) {
            if (goToWorkRegist) {
              //基本時間編集画面へ
              me.$router.push(config.PAGES.WORK_REGIST);
            }
          } else {
            let msg = config.MESSAGE.SERVER_ERROR;
            if (response.detail.error) {
              msg = response.detail.error;
            }
            me.showErrorMsg(msg, "登録・更新処理エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e, "登録・更新処理エラー");
        })
        .finally(() => {
          if (isGetData) {
            this.getBaseDailyReportWithWorker(me.constructionCd, me.targetDate);
          } else {
            this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          }
        });
    },

    /**
     * 工事情報クリア
     */
    clearWorkData: function () {
      this.workData = {
        target_date: null,
        construction_code: "",
        weather_1: "0",
        weather_2: "0",
        weather_3: "0",
        job_content_1: "",
        job_content_2: "",
        job_content_3: "",
        job_content_4: "",
        job_content_5: "",
        job_content_6: "",
        job_content_7: "",
        job_content_8: "",
        job_content_9: "",
        job_content_10: "",
        worker: [],
      };
    },
    /**
     * カレンダーコンテキスト
     */
    onCalendarContext: function (ctx) {
      this.calendarContext = ctx;
      if (this.calendarContext == null) {
        this.calendarActiveYMD = null;
        this.calendarYm = null;
      } else {
        this.calendarActiveYMD = this.calendarContext.activeYMD;
        //現在表示中のカレンダー年月（年月の変化の検知用）
        this.calendarYm = this.getStrYm(this.calendarContext.activeYMD);
      }
    },
    /**
     * 選択中工事の作業日リストの取得
     */
    getConstructionMonthDatelist: function () {
      let me = this;
      this.constructionMonthDatelist = [];

      if (
        this.isNullOrEmpty(this.constructionCd) ||
        this.isNullOrEmpty(this.calendarActiveYMD)
      ) {
        //工事か対象月が不明の場合、取得不可
        return;
      }

      //--リクエスト生成
      //月初の日付を取得
      let startYmd = this.getTargetMonthStartDate(this.calendarActiveYMD);
      //月末の日付を取得
      let endYmd = this.getTargetMonthEndDate(this.calendarActiveYMD);
      let param = {
        construction_cd: this.constructionCd,
        startTargetDate: startYmd,
        endTargetDate: endYmd,
      };
      //--データ取得
      this.getDataList(
        config.URL.DAILY_REPORT_BASE + "/construction_month_datelist",
        param
      )
        .then((response) => {
          if (response.result) {
            me.constructionMonthDatelist = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "工事作業日取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
  },
};
</script>

<style>
#work-base .main_area {
  min-width: 1400px;
  width: 1400px;
}

/* 選択技術担当者のタグレイアウト */
#work-base .selectWorkerName {
  font-size: 0.85rem;
  padding: 3px 15px;
  margin: 2px 5px;
  border-radius: 5px;
  word-break: keep-all;
  min-width: 160px;
  background: rgb(235, 235, 235);
}
#work-base .selectWorkerNameCompany {
  background: rgb(188, 234, 255);
}

/* フォームラベルレイアウト */
#work-base .col-form-label {
  color: rgb(54, 82, 119);
}
#work-base .itemTitle {
  border-bottom: 5px solid rgb(54, 82, 119);
  /* border-left: 6px solid rgb(105, 126, 158); */
  margin-bottom: 5px;
  padding-left: 10px;
  padding-bottom: 0px;
}

#work-base .form-group {
  margin-bottom: 0.25rem;
}

#work-base .weatherArea {
  height: 365px;
  overflow: auto;
  word-break: keep-all;
}

#work-base .selectWorkerArea {
  min-height: 340px;
  height: 340px;
  overflow-y: auto;
}

#work-base .selectManagerArea {
  min-height: 55px;
  height: 55px;
  overflow-y: auto;
}

.el-message-box__message {
  white-space: pre-line;
}

#work-base .watherRadioGroup {
  min-width: 100px;
}

#work-base .table-orenge {
  background: rgb(252, 234, 134);
}
.b-calendar .b-calendar-grid-body .col[data-date] {
  border: 1px solid white;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 30px;
  height: 30px;
}
</style>
