<template>
  <div id="paid" class="main" style>
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="有給確認"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="p-4">
            <div>
              <!-- 年月変更 -->
              <div>
                <b-row>
                  <b-col cols="4" >
                    <div class="changeDateArea">
                      <b-input-group size="md">
                        <!-- 年月 -->
                        <b-input
                          readonly
                          v-model="showTargetYearMonth"
                          class="text-center showOnry"
                          style="max-width: 200px"
                        ></b-input>
                        <!-- 年月変更ボタン -->
                        <b-input-group-append>
                          <b-button @click="lastMonth" variant>＜＜</b-button>
                          <b-button @click="nextMonth" variant>＞＞</b-button>
                          <b-button class="ml-1" @click="nowMonth" variant>今月</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div>
                      <b-form-radio-group
                        size="md"
                        v-model="selectSalaryType"
                        buttons
                        button-variant="outline-info"
                      >
                        <b-form-radio :value="null">全て</b-form-radio>
                        <b-form-radio :value="SALARY_TYPE_MONTH">月給</b-form-radio>
                        <b-form-radio :value="SALARY_TYPE_DAY" v-if="isUseDailySalary">日給</b-form-radio>
                        <b-form-radio :value="SALARY_TYPE_HOUR">時給</b-form-radio>
                      </b-form-radio-group>
                    </div>
                  </b-col>
                  <b-col cols="5" class="text-right">
                    <h5 class="mt-2" v-if="zanPaidYm">{{zanPaidYm}}締時点の有給残を表示しています</h5>
                  </b-col>
                </b-row>
              </div>
              <!-- 一覧 -->
              <div class="mt-2">
                <b-table-simple bordered class="paidDateListTable monthCalendar boldBorder">
                  <b-tbody v-for="(item, index) in showPaidList" :key="index">
                    <!-- ヘッダー（日数）20レコード毎に表示 -->
                    <b-tr v-if="index % 20 == 0" class="topBoldBorder">
                      <b-th rowspan="2" class="workerName bottomBoldBorder">氏 名</b-th>
                      <b-th class="colSumPaid paidDayBackcolor">残日</b-th>
                      <b-th class="colSumPaid paidHourBackcolor">残時</b-th>
                      <b-th class="colSumPaid paidDayBackcolor">取日</b-th>
                      <b-th class="colSumPaid paidHourBackcolor rightBoldBorder">取時</b-th>
                      <b-th
                        v-for="(bItem, bIndex) in baseDateList"
                        :key="bIndex"
                        :class="getWeekColorClass(true, bItem.weekDay)"
                      >{{ bItem.day }}</b-th>
                    </b-tr>
                    <!-- ヘッダー（曜日）20レコード毎に表示-->
                    <b-tr v-if="index % 20 == 0" class="bottomBoldBorder">
                      <b-th class="paidDayBackcolor">d</b-th>
                      <b-th class="paidHourBackcolor">h</b-th>
                      <b-th class="paidDayBackcolor">d</b-th>
                      <b-th class="paidHourBackcolor rightBoldBorder">h</b-th>
                      <b-th
                        class="colDay"
                        v-for="(bItem, bIndex) in baseDateList"
                        :key="bIndex"
                        :class="getWeekColorClass(true, bItem.weekDay)"
                      >{{ strWeekDay[bItem.weekDay] }}</b-th>
                    </b-tr>
                    <!-- 社員の対象月の有給情報 5レコード毎に太ボーダーを表示-->
                    <b-tr :class="(index + 1) % 5 == 0 ? 'bottomBoldBorder' : ''">
                      <b-td class="text-left workerName">{{ item.employee_name }}</b-td>

                      <!-- 残日 -->
                      <b-td class="paidDayBackcolor">
                        {{
                        isThisYear ? item.zanPaidDays : ""
                        }}
                      </b-td>
                      <!-- 残時 -->
                      <b-td class="paidHourBackcolor">
                        {{
                        isThisYear ? item.zanPaidHour : ""
                        }}
                      </b-td>
                      <!-- 取日 -->
                      <b-td
                        :class="
                          getSumPaid(item.dateList) > 0
                            ? 'paidDayBackcolor '
                            : ''
                        "
                      >{{ getSumPaid(item.dateList) | emptyFilters }}</b-td>
                      <!-- 取時 -->
                      <b-td
                        :class="
                          getSumPaidHour(item.dateList) > 0
                            ? 'paidHourBackcolor rightBoldBorder'
                            : 'rightBoldBorder'
                        "
                      >
                        {{
                        getSumPaidHour(item.dateList) | emptyFilters
                        }}
                      </b-td>
                      <b-td
                        v-for="(citem, cIndex) in item.dateList"
                        :key="cIndex"
                        :class="getWeekColorClass(false, citem.weekDay)"
                        :colspan="citem.paidDayCount"
                      >
                        <div
                          v-if="citem.isPaid && citem.paid_type == 1"
                          class="paidDayBackcolor h-100 w-100 d-flex align-items-center justify-content-center"
                        >{{ citem.paidDayCount }}d</div>
                        <div
                          v-if="citem.isPaid && citem.paid_type == 2"
                          class="paidHourBackcolor h-100 w-100 d-flex align-items-center justify-content-center"
                        >{{ citem.paid_hour }}h</div>
                        <div v-else></div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </main>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader";
import moment from "moment";
import config from "../const/config";

export default {
  name: "Paid",
  components: {
    BaseHeader,
  },
  data() {
    return {
      periodStartDate: null,
      //
      thisYearStartDate: null,
      thisYearEndDate: null,
      //対象月
      targetDate: null,
      //表示用年月
      showTargetYearMonth: "",
      //日付情報データリスト（ひと月分の空データ）
      baseDateList: [],
      //取得有給情報社員別リスト
      paidDataEmployeeList: [],
      // 表示データリスト
      showPaidList: [],
      //給与形態
      selectSalaryType: null,
      // -- 定数
      strWeekDay: config.VALUE.strWeekDay,
      zanPaidYm: null,
      // 給与形態種別
      SALARY_TYPE_MONTH: config.VALUE.SALARY_TYPE_MONTH,
      SALARY_TYPE_DAY: config.VALUE.SALARY_TYPE_DAY,
      SALARY_TYPE_HOUR: config.VALUE.SALARY_TYPE_HOUR,
      //日給シートの利用有無
      isUseDailySalary: false,
    };
  },
  filters: {
    emptyFilters: function (value) {
      if (value == 0) {
        return "";
      }
      return value;
    },
  },
  computed: {
    isThisYear: function () {
      if (
        this.targetDate == null ||
        this.thisYearStartDate == null ||
        this.thisYearEndDate == null
      ) {
        return false;
      }

      if (
        this.targetDate >= this.thisYearStartDate &&
        this.targetDate <= this.thisYearEndDate
      ) {
        //今年度
        return true;
      } else {
        //今年度以外
        return false;
      }
    },
  },
  watch: {
    // 対象年月変更時
    targetDate: function () {
      if (this.targetDate != null) {
        // 表示用年月セット
        this.showTargetYearMonth = this.getStrYmKanji(this.targetDate);
        this.createList();
      } else {
        // 初期化
        this.showTargetYearMonth = "";
        this.baseDateList = [];
        this.showPaidList = [];
      }
    },
    selectSalaryType:function(){
      this.createList();
    },
  },
  mounted: function () {
    this.setWorkerTypeOption();
    this.createThisYearStartAndEndDate();
    this.targetDate = moment().format();
    this.setZanPaidYm();
  },
  methods: {
    //日給シートの利用有無を取得
    setWorkerTypeOption: function () {
      let me = this;
      //日給シートの利用有無の取得
      me.isUseDailySalary = false;
      this.getData(config.URL.DAILY_REPORT + "/is_use_dayly_salary")
        .then((response) => {
          if (response.result) {
            me.isUseDailySalary = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日給シートの利用有無取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    //年度の開始年を設定
    createThisYearStartAndEndDate: function () {
      //現在日付から、年度の開始年を取得
      let today = moment();
      let todayMonth = today.month() + 1;
      let thisStartYear = today.year();
      if (todayMonth < config.VALUE.YEAR_START_MONTH) {
        //昨年が年度開始年
        let lastYear = today.subtract(1, "years");
        thisStartYear = lastYear.year();
      }
      //今年度の開始日
      this.thisYearStartDate = moment([thisStartYear, 4, 1]).format();
      //翌年度の開始年
      this.thisYearEndDate = moment([thisStartYear + 1, 3, 1]).endOf("month").format();
    },
    nowMonth: function () {
      this.targetDate = moment().format();
    },
    // 先月に変更
    lastMonth: function () {
      if (this.targetDate != null) {
        this.targetDate = moment(this.targetDate).subtract(1, "M").format();
      }
    },
    // 翌月に変更
    nextMonth: function () {
      if (this.targetDate != null) {
        this.targetDate = moment(this.targetDate).add(1, "M").format();
      }
    },
    createList: function () {
      // 初期化
      this.showPaidList = [];
      this.baseDateList = [];
      let tmpBaseDataList = [];
      //月初の日付を取得
      let startYmd = this.getTargetMonthStartDate(this.targetDate);
      //月末の日付を取得
      let endYmd = this.getTargetMonthEndDate(this.targetDate);
      //月末の日(d)を取得
      let endDate = this.getDay(endYmd);
      for (let i = 1; i <= endDate; i++) {
        /// 1ヶ月分の空データ生成
        let dateValue = moment(startYmd).add(i - 1, "d");
        tmpBaseDataList.push(this.initData(i, dateValue));
      }
      this.baseDateList = tmpBaseDataList;
      // 有給情報をセット
      this.getPaidData();
    },
    //日要素データ生成
    initData: function (dayValue, dateValue) {
      let setWeekDay = moment(dateValue).isoWeekday();
      return {
        date: this.getStrDateHyphen(dateValue),
        day: dayValue,
        weekDay: setWeekDay,
        isPaid: false,
        paid_type: null,
        paidDayCount: null,
        paid_hour: null,
      };
    },
    //2.有給登録情報を取得
    getPaidData: function () {
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      //有給登録情報を取得
      let me = this;
      //月初の日付を取得
      let startYmd = this.getTargetMonthStartDate(this.targetDate);
      //月末の日付を取得
      let endYmd = this.getTargetMonthEndDate(this.targetDate);
      let param = {
        startTargetDate: startYmd,
        endTargetDate: endYmd,
        salary_type:this.selectSalaryType
      };

      this.getDataList(config.URL.PAID + "/getAllEmployeePaid", param)
        .then((response) => {
          if (response.result) {
            if (response.container != null) {
              me.paidDataEmployeeList = response.container;
            }
            me.addPaidData();
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //2.有給データ付与
    addPaidData: function () {
      //有給登録情報をもとに表示データを組立
      let me = this;
      let tmpShowPaidList = [];
      this.showPaidList = [];
      me.paidDataEmployeeList.forEach(function (element) {
        tmpShowPaidList.push(
          me.createShowData(
            element.employee_cd,
            element.employee_name,
            element.zanPaidDays,
            element.zanPaidHour,
            element.paidList
          )
        );
      });
      this.showPaidList = tmpShowPaidList;
    },
    // 表示データを組立
    createShowData: function (
      employee_cd,
      employee_name,
      zanPaidDays,
      zanPaidHour,
      paidList
    ) {
      // 日要素リスト（一時保持用）
      let setDateList = [];
      // 連続する1日有休情報保持用
      let beforePaidDayflg = false;

      // 空の基本リスト要素をコピーし、新規リストに加える
      this.baseDateList.forEach(function (element) {
        // 空の基本リスト要素をコピー
        let addDate = { ...element };

        //有給情報を絞込
        let data = paidList.filter((el) => el.paid_date == element.date);

        if (data && data.length > 0) {
          //有給データあり
          addDate.isPaid = true;
          //有給種別セット
          addDate.paid_type = data[0].paid_type;

          if (addDate.paid_type == config.VALUE.PAIDTYPE_DAY) {
            //1日有給
            if (beforePaidDayflg) {
              // 連日の場合連結表示をする（連続する日数を保持しcolspanに利用）
              setDateList[setDateList.length - 1].paidDayCount += 1;
            } else {
              //新規
              addDate.paidDayCount = 1;
              setDateList.push(addDate);

              beforePaidDayflg = true;
            }
          } else {
            //時間有給
            addDate.paid_hour = data[0].paid_hour;
            setDateList.push(addDate);
            beforePaidDayflg = false;
          }
        } else {
          //有給無し(空データをセット）
          setDateList.push({ ...element });
          //
          beforePaidDayflg = false;
        }
      });

      let reData = {
        employee_cd: employee_cd,
        employee_name: employee_name,
        zanPaidDays: zanPaidDays,
        zanPaidHour: zanPaidHour,
        dateList: setDateList,
      };

      return reData;
    },
    //曜日クラスを取得
    getWeekColorClass: function (isTh, weekDay) {
      let setClass = " ";
      if (isTh) {
        if (weekDay == 7) {
          setClass += " weekEndBackcolor sundayColor ";
        } else if (weekDay == 6) {
          setClass += " weekEndBackcolor saturdayColor ";
        }
      } else {
        setClass += "p-0 ";
        if (weekDay == 7) {
          setClass += " weekEndBackcolor ";
        } else if (weekDay == 6) {
          setClass += " weekEndBackcolor ";
        }
      }
      return setClass;
    },
    // 1日有給の取得日数合計を取得
    getSumPaid: function (dateList) {
      let count = 0;
      dateList.forEach(function (item) {
        if (item.isPaid) {
          if (item.paid_type == config.VALUE.PAIDTYPE_DAY) {
            count += item.paidDayCount;
          }
        }
      });
      return count;
    },
    // 時間有給の取得時間合計を取得
    getSumPaidHour: function (dateList) {
      let count = 0;
      dateList.forEach(function (item) {
        if (item.isPaid) {
          if (item.paid_type == config.VALUE.PAIDTYPE_HOUR) {
            count += item.paid_hour;
          }
        }
      });
      return count;
    },
    //作業員締め情報の勤怠年月を参照し、その前月をセット
    setZanPaidYm: function () {
      let me = this;
      this.getDataList(config.URL.SETTING + config.PROCESS.GET_OPE_CONTROL)
        .then((response) => {
          if (response.result) {
            let value = response.container.fieldworker_year_month;
            if (value == null || value == "") {
              me.zanPaidYm = null;
            } else {
              //前月に変換しセット
              me.zanPaidYm = moment(value, "YYYYMM")
                .subtract(1, "months")
                .format("YYYY年MM月");
            }
          } else {
            me.showErrorMsg(response.detail.error, "システム運用情報取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
  },
};
</script>

<style>
#paid .main_area {
  min-width: 1480px;
  width: 1480px;
}
/* 月度変更 --------------------------------------*/
#paid .changeDateArea {
  width: 500px;
}
/** 有給日付テーブル-------------------------------*/

/* 有給合計列（残日、残時、取日、取時） */
#paid .paidDateListTable .colSumPaid {
  width: 45px;
}
</style>
