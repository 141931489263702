<template>
  <b-modal
    v-model="isModalShow"
    id="employee-workers-select-modal"
    size="lg"
    :centered="true"
    hide-footer
    header-class="modal_header"
  >
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">社員選択</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="px-5">
        <!-- 絞り込み検索 -->
        <div class>
          <div class>
            <b-input-group prepend="キーワード">
              <b-input v-model="partialMatch" v-on:keyup.enter="getFilterEmployeeList"></b-input>
              <b-input-group-append>
                <b-button size="sm" class="px-3" @click="getFilterEmployeeList">検索</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="mt-2">
            <!-- 給与形態種別： -->
            <div>
              <b-form-radio-group
                size="sm"
                v-model="selectSalaryType"
                @change="getFilterEmployeeList"
                buttons
                button-variant="outline-info"
              >
                <b-form-radio :value="null">全て</b-form-radio>
                <b-form-radio :value="SALARY_TYPE_MONTH">月給</b-form-radio>
                <b-form-radio :value="SALARY_TYPE_DAY" v-if="isUseDailySalary">日給</b-form-radio>
                <b-form-radio :value="SALARY_TYPE_HOUR">時給</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
        <!-- 社員一覧 -->
        <div class="mt-3">
          <b-table
            class="workerSelectTable"
            :tbody-tr-class="tbodyRowClass"
            sticky-header
            small
            bordered
            :fields="fields"
            :items="workerList"
            ref="selectableEmployeeTable"
            @row-clicked="rowClicked"
          >
            <template #cell(selected)="{ item, field: { key } }">
              <template v-if="item[key]">
                <div class="text-center" aria-hidden="true">&check;</div>
              </template>
              <template v-else>
                <div aria-hidden="true">&nbsp;</div>
              </template>
            </template>
          </b-table>
        </div>
        <!-- ボタン -->
        <div class="text-center">
          <b-row>
            <!-- テーブル選択状態変更 -->
            <b-col cols="6" class="text-left">
              <b-button class="px-3" @click="selectAllRows">全て</b-button>
              <b-button class="px-3 ml-1" @click="clearSelected">クリア</b-button>
            </b-col>
            <!-- 確定 -->
            <b-col cols="6" class="text-right">
              <b-button class="px-5" variant="success" @click="handleOk">OK</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
import config from "../const/config.js";

export default {
  name: "WorkersSelectModal",
  props: ["propSelectedList", "propIsManager"],
  data: function () {
    return {
      SALARY_TYPE_MONTH: config.VALUE.SALARY_TYPE_MONTH,
      SALARY_TYPE_DAY: config.VALUE.SALARY_TYPE_DAY,
      SALARY_TYPE_HOUR: config.VALUE.SALARY_TYPE_HOUR,
      WORKER_TYPE: config.VALUE.WORKER_TYPE_EMPLOYEE,
      // モーダル表示状態
      isModalShow: false,
      // 給与形態種別
      selectSalaryType: null,
      salaryTypeOptions: [
        { value: null, text: "全て" },
        { value: 1, text: "月給" },
        { value: 2, text: "日給" },
        { value: 3, text: "時給" },
      ],
      isUseDailySalary: false,
      // 作業員種別
      selectWorkerType: 0,
      workerTypeOptions: [
        { value: "0", text: "社員" },
        { value: "1", text: "協力" },
      ],
      // テーブル
      fields: [
        {
          key: "selected",
          label: "選択",
          sortable: false,
          thClass: "thSelected text-center themeColoreHeader",
        },
        {
          key: "worker_cd",
          label: "社員コード",
          sortable: true,
          thClass: "text-center thWorkerCode themeColoreHeader",
          tdClass: "text-center",
        },
        {
          key: "worker_name",
          label: "社員名",
          sortable: false,
          thClass: "themeColoreHeader",
        },
        {
          key: "worker_name_kana",
          label: "カナ",
          sortable: true,
          thClass: "themeColoreHeader",
        },
      ],
      //部分一致検索
      partialMatch: null,
      //サーバiから取得した全社員の一覧を保持
      workerList: [],
      //選択状態の作業者
      selectedWorkerList: [],
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        //給与形態オプション設定
        this.setWorkerTypeOption();
        //作業者取得
        this.getEmployeeList();
      } else {
        // モーダル閉じるとき
        this.initData();
      }
    },
  },
  computed: {},
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.$root.$emit("bv::hide::modal", "employee-workers-select-modal", "");
    },
    // -- データ処理
    setWorkerTypeOption: function () {
      let me = this;
      //日給シートの利用有無の取得
      me.isUseDailySalary = false;
      this.getData(config.URL.DAILY_REPORT + "/is_use_dayly_salary")
        .then((response) => {
          if (response.result) {
            me.isUseDailySalary = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日給シートの利用有無取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // 初期化
    initData: function () {
      this.selectSalaryType = null;
      this.partialMatch = null;
      this.workerList = [];
      this.selectedWorkerList = [];
    },
    //1.1 作業者取得（画面展開時）
    getEmployeeList: function () {
      let me = this;
      this.workerList = [];
      // パラメータ作成
      let employeeSearchConditions = {
        employee_status: config.VALUE.EMPLOYEE_STATUS_WORK
      };
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(
        config.URL.EMPLOYEE + config.PROCESS.SEARCH,
        employeeSearchConditions
      )
        .then((response) => {
          if (response.result) {
            //取得した社員をセット
            me.setWorkerList(response.container);
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          //社員選択描画更新
          this.setPropList();
        });
    },
    //1.2 既存選択状態の作業員情報を選択状態作業員リストにセットする
    setPropList: function () {
      this.setSelected(this.propSelectedList);
      //社員選択描画
      this.setSelectWorkerRow();
    },
    //2.1 絞り込み検索
    getFilterEmployeeList: function () {
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      let me = this;
      this.workerList = [];

      // パラメータ作成
      let employeeSearchConditions = {
        partialMatch: this.partialMatch,
        salary_type: this.selectSalaryType,
        employee_status: config.VALUE.EMPLOYEE_STATUS_WORK
      };
      // リクエスト
      this.getDataList(
        config.URL.EMPLOYEE + config.PROCESS.SEARCH,
        employeeSearchConditions
      )
        .then((response) => {
          if (response.result) {
            //絞り込み結果を社員リストにセットする
            me.setWorkerList(response.container);
            //選択状態だった社員が存在しない場合、保持している選択状態社員リストからコピーする
            // me.addSelectedEmployeeData();
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          //社員選択描画
          this.setSelectWorkerRow();
        });
    },
    //2.2 選択状態だった作業者が、絞込の結果一覧から存在しなくなった場合、選択状態作業者リストからコピーする
    addSelectedEmployeeData: function () {
      let me = this;
      if (
        this.selectedWorkerList != null &&
        this.selectedWorkerList.length > 0
      ) {
        //現在の表示順描画のため、データ順反転
        let list = this.selectedWorkerList.slice().reverse();

        list.forEach(function (element) {
          let data = me.workerList.filter(
            (el) => el.worker_cd == element.worker_cd && el.worker_type == me.WORKER_TYPE
          );
          if (data == null || data.length <= 0) {
            //選択状態だった作業者が、作業者リストに存在しない場合、先頭に追加する
            if (
              me.selectSalaryType == null ||
              me.selectSalaryType == element.salary_type
            )
              me.workerList.unshift(
                me.getWorkerData(
                  element.worker_cd,
                  element.worker_name,
                  element.worker_name_kana,
                  element.salary_type,
                  element.employee_type,
                  element.work_style
                )
              );
          }
        });
      }
    },
    //取得した社員データを作業員形式で変換してセットする
    setWorkerList: function (dataList) {
      if (dataList == null || dataList <= 0) {
        return;
      }
      let me = this;
      dataList.forEach(function (element) {
        me.workerList.push(
          me.getWorkerData(
            element.employee_cd,
            element.employee_name,
            element.employee_name_kana,
            element.salary_type,
            element.employee_type,
            element.work_style
          )
        );
      });
    },
    //作業者用データに整形
    getWorkerData: function (
      worker_cd,
      worker_name,
      worker_name_kana,
      salary_type,
      employee_type,
      work_style
    ) {
      return {
        selected: false,
        worker_cd: worker_cd,
        worker_name: worker_name,
        worker_name_kana: worker_name_kana,
        worker_type: this.WORKER_TYPE,
        salary_type: salary_type,
        employee_type: employee_type,
        work_style: work_style,
        worker_count: null,
        is_manager: this.propIsManager,
      };
    },
    //選択済み保持リストに対象作業者を追加
    addSelectWorker: function (worker) {
      let me = this;
      let data = this.selectedWorkerList.filter(
        (el) => el.worker_cd == worker.worker_cd && el.worker_type == me.WORKER_TYPE
      );
      if (data && data.length > 0) {
        //選択済みの場合は、作業担当者FLGのみ更新を行う
        this.selectedWorkerList.forEach(function (el) {
          if(el.worker_cd == worker.worker_cd && el.worker_type == me.WORKER_TYPE ){
            el.is_manager = me.propIsManager;
          }
        });
      } else {
        //追加
        this.selectedWorkerList.push(worker);
      }
    },
    //選択済み保持リストから対象作業者を削除
    removeSelectWorker: function (worker) {
      let me = this;
      let beforeData = this.propSelectedList.filter(
        (el) => el.worker_cd == worker.worker_cd && el.worker_type == me.WORKER_TYPE
      );
      if ( beforeData && beforeData.length > 0 && beforeData[0].is_manager != this.propIsManager ) {
        //担当技術者選択で従事者にて選択状態だった場合か、従事者選択で担当者技術者にて選択状態だった場合
        //担当技術者FLGを戻す
        me.selectedWorkerList.forEach(function (el) {
          if(el.worker_cd == worker.worker_cd && el.worker_type == me.WORKER_TYPE ){
            el.is_manager = beforeData[0].is_manager;;
          }
        });
      } else {
        //対象の作業者以外に絞込
        let data = this.selectedWorkerList.filter(
          (el) => el.worker_cd != worker.worker_cd
        );
        //
        if (data && data.length > 0) {
          //削除する技術者を除いたデータを再セット
          this.selectedWorkerList = data;
        } else {
          this.selectedWorkerList = [];
        }
      }
    },
    //選択済み保持リストにセットする
    setSelected: function (dataList) {
      let me = this;
      me.selectedWorkerList = [];
      if (dataList != null && dataList.length > 0) {
        dataList.forEach(function (element) {
          if (element.worker_type == me.WORKER_TYPE) {
            me.selectedWorkerList.push({ ...element });
          }
        });
      }
    },
    //選択済みのはずの社員を選択化
    setSelectWorkerRow: function () {
      let me = this;
      if (!this.selectedWorkerList || this.selectedWorkerList.length <= 0) {
        //選択状態の社員が存在しない場合
        return;
      }
      //
      this.workerList.forEach(function (element) {
        let data = me.selectedWorkerList.filter(
          (el) =>
            el.worker_cd == element.worker_cd  && 
            el.worker_type == me.WORKER_TYPE && 
            el.is_manager == me.propIsManager
        );
        if (data && data.length > 0) {
          element.selected = true;
        }
      });
    },
    //--  テーブル選択処理

    //行選択時
    rowClicked(item) {
      if (item.selected) {
        this.$set(item, "selected", false);
        this.removeSelectWorker(item);
      } else {
        this.$set(item, "selected", true);
        this.addSelectWorker(item);
      }
    },
    // 全選択時
    selectAllRows() {
      this.workerList.forEach((item) => {
        this.$set(item, "selected", true);
        this.addSelectWorker(item);
      });
    },
    //全選択解除時
    clearSelected() {
      this.workerList.forEach((item) => {
        this.$set(item, "selected", false);
        this.removeSelectWorker(item);
      });
    },
    // -- 確定時
    handleOk: function () {
      if (this.selectedWorkerList.length < 0) {
        this.$alert("社員が選択されていません。", "", {
          confirmButtonText: "OK",
        });
      } else {
        this.$emit("setSelectWorkers", this.selectedWorkerList);
        this.hideModal();
      }
    },
    //行のレイアウト
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item.selected) {
        return ["selectedRow"];
      } else {
        return [""];
      }
    },
  },
};
</script>

<style>
#employee-workers-select-modal .workerSelectTable {
  min-height: 450px;
  max-height: 450px;
  font-size: 14px;
  overflow-y: auto;
}

#employee-workers-select-modal .thSelected {
  width: 50px;
}

#employee-workers-select-modal .thWorkerCode {
  width: 110px;
}

#employee-workers-select-modal .selectedRow {
  background: rgba(255, 251, 193, 0.616);
}
</style>