import store from "@/store/"
import router from "@/router"
import config from "../const/config"

export default {
  methods: {
    addUrl(url, key) {
      if (key == null || key == "") {
        return "";
      }
      let str = '' + key;
      if (url.endsWith("/")) {
        if (str.startsWith("/")) {
          if (str.length == 1) {
            return "";
          } else {
            return str.substring(1) + "";
          }
        } else {
          return str + "";
        }
      } else {
        if (str.startsWith("/")) {
          return str + "";
        } else {
          return "/" + str + "";
        }
      }
    },
    /**
     * PK指定による1件検索処理.
     * @param {*} method RequestMapping Value
     * @param {*} key 検索対象のPK
     */
    getData(method, key1 = "", key2 = "") {
      let url = method;
      this.setCsrfHeader();
      url += this.addUrl(url, key1);
      url += this.addUrl(url, key2);
      return new Promise((resolve, reject) => {
        this.axios.get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * PK指定による1件検索処理.
     * @param {*} method RequestMapping Value
     * @param {*} key1 検索対象のPK1
     * @param {*} key2 検索対象のPK2
     */
    getDataMultiKey(method, key1, key2) {
      let url = method;
      url += this.addUrl(url, key1);
      url += this.addUrl(url, key2);
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * クエリパラメータ付きで1件検索.
     * @param {}} method RequestMapping Value
     * @param {*} key 更新対象PK
     * @param {*} json 登録内容JSONコンテナ
     */
    getWithParams(method, params) {
      let url = method;
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.get(url, { params: params })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      })
    },
    /**
     * 条件付き検索処理
     * @param {*} method RequestMapping Value
     * @param {*} paramList 検索条件リスト(Key-Value)
     */
    getDataList(method, paramList = {}) {
      let url = method;
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.post(url, paramList)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * 新規登録処理
     * @param {*} method RequestMapping Value
     * @param {*} json 登録内容JSONコンテナ
     */
    postData(method, json) {
      let url = method;
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.post(url, json)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },

    /**
     * 新規登録処理
     * @param {}} method RequestMapping Value
     * @param {*} key 更新対象PK
     * @param {*} json 登録内容JSONコンテナ
     */
    postDataWithKey(method, key1, json) {
      let url = method;
      url += this.addUrl(url, key1);
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.post(url, json)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      })
    },

    /**
     * 新規登録処理
     * @param {}} method RequestMapping Value
     * @param {*} query 更新対象PKクエリストリング
     * @param {*} data 登録内容JSONコンテナ
     */
    postDataWithQuery(method, query, data) {
      let url = method;
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.post(url, data, { params: query })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      })
    },

    /**
     * 更新登録処理
     * @param {*} method RequestMapping Value
     * @param {*} key 更新対象PK
     * @param {*} json 登録内容JSONコンテナ
     */
    putData(method, key1, json) {
      let url = method;
      url += this.addUrl(url, key1);
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.put(url, json)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * 更新登録処理
     * @param {*} method RequestMapping Value
     * @param {*} key1 更新対象PK1
     * @param {*} key2 更新対象PK2
     * @param {*} json 登録内容JSONコンテナ
     */
    putDataMultiKey(method, key1, key2, json) {
      let url = method;
      url += this.addUrl(url, key1);
      url += this.addUrl(url, key2);
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.put(url, json)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /***
     * 削除
     */
    deleteDataParams(method, json) {
      let url = method;
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.delete(url, { data: json })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * 削除処理
     * @param {*} method RequestMapping Value
     * @param {*} key1 更新対象PK
     */
    deleteData(method, key1) {
      let url = method;
      url += this.addUrl(url, key1);
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.delete(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * 削除処理
     * @param {*} method RequestMapping Value
     * @param {*} key1 更新対象PK1
     * @param {*} key2 更新対象PK2
     */
    deleteDataMultiKey(method, key1, key2) {
      let url = method;
      url += this.addUrl(url, key1);
      url += this.addUrl(url, key2);
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.delete(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * API通信時にエラーが返却された場合の共通処理
     * @param {*} err 
     */
    connServerErrorHander(err) {
      if (err && err.response && err.response.status == 401) {
        if (store.state.userInfo.loggedIn) {
          // ログイン済だった場合は、ログイン画面へ遷移することを知らせるモーダルを表示.
          // App.Vueにモーダルの実装がある.
          store.dispatch(config.STORE.INDEX.ACTION.authErrorModalShow);
        }
        store.commit(config.STORE.USER_INFO.MUTATIONS.logOut);
        router.push(config.PAGES.LOGIN)
        return false;
      } else {
        return true;
      }
    },

    uploadFilePost(method, key1 = null, param) {
      let url = method;
      url += this.addUrl(url, key1);
      let config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.post(url, param, config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          });
      })
    },
    /**
     * ファイルダウンロード
     * @param {*} method 
     * @param {*} key1 
     * @returns 
     */
    downloadFileGetByKey(method, key1 = "",key2 = "") {
      let url = method;
      url += this.addUrl(url, key1);
      url += this.addUrl(url, key2);
      return new Promise((resolve, reject) => {
        this.axios.get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.data.type
            });
            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = this.getFileName(contentDisposition)

            //ダウンロードします
            this.saveAs(blob, fileName);
            resolve(response);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          })
      });
    },
    /**
     * ファイルダウンロード
     * @param {*} method 
     * @param {*} query 
     * @param {*} body 
     * @returns 
     */
    downloadFilePost(method, query = null, body = null) {
      let url = method;
      this.setCsrfHeader();
      return new Promise((resolve, reject) => {
        this.axios.post(url, body, { params: query, responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: response.data.type
            });
            //レスポンスヘッダからファイル名を取得します
            const contentDisposition = response.headers["content-disposition"];
            const fileName = this.getFileName(contentDisposition)

            //ダウンロードします
            this.saveAs(blob, fileName);

            resolve(response);
          })
          .catch((err) => {
            this.connServerErrorHander(err);
            reject(err);
          });
      });
    },

    saveAs(blob, fileName) {
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    getFileName(contentDisposition) {
      let fileName = contentDisposition.substring(contentDisposition.indexOf("''") + 2,
        contentDisposition.length
      );
      //デコードするとスペースが"+"になるのでスペースへ置換します
      fileName = decodeURI(fileName).replace(/\+/g, " ");
      return fileName;
    },

    setCsrfHeader() {
      const csrf = this.$cookies.get("_ctkn");
      if (csrf) {
        this.axios.defaults.headers.common["X-CSRF-TOKEN"] = csrf;
      }

    }
  },
};