import moment from 'moment'
export default {
  methods: {
    getTargetMonthStartDate: function (value) {
      return moment(value).startOf('month')
    },
    getTargetMonthEndDate: function (value) {
      return moment(value).endOf('month')
    },
    getStrYmKanji: function (value) {
      return moment(value).format("YYYY年MM月");
    },
    getStrYmdKanji: function (value) {
      return moment(value).format("YYYY年MM月DD日");
    },
    getStrMd: function (value) {
      return moment(value).format("MM/DD");
    },
    getStrYm: function (value) {
      return moment(value).format("YYYY-MM");
    },
    // 
    getDate: function (value) {
      return moment(value).format("YYYY-MM-DD")
    },
    // 今月初
    getNowMonthStartDate: function (value) {
      return moment(value).startOf('month').format("YYYY-MM-DD");
    },
    // 今月末
    getNowMonthEndDate: function (value) {
      return moment(value).endOf('month').format("YYYY-MM-DD");
    },
    //日を取得
    getDay: function (value) {
      return moment(value).date();
    },
    getDefaultShowDate: function (value) {
      return moment(value).format("YYYY / MM / DD")
    },
    getStrDateSlash: function (value) {
      return moment(value).format("YYYY/MM/DD")
    },
    getStrDateHyphen: function (value) {
      return moment(value).format("YYYY-MM-DD");
    }
    ,
    getBeforeDate: function (value) {
      return moment(value).add(-1, "days").format();
    },
    getNextDate: function (value) {
      return moment(value).add(1, "days").format();
    },
    getStartWeek: function (value) {
      return moment(value).startOf('isoWeek').add(-1, "days").format("YYYY-MM-DD")
    },
    getEndWeek: function (value) {
      return moment(value).endOf('isoWeek').add(-1, 'days').format("YYYY-MM-DD");
    }


  }
}