<template>
  <div class>
    <nav
      class="
        border-bottom
        headerBackcolor
        navbar navbar-expand-md navbar-dark
        d-flex
        justify-content-between
      "
    >
      <!-- ブランド -->
      <div class style="height: 30px">
        <h4 class="ml-4" style="line-height: 30px">{{ propHeaderTitle }}</h4>
      </div>
      <div class="mx-3">
        <b-button variant="light" @click="logout">
          <b-icon icon="person-fill" class="mr-2"></b-icon
          >{{ userInfo.userName }}
        </b-button>
      </div>
    </nav>
    <div
      v-if="porpSubArea != null && porpSubArea != ''"
      class="w-100 border-bottom"
      style="height: 50px; background: white; color: black"
    >
      <h6 class="my-0" style="margin-left: 40px; line-height: 50px">
        {{ porpSubArea }}
      </h6>
    </div>
  </div>
</template>

<script>
import config from "../const/config";
import { mapState } from "vuex";

export default {
  name: "baseHeader",
  props: ["propHeaderTitle", "porpSubArea", "hiddenFlg"],
  data: function () {
    return {
      items: [
        {
          text: "home",
          href: "#",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    logout: function () {
      this.$confirm("ログアウトしますか?", "", {
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
      })
        .then((result) => {
          // this.$router.push("/");
          if (result) {
            this.postData(config.URL.LOGOUT);
            this.$store.commit(config.STORE.USER_INFO.MUTATIONS.logOut);
            this.$router.push(config.PAGES.LOGIN);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.headerBackcolor {
  /* background-color: rgb(245, 245, 245); */
  background: var(--mainColor);
  color: rgb(71, 71, 71);
  color: rgb(255, 255, 255);
}
/* .theme_back_coloer {
  background: rgba(3, 117, 204, 0.508) !important;
} */
</style>