<template>
  <div id="data-export" class="main">
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="日報個人別入力明細出力（ WEB日報入力 ＞＞ 給与システム ）"></BaseHeader>
    </header>
    <main class="main_area">
      <b-container fluid>
        <div class="m-4" style="width: 700px">
          <div class="mt-2">
            <b-table-simple bordered>
              <b-tr>
                <b-th class="themeColoreHeader">作業日報期間</b-th>
                <b-td class="d-flex">
                  <b-input size="sm" type="date" class="inputDateEdit mr-2" v-model="periodFrom" :disabled="isDateEdit"></b-input>~
                  <b-input size="sm" type="date" class="inputDateEdit ml-2" v-model="periodTo" :disabled="isDateEdit"></b-input>
                  <b-button size="sm" class="m-auto" @click="isDateEdit = !isDateEdit">
                    <span v-if="isDateEdit">変更 有効化</span>
                    <span v-else>変更 無効化</span>
                  </b-button>
                </b-td>
              </b-tr>
            </b-table-simple>
          </div>
          <div>
            <b-button variant="success" @click="execute">社員日報出力</b-button>
            <b-button variant="success" @click="executeForDailyReportBase" class="ml-3">工事概要出力</b-button>
          </div>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
// import moment from "moment";
import config from "../const/config";
import BaseHeader from "../components/BaseHeader";

export default {
  name: "data-export",
  components: {
    BaseHeader,
  },
  data() {
    return {
      isDateEdit: true,
      periodFrom: null,
      periodTo: null,
    };
  },
  watch: {},
  mounted: function () {
    this.periodFrom = this.getNowMonthStartDate();
    this.periodTo = this.getNowMonthEndDate();
  },
  methods: {
    /**
     * 社員日報出力
     */
    execute: function () {
      const lo = this;

      var query = {
        start_at: this.periodFrom,
        end_at: this.periodTo,
      };
      // 出力処理
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);

      this.downloadFilePost(config.URL.DAILY_WORK_AND_PAID_CSV_EXPORT, query)
        .catch((e) => {
          lo.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    /**
     * 工事概要出力
     * (データ移出画面の”出力データ：工事別日別作業内容データ”と同様のデータをダウンロードする)
     */
    executeForDailyReportBase: function () {
      const lo = this;

      var query = {
        start_at: this.periodFrom,
        end_at: this.periodTo,
      };
      var request = {
        //0:日報入力データ, 1:工事別日別作業内容データ
        select_file: 1,
      };
      
      // 出力処理
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.downloadFilePost(config.URL.DATA_EXPORT, query, request)
        .catch((e) => {
          lo.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
  },
};
</script>

<style>
#data-export .workerSelectTable {
  max-height: 420px;
  font-size: 14px;
}

#data-export .inputDateEdit {
  text-align: center;
  width: 160px;
}
</style>
