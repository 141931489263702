<template>
  <b-alert
    class="m-0 alert"
    :show="this.show"
    :variant="this.type"
    :class="{ sm: this.size == 'sm' }"
    >{{ this.message }}</b-alert
  >
</template>

<script>
export default {
  name: "Alert",
  props: {
    message: String,
    type: {
      type: String,
      default: "warning",
    },
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    show() {
      const msg = this.message;
      return msg != null;
    },
  },
};
</script>

<style scoped>
.alert {
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
}
.sm {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}
</style>