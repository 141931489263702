<template>
  <div>
    <b-modal
      id="paid-edit-modal"
      :centered="true"
      v-model="isModalShow"
      hide-footer
      header-class="modal_header"
      :no-close-on-backdrop="false"
    >
      <!-- モーダルヘッダー -->
      <template v-slot:modal-header>
        <h5 class="modal-title py-0">有給登録・変更</h5>
        <b-button class="close" @click="hideModal" aria-label="閉じる">
          <span aria-hidden="true">×</span>
        </b-button>
      </template>
      <!-- モーダルボディ -->
      <template v-slot:default>
        <div class="px-2">
          <div>
            <div class="text-center">
              <h4 class="mt-3">{{ strTargetDate }}</h4>
            </div>
          </div>
          <div class="my-4">
            <div class="mt-3 text-center">
              <b-form-radio-group
                class="w-100"
                buttons
                button-variant="outline-primary"
                :options="options"
                v-model="selectPaidType"
              ></b-form-radio-group>
            </div>
            <b-collapse v-model="visible">
              <b-card class="mt-4">
                <b-form-group class="w-50" label="時間">
                  <b-select :options="houreOptions" v-model="paidHour"></b-select>
                </b-form-group>
              </b-card>
            </b-collapse>
          </div>
          <div class="mt-2">
            <b-row class="text-center">
              <b-col cols="6">
                <b-button variant="secondary" @click="hideModal">キャンセル</b-button>
              </b-col>
              <b-col cols="6">
                <b-button variant="success" @click="handleOk">確定登録</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-modal>
    <Confirm
      ref="confirmDelete"
      title="有給削除"
      message="有給設定を削除します。"
      focusCancel="true"
      @parent-ok-event="deletePaid()"
    ></Confirm>
  </div>
</template>

<script>
import config from "../../const/config";
import Confirm from "../Confirm";

export default {
  name: "paidEditModal",
  components: { Confirm },
  props: ["propDateList", "propEmployeeCd"],
  data: function () {
    return {
      isUpdateMode: false,
      isModalShow: false,
      //有給種別選択
      options: [],
      selectPaidType: config.VALUE.PAIDTYPE_HOUR,
      //時間有給の時間選択欄の有効無効
      visible: false,
      //有給時間選択
      houreOptions: [
        { value: "1", text: "1時間" },
        { value: "2", text: "2時間" },
        { value: "3", text: "3時間" },
        { value: "4", text: "4時間" },
        { value: "5", text: "5時間" },
        { value: "6", text: "6時間" },
        { value: "7", text: "7時間" },
        { value: "8", text: "8時間" },
      ],
      paidHour: 1,
      //表示用日付
      strTargetDate: "",
      // リクエスト
      startTargetDate: null,
      endTargetDate: null,
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        //初期化
        this.init();
        //
        this.setProcessMode();
        //処理対象日付の設定
        this.setTargetDate();
      } else {
        // モーダル閉じるとき
        this.$emit("parent-event");
      }
    },
    // 有給種別変更時（1日有給・時間有給・削除）
    selectPaidType: function () {
      //時間選択欄の有効無効
      if (this.selectPaidType == config.VALUE.PAIDTYPE_HOUR) {
        //有効
        this.visible = true;
      } else {
        //無効
        this.visible = false;
      }
    },
  },
  computed: {},
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.$root.$emit("bv::hide::modal", "paid-edit-modal", "");
    },
    init: function () {
      this.isUpdateMode = false;
      //有給種別選択肢の設定
      this.options = [
        { value: config.VALUE.PAIDTYPE_DAY, text: "一日有給取得" },
        { value: config.VALUE.PAIDTYPE_HOUR, text: "時間有給取得" },
      ];
      //初期値の処理種別を1日有給とする
      this.selectPaidType = config.VALUE.PAIDTYPE_DAY;
      //対象日付の初期化
      this.startTargetDate = null;
      this.endTargetDate = null;
    },
    //
    setProcessMode: function () {
      //
      if (this.propDateList != null && this.propDateList.length > 0) {
        if (this.propDateList[0].isPaid) {
          //--有給データの更新・削除の場合
          this.isUpdateMode = true;

          //更新や削除の場合、選択可能は1件のみなのでindexは0のみ参照
          let updateTarget = this.propDateList[0];
          //現在の有給種別をセット
          this.selectPaidType = updateTarget.paidType;
          //現在の有給時間の設定
          if (this.selectPaidType == config.VALUE.PAIDTYPE_HOUR) {
            this.paidHour = updateTarget.paidHour;
          }
          //有給種別に（＋削除）選択肢を追加
          this.options.push({
            value: config.VALUE.PAIDTYPE_DELETE,
            text: "有給設定を削除",
          });
        }
      }
    },
    // 処理対象日時の設定
    setTargetDate: function () {
      // 初期化
      this.strTargetDate = "";

      if (this.propDateList != null) {
        let dateLen = this.propDateList.length;
        if (dateLen > 0) {
          let start = this.propDateList[0];
          this.strTargetDate += this.getDefaultShowDate(start.strHyphenDate);
          //開始日と終了日に同じ日を設定
          this.startTargetDate = start.strHyphenDate;
          this.endTargetDate = start.strHyphenDate;

          //複数日対応
          if (dateLen > 1) {
            // 複数日付選択の場合
            let end = this.propDateList[this.propDateList.length - 1];
            this.strTargetDate +=
              " ～ " + this.getDefaultShowDate(end.strHyphenDate);
            // 複数日のため終了日を再設定
            this.endTargetDate = end.strHyphenDate;
          }
        }
      }
    },
    //リクエストの準備
    getRequest: function () {
      //時間の設定
      let hour = null;
      if (this.selectPaidType == config.VALUE.PAIDTYPE_HOUR) {
        hour = this.paidHour;
      }

      return {
        employee_cd: this.propEmployeeCd,
        targetType: this.selectPaidType,
        hour: hour,
        targetDate: this.startTargetDate,
        startTargetDate: this.startTargetDate,
        endTargetDate: this.endTargetDate,
      };
    },
    handleOk: function () {
      if (this.selectPaidType == config.VALUE.PAIDTYPE_DELETE) {
        this.$refs.confirmDelete.openDialog();
      } else if (this.selectPaidType == config.VALUE.PAIDTYPE_DAY) {
        this.save();
      } else if (this.selectPaidType == config.VALUE.PAIDTYPE_HOUR) {
        this.save();
      }
    },
    //新規登録・更新
    save: function () {
      //
      let param = this.getRequest();
      //
      let process = config.PROCESS.INSERT;
      if (this.isUpdateMode) {
        process = config.PROCESS.UPDATE;
      }
      // リクエスト
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(config.URL.PAID_EDIT + process, param)
        .then((response) => {
          if (response.result) {
            me.hideModal();
          } else {
            me.showErrorAlert(response.detail.error, "登録・更新エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //削除
    deletePaid: function () {
      let param = this.getRequest();
      // リクエスト
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(config.URL.PAID_EDIT + config.PROCESS.DELETE, param)
        .then((response) => {
          if (response.result) {
            me.hideModal();
          } else {
            me.showErrorAlert(response.detail.error, "削除エラー");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
  },
};
</script>

<style>
</style>