<template>
  <el-dialog
    class="confirmDialog"
    width="25%"
    top="40vh"
    :title="title"
    :visible.sync="dialogVisible"
    v-on:keyup.enter="handleEnter()"
  >
    <div class="my-2" style="white-space:pre-wrap; word-wrap:break-word;" v-text="message"></div>
    <span ref="footerConfirm" slot="footer" class="dialog-footer">
      <el-button
        ref="btnConfirmCancel"
        size="small"
        class="confirmBtn"
        @click="handleClose()"
      >{{btnCancelText}}</el-button>
      <el-button
        ref="btnConfirmOk"
        size="small"
        class="confirmBtn"
        type="primary"
        @click="handleOk()"
      >{{btnOkText}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
/* eslint-disable */
export default {
  name: "confirm",
  props: {
    title: { default: () => "" },
    message: { default: () => "" },
    btnCancelText: { default: () => "キャンセル" },
    btnOkText: { default: () => "はい" },
    focusCancel: { default: () => false },
  },
  data: function () {
    return { dialogVisible: false, focusOk: false };
  },
  mounted: function () {},
  watch: {
    //ダイアログ開閉時
    dialogVisible: function () {
      if (this.dialogVisible) {
        //ダイアログ開
        document.addEventListener("keydown", this.onKeyDown);
      } else {
        //ダイアログ閉
        document.removeEventListener("keydown", this.onKeyDown);
      }
    },
  },
  methods: {
    onKeyDown: function (event) {
      document.activeElement.blur();
      let key = event.key;
      if (key == "Enter") {
        //Enter押下
        if (this.focusCancel) {
          //キャンセルとして処理
          this.handleClose();
        } else {
        }
      } else if (key == "Tab") {
        event.preventDefault();
      }
    },
    openDialog: function () {
      this.dialogVisible = true;
    },
    handleClose: function () {
      this.$emit("parent-cancel-event");
      this.dialogVisible = false;
    },
    handleOk: function () {
      this.$emit("parent-ok-event");
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
.confirmDialog .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>