<template>
  <b-modal
    id="worker-select-modal"
    size="lg"
    :centered="true"
    hide-footer
    header-class="modal_header"
    :no-close-on-backdrop="false"
    v-model="isModalShow"
  >
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">社員選択</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="px-5">
        <div class="d-flex">
          <b-input-group prepend="キーワード" class="mt-2">
            <b-input v-model="partialMatch" v-on:keyup.enter="handleSearch"></b-input>
            <b-input-group-append>
              <b-button size="sm" class="px-3" @click="handleSearch">検索</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="mt-2">
          <!-- 給与形態種別： -->
          <div>
            <b-form-radio-group
              size="sm"
              v-model="selectSalaryType"
              @change="handleSearch"
              buttons
              button-variant="outline-info"
            >
              <b-form-radio :value="null">全て</b-form-radio>
              <b-form-radio :value="SALARY_TYPE_MONTH">月給</b-form-radio>
              <b-form-radio :value="SALARY_TYPE_DAY" v-if="isUseDailySalary">日給</b-form-radio>
              <b-form-radio :value="SALARY_TYPE_HOUR">時給</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
        <div class="mt-2" style="min-height:460px;">
          <b-table
            class="workerSelectTable"
            sticky-header
            small
            bordered
            selectable
            select-mode="single"
            :fields="fields"
            :items="employeeList"
            @row-selected="onRowSelected"
          ></b-table>
        </div>
        <div class="text-center">
          <b-button class="px-5" variant="success" @click="handleOk">OK</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
import config from "../const/config";

export default {
  name: "WorkerSelectModal",
  props: ["value", "propsIsPaidManagement", "propsDailyReportInputType"],
  data: function () {
    return {
      isModalShow: false,
      selected: null,
      partialMatch: null,
      fields: [
        {
          key: "employee_cd",
          label: "社員コード",
          sortable: true,
          thClass: "w-15 text-center themeColoreHeader",
          tdClass: "text-center",
        },
        {
          key: "employee_name",
          label: "社員名",
          sortable: false,
          thClass: "themeColoreHeader",
        },
        {
          key: "employee_name_kana",
          label: "カナ",
          sortable: true,
          thClass: "w-50 themeColoreHeader",
        },
      ],
      employeeList: [],
      // 給与形態種別
      SALARY_TYPE_MONTH: config.VALUE.SALARY_TYPE_MONTH,
      SALARY_TYPE_DAY: config.VALUE.SALARY_TYPE_DAY,
      SALARY_TYPE_HOUR: config.VALUE.SALARY_TYPE_HOUR,
      selectSalaryType: null,
      //日給シートの利用有無
      isUseDailySalary: false,
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        this.selectSalaryType = null;
        this.getEmployeeList();
      } else {
        //
      }
    },
  },
  mounted: function () {
    this.setWorkerTypeOption();
  },
  methods: {
    //日給シートの利用有無を取得
    setWorkerTypeOption: function () {
      let me = this;
      //日給シートの利用有無の取得
      me.isUseDailySalary = false;
      this.getData(config.URL.DAILY_REPORT + "/is_use_dayly_salary")
        .then((response) => {
          if (response.result) {
            me.isUseDailySalary = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日給シートの利用有無取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.selected = null;
      this.$root.$emit("bv::hide::modal", "worker-select-modal", "");
    },
    //行選択
    onRowSelected(items) {
      this.selected = items[0];
    },
    //検索押下
    handleSearch: function () {
      this.selected = null;
      this.getEmployeeList();
    },
    // 社員取得
    getEmployeeList: function () {
      let me = this;

      this.employeeList = [];

      // パラメータ作成
      let employeeSearchConditions = {
        partialMatch: this.partialMatch,
        is_paid_management: this.propsIsPaidManagement,
        daily_report_input_type: this.propsDailyReportInputType,
        employee_status: config.VALUE.EMPLOYEE_STATUS_WORK,
        salary_type: this.selectSalaryType,
      };
      // リクエスト
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.getDataList(
        config.URL.EMPLOYEE + config.PROCESS.SEARCH,
        employeeSearchConditions
      )
        .then((response) => {
          if (response.result) {
            //
            me.employeeList = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    handleOk: function () {
      if (this.selected == null) {
        this.$alert("社員が選択されていません。", "", {
          confirmButtonText: "OK",
        });
      } else {
        //
        this.$emit("input", this.selected);
        this.hideModal();
      }
    },
  },
};
</script>

<style>
#worker-select-modal .workerSelectTable {
  max-height: 450px;
  font-size: 14px;
}
</style>