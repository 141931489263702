<template>
  <div class="workStateArea" @click="clearPopover">
    <!-- コンテキストメニュー： -->
    <context-menu id="context-menu" ref="ctxMenu" class="p-0">
      <b-list-group style="word-break: keep-all">
        <!-- <b-list-group-item button @click="toDeleteMode"
          >削除モード</b-list-group-item
        >-->
        <b-list-group-item button @click="toEditMode">編集モード</b-list-group-item>
        <b-list-group-item button @click="handelBeforeWorkerDailyReport">前者コピー</b-list-group-item>
        <b-list-group-item button @click="showCopyWorkModal">前回作業日コピー</b-list-group-item>
        <b-list-group-item button @click="handleClear">クリア</b-list-group-item>
        <b-list-group-item button @click="handleDailyReportDelete">削除</b-list-group-item>
      </b-list-group>
    </context-menu>
    <!-- 勤務時間編集テーブル -->
    <b-table-simple small fixed class="workStateTable text-center">
      <!-- 先頭のみ表示のヘッダ -->
      <b-thead v-if="this.rowIndex == 0">
        <b-tr>
          <b-th class="workStateWorkername">
            {{ this.isPartnerCompany ? "会 社 名" : "氏 名" }}
          </b-th>
          <b-th colspan="8" class="workStateContent">
            {{ this.isPartnerCompany ? "勤務内容" : "手当 / 休み / 勤務内容" }}
          </b-th>
          <b-th>区分</b-th>
          <b-th colspan="96" class="Nken_BorderRightBold">勤 務 時 間</b-th>
          <b-th colspan="5" class="Nken_BorderRightBold">時 間 計</b-th>
          <!-- ↓ 2023/10 -->
          <b-th colspan="2" class="Nken_MonthTotal__TitleCel">月 間 計</b-th>
          <!-- ↑ 2023/10 -->
        </b-tr>
      </b-thead>
      <b-tbody v-if="dailyReportData != null">
        <!-- ヘッダー・手当行 ---------------------------------------------------------------------->
        <b-tr class="text-center">
          <!-- 担当者氏名 -->
          <b-th
            v-if="isWorkerEditMode"
            @contextmenu.prevent="$refs.ctxMenu.open"
            :class="getModeClassForWorkerName()"
            :rowspan="dailyReportData.workContentList.length + 1"
            @click="offEditMode">
            <div>{{ dailyReportData.workerName }}</div>
          </b-th>
          <b-th
            v-else
            @contextmenu.prevent="$refs.ctxMenu.open"
            :class="getModeClassForWorkerName()"
            :rowspan="dailyReportData.workContentList.length + 1"
            @click="toEditMode">
            <div>{{ dailyReportData.workerName }}</div>
          </b-th>
          <!-- 協力会社の場合 手当の代わりに勤務者人数 -->
          <b-th v-if="this.isPartnerCompany" class="p-0" colspan="8">
            <div class="Nken_WorkerCountBlock">
              <div class="Nken_WorkerCountBlock__TitleLabelArea">勤務者人数</div>
              <div class="Nken_WorkerCountBlock__InputArea">
                <b-form-input
                  v-model="dailyReportData.workerCount"
                  type="number"
                  size="sm"
                  class="text-right partnerCompanyWorkerCount no-spin"
                  @change="updateDailyReport"
                  :readonly="!isWorkerEditMode">
                </b-form-input>
                <div class="ml-1 btnEditWorkerCont" @click="upWorkerCount">
                  <b-icon scale="2rem" icon="caret-up-fill"></b-icon>
                </div>
                <div class="btnEditWorkerCont" @click="downWorkerCount">
                  <b-icon scale="2rem" icon="caret-down-fill"></b-icon>
                </div>
              </div>
              <div class="pt-1 px-1">人</div>
            </div>
          </b-th>
          <!-- 社員・作業者の場合 手当 -->
          <!-- 職長 -->
          <b-th v-if="!this.isPartnerCompany" class="Nken_WorkStateContent__SupplementInfoCel">職長</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel pointer ' + (dailyReportData.isForeman == VALID ? ' isforeman pointer' : '')"
            @click="togleForeman(dailyReportData)"></b-th>
          <!-- 送迎 -->
          <b-th v-if="!this.isPartnerCompany" class="Nken_WorkStateContent__SupplementInfoCel">送迎</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel pointer ' + (dailyReportData.isTransferGo == VALID ? ' isTransfer ' : '')"
            @click="togleTransfer1(dailyReportData)"
            >往</b-th
          >
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel pointer ' + (dailyReportData.isTransferBack == VALID ? ' isTransfer ' : '')"
            @click="togleTransfer2(dailyReportData)"
            >複</b-th
          >
          <!-- 有給 -->
          <b-th v-if="!this.isPartnerCompany" class="Nken_WorkStateContent__SupplementInfoCel">有給</b-th>
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel ' + (dailyReportData.isPaid == VALID ? ' isPaid' : '')">
            <span v-if="dailyReportData.paidType == VALID">1d</span>
            <span v-else-if="dailyReportData.paidType == 2">{{ dailyReportData.paidHour }}h</span>
          </b-th>
          <!-- 無勤 -->
          <b-th
            v-if="!this.isPartnerCompany"
            :class="'Nken_WorkStateContent__SupplementInfoCel pointer ' + (dailyReportData.isAbsence == VALID ? ' isAbsence ' : '')"
            @click="togleAbsence(dailyReportData)"
            >無勤</b-th
          >
          <!-- ---------------------------------------------------------------------------------- -->
          <b-th class="colWorkTyp"></b-th>
          <!-- 時刻（ 0時～23時 ） -->
          <b-th v-for="hourNo in 24" :key="hourNo" colspan="4" :class="getWorkTimeHeader(hourNo - 1)">{{ hourNo - 1 }}</b-th>
          <!-- 時間計 -->
          <b-th class="thSumTime midnightBackcolor">内深夜</b-th>
          <b-th class="thSumTime">8h内</b-th>
          <b-th class="thSumTime">8h外</b-th>
          <b-th class="thSumTime midnightBackcolor">外深夜</b-th>
          <b-th class="thSumTime Nken_BorderRightBold">計</b-th>
          <!-- ↓ 2023/10 -->
          <b-th class="Nken_MonthTotal__TypeTitleCel">8h内</b-th>
          <b-th class="Nken_MonthTotal__TypeTitleCel">8h外</b-th>
          <!-- ↑ 2023/10 -->
        </b-tr>
        <!---時間チャート行（工事・附帯・運転・休憩行ごと）---------------------------------------------------------------------->
        <b-tr v-for="(rowItem, rowIIndex) in dailyReportData.workContentList" :key="rowIIndex">
          <!-- 業務内容備考 業務行 -->
          <b-td v-if="!isBreakRow(rowItem.type)" colspan="8" class="p-0 workStateContent">
            <WorkSelectNote
              v-model="rowItem.content"
              :propIsDisabled="!isWorkerEditMode"
              :propOptions="workContentOptions"
              @setEvent="updateDailyReport"></WorkSelectNote>
          </b-td>

          <!-- 勤務計 休憩行-->
          <b-th colspan="2" class="Nken_WorkStateContent__DailyTimeTotalCel" v-if="isBreakRow(rowItem.type)">勤務計</b-th>
          <b-td colspan="2" v-if="isBreakRow(rowItem.type)" class="Nken_WorkStateContent__DailyTimeTotalCel thSumAll">{{
            getSumTimeTimeZoneAll(0, 95)
          }}</b-td>
          <!-- 休憩計 休憩行-->
          <b-th colspan="2" class="Nken_WorkStateContent__DailyTimeTotalCel" v-if="isBreakRow(rowItem.type)">休憩計</b-th>
          <b-td colspan="2" v-if="isBreakRow(rowItem.type)" class="Nken_WorkStateContent__DailyTimeTotalCel breakTimeBackcolor">{{
            getSumTimeRow(rowItem.timeData) | emptyFilters
          }}</b-td>

          <!-- 種別名 共通-->
          <b-th @click="handleBaseTimeSet(rowItem, rowItem.type)" :class="getTypeClass(rowItem.type)" role="button">{{
            getWorkTypeName(rowItem.type)
          }}</b-th>

          <!-- 時間列 共通-->
          <b-td
            v-for="(timeItem, timeIndex) in rowItem.timeData"
            :key="timeIndex"
            :class="getColClass(rowItem.type, timeIndex, timeItem)"
            @mousedown="tdClick(rowItem.timeData, rowIIndex, timeIndex)"
            @mouseup="tdMousuup"
            @mouseover="tdMousover(rowIIndex, timeIndex)"
            v-b-popover.html.click.top="{ variant: 'warning', content: getOtherConstructionName(timeItem, timeIndex) }"></b-td>

          <!-- 時間計 業務行 -------------->
          <b-td v-if="!isBreakRow(rowItem.type)" class="thSumTime" :class="getSumTimeMidnightIn(rowItem.timeData) != 0 ? 'midnightIn' : ''">
            {{ minutesToPercentage(getSumTimeMidnightIn(rowItem.timeData)) }}
          </b-td>
          <b-td v-if="!isBreakRow(rowItem.type)" class="thSumTime" :class="getSumTimeBaseIn(rowItem.timeData) != 0 ? 'baseIn' : ''">{{
            minutesToPercentage(getSumTimeBaseIn(rowItem.timeData))
          }}</b-td>
          <b-td v-if="!isBreakRow(rowItem.type)" class="thSumTime" :class="getSumTimeBaseOut(rowItem.timeData) != '' ? 'eightOver' : ''">{{
            getSumTimeBaseOut(rowItem.timeData) | emptyFilters
          }}</b-td>
          <b-td
            v-if="!isBreakRow(rowItem.type)"
            class="thSumTime"
            :class="getSumTimeMidnightOut(rowItem.timeData) != 0 ? 'midnightOut' : ''">
            {{ minutesToPercentage(getSumTimeMidnightOut(rowItem.timeData)) }}
          </b-td>
          <!-- 区分別計 業務行 -->
          <b-td class="tdSumTime thSumTime Nken_BorderRightBold" v-if="!isBreakRow(rowItem.type)">
            {{ getSumTimeRow(rowItem.timeData) | emptyFilters }}
          </b-td>

          <!-- 時間計 休憩行 ------------->
          <b-td v-if="isBreakRow(rowItem.type)">
            {{ minutesToPercentage(getSumTimeMidNightInAll()) | emptyFilters }}
          </b-td>
          <b-td v-if="isBreakRow(rowItem.type)">
            {{ minutesToPercentage(getSumTimeBaseInAll(dailyReportData.workContentList)) }}
          </b-td>
          <b-td v-if="isBreakRow(rowItem.type)">
            {{ getSumTimeBaseOutAll(dailyReportData.workContentList) | emptyFilters }}
          </b-td>
          <b-td v-if="isBreakRow(rowItem.type)">
            {{ minutesToPercentage(getSumTimeMidnightOutAll(dailyReportData.workContentList)) }}
          </b-td>
          <!-- 勤務計 休憩行)-->
          <b-td v-if="isBreakRow(rowItem.type)" class="thSumAll Nken_BorderRightBold">
            {{ getSumTimeTimeZoneAll(0, 95) }}
          </b-td>

          <!-- ↓ 2023/10 -->
          <!-- 月間計  ------------->
          <b-td v-if="rowIIndex == 0">{{ dailyReportData.monthTotalWorkTimeIn8 }}</b-td>
          <b-td v-if="rowIIndex == 0">{{ dailyReportData.monthTotalWorkTimeOut8 }}</b-td>
          <!-- ↑ 2023/10 -->
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <CopyWorkModal ref="dateSelectModal" :propDateList="recentDailyReportDateList" @parent-event="dailyReportCopy"></CopyWorkModal>
    <!--  -->
    <Confirm
      ref="confirmClear"
      title="クリア"
      message="日報を初期化します。"
      focusCancel="true"
      @parent-ok-event="clearDailyReport()"></Confirm>
    <Confirm
      ref="confirmDelete"
      title="日報削除"
      message="日報を削除します。"
      focusCancel="true"
      @parent-ok-event="deleteDailyReport()"></Confirm>
  </div>
</template>

<script>
import utilsMixin from "../../mixin/utilityMixin";
import config from "../../const/config";
import WorkSelectNote from "./WorkSelectNote";
import CopyWorkModal from "../Work/CopyWorkModal";
import Confirm from "../Confirm";

export default {
  name: "WorkState",
  components: { WorkSelectNote, CopyWorkModal, Confirm },
  mixins: [utilsMixin],
  props: {
    worker: { type: Object, default: () => ({ editMode: false }) },
    rowIndex: { default: 0 },
    workDate: { default: null },
    isPartnerCompany: { default: false },
    baseTimes: { type: Object },
    workerList: { type: Array },
  },
  data() {
    return {
      // 日報データ
      dailyReportData: null,
      dailyReportDataCache: null,
      // 削除モードFLG
      isDeleteMode: false,
      // 勤務時間チャート描画管理
      timeChartEdit: false,
      startAreaKey: null,
      endAreaKey: null,
      targetDataList: null,
      targetIndex: null,
      // 8h境界時刻（index）
      overEightHour: null,
      //前回作業日付
      recentDailyReportDateList: [],
      //業務内容オプション
      workContentOptions: [],
      // 業務区分（定数）
      VALID: config.VALUE.VALID,
      WORK_TYPE_CONSTRUCTION: config.VALUE.WORK_TYPE_CONSTRUCTION,
      WORK_TYPE_AUXILIARY: config.VALUE.WORK_TYPE_AUXILIARY,
      WORK_TYPE_DRIVE: config.VALUE.WORK_TYPE_DRIVE,
      WORK_TYPE_BREAK: config.VALUE.WORK_TYPE_BREAK,
    };
  },
  filters: {
    emptyFilters: function (value) {
      if (value == 0.0) {
        return "";
      } else if (value == 0) {
        return "";
      }
      return value;
    },
  },
  computed: {
    // 編集モード
    isWorkerEditMode: function () {
      return this.worker.editMode;
    },
    isAbsenceValid: function () {
      return this.dailyReportData.isAbsence == this.VALID;
    },
  },
  mounted: function () {
    this.getWorkContentOptions();
    this.getDailyReport();
  },
  methods: {
    //--------------------------------
    // モード変更
    //--------------------------------

    // 編集モード終了
    offEditMode: function () {
      this.worker.editMode = false;
    },
    // 編集モード開始
    toEditMode: function () {
      this.isDeleteMode = false;
      // this.worker.editMode = true;
      this.$emit("changeEditMode", this.worker);
    },
    // 削除モード開始
    toDeleteMode: function () {
      this.isDeleteMode = true;
      this.$emit("changeEditMode", this.worker);
    },
    //--------------------------------
    // --
    //--------------------------------
    //勤務内容Option取得
    getWorkContentOptions: function () {
      let me = this;
      let param = { construction_cd: this.worker.construction_cd };
      this.workContentOptions = [];
      this.getWithParams(config.URL.ITEM_NAME + "/getWorkContentList", param)
        .then((response) => {
          if (response.result) {
            // 業務内容（文字配列List<String>）をセット
            me.workContentOptions = response.container;
          } else {
            me.showServerError();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    /**日報情報取得 */
    getDailyReport: function () {
      // -- 日報データ取得
      let me = this;
      let param = {
        construction_cd: this.worker.construction_cd,
        work_date: this.workDate,
        worker_cd: this.worker.worker_cd,
        worker_type: this.worker.worker_type,
        includeEmptyTimeData: this.VALID,
      };
      this.getWithParams(config.URL.DAILY_REPORT, param)
        .then((response) => {
          if (response.result) {
            me.dailyReportData = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "日報データ取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.getOverEightHours();
        });
    },
    // 描画：区分名取得
    getWorkTypeName: function (workType) {
      if (workType == this.WORK_TYPE_CONSTRUCTION) {
        return "工事";
      } else if (workType == this.WORK_TYPE_AUXILIARY) {
        return "附帯";
      } else if (workType == this.WORK_TYPE_DRIVE) {
        return "運転";
      } else if (workType == this.WORK_TYPE_BREAK) {
        return "休憩";
      } else {
        return "";
      }
    },
    // 描画：休憩行判定
    isBreakRow: function (type) {
      if (type == this.WORK_TYPE_BREAK) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 日報情報更新
     */
    updateDailyReport: function () {
      let me = this;
      this.putData(config.URL.DAILY_REPORT, "", this.dailyReportData)
        .then((response) => {
          if (response.result) {
            me.getDailyReport();
            return;
          } else {
            me.showErrorMsg(response.detail.error, "日報更新");
            me.getDailyReport();
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    /**
     * 日報削除
     */
    deleteDailyReport: function () {
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.putData(config.URL.DAILY_REPORT + config.PROCESS.DELETE, "", this.dailyReportData)
        .then((response) => {
          if (response.result) {
            return;
          } else {
            me.showErrorMsg(response.detail.error, "日報削除");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          this.$emit("getDailyReportList");
        });
    },

    //--------------------------------
    //-- 時間チャート操作
    //--------------------------------
    //
    clearPopover: function () {
      this.$root.$emit("bv::hide::popover");
    },
    getOtherConstructionName: function (item, index) {
      if (item != "9") {
        //他工事データではない場合
        return null;
      }
      console.log("ClickOtherConstruction, index = " + index);
      // return this.dailyReportData.otherConstructionList[index];
      return this.dailyReportData.otherConstructionNames;
    },
    /**
     * 勤務時間チャート表示データ更新
     */
    updateTimeItem: function (timeIndex) {
      let isSkip = false;
      this.dailyReportData.workContentList.forEach(function (workStateRow) {
        if (workStateRow.timeData[timeIndex] == config.VALUE.TIME_TYPE_ANOTHOR_CONSTRUCTION) {
          // 別事業所で登録データが存在する場合
          isSkip = true;
          return;
        } else if (workStateRow.timeData[timeIndex] == 1) {
          // 時間帯が選択済みの場合
          if (workStateRow.type == 99) {
            // 休憩を優先する
            isSkip = true;
            return;
          } else {
            workStateRow.timeData[timeIndex] = 0;
          }
        }
      });
      if (!isSkip) {
        this.$set(this.targetDataList, timeIndex, 1);
      }
    },

    /**
     * 勤務時間チャート表示データ更新(マウスを右から左に戻した場合)
     */
    updateTimeItemForBackLeft: function (timeIndex) {
      for (let j = timeIndex; j < this.endAreaKey; j++) {
        for (let i = 0; i < 4; i++) {
          this.$set(this.dailyReportData.workContentList[i].timeData, j + 1, this.dailyReportDataCache.workContentList[i].timeData[j + 1]);
        }
      }
    },

    /**
     * 勤務時間チャート表示データ更新(マウスを左から右に戻した場合)
     */
    updateTimeItemForBackRight: function (timeIndex) {
      for (let j = timeIndex; this.endAreaKey < j; j--) {
        for (let i = 0; i < 4; i++) {
          this.$set(this.dailyReportData.workContentList[i].timeData, j - 1, this.dailyReportDataCache.workContentList[i].timeData[j - 1]);
        }
      }
    },

    /**
     * 勤務時間チャート変更操作(クリック時)
     */
    tdClick: function (timeData, rowIndex, timeIndex) {
      // 対象とする区分行情報保持
      this.targetDataList = timeData;
      // 対象とする区分行インデックスを保持
      this.targetIndex = rowIndex;

      if (!this.isWorkerEditMode || this.isAbsenceValid) {
        // 編集不可モード
        this.targetDataList = null;
        this.targetIndex = null;
        return;
      }
      // if (this.isDeleteMode) {
      // 削除モード
      // すでに時間が登録されている場合、削除とする
      let clickTime = this.targetDataList[timeIndex];
      if (clickTime == config.VALUE.TIME_TYPE_HAVE_DATE) {
        this.deleteBlock(timeIndex);
        this.targetDataList = null;
        this.targetIndex = null;
        return;
      }

      // 現在の情報をキャッシュしておく
      this.dailyReportDataCache = JSON.parse(JSON.stringify(this.dailyReportData));

      //クリック地点から、選択有効化
      this.timeChartEdit = true;
      this.startAreaKey = timeIndex;
      this.endAreaKey = timeIndex;
      this.updateTimeItem(timeIndex);
    },

    /**
     * 勤務時間チャート変更操作(時間移動時)
     */
    tdMousover: function (rowIndex, timeIndex) {
      if (this.targetIndex != rowIndex) {
        // 行移動した場合
        this.tdMousuup();
        return;
      }

      if (this.timeChartEdit) {
        // --- タイムチャート変更可能の場合
        if (this.startAreaKey != null && this.endAreaKey != null && this.startAreaKey < this.endAreaKey && timeIndex < this.endAreaKey) {
          // マウスを右から左に戻した場合
          this.updateTimeItemForBackLeft(timeIndex);
          this.endAreaKey = timeIndex;
          return;
        }

        if (this.startAreaKey != null && this.endAreaKey != null && this.startAreaKey > this.endAreaKey && timeIndex > this.endAreaKey) {
          // マウスを左から右に戻した場合
          this.updateTimeItemForBackRight(timeIndex);
          this.endAreaKey = timeIndex;
          return;
        }

        if (this.startAreaKey != null) {
          this.endAreaKey = timeIndex;
          let start = this.startAreaKey;
          let end = timeIndex;
          if (start > end) {
            start = end;
            end = this.startAreaKey;
          }
          for (start; start <= end; start++) {
            this.updateTimeItem(start);
          }
        }
      }
    },

    /**
     * 勤務時間チャート変更操作（離す時）
     */
    tdMousuup: function () {
      if (!this.isWorkerEditMode || this.isAbsenceValid) {
        // 編集不可モード
        return;
      }
      //選択無効化
      this.timeChartEdit = false;
      if (this.startAreaKey != null && this.endAreaKey != null) {
        let start = this.startAreaKey;
        let end = this.endAreaKey;
        if (start > end) {
          start = this.endAreaKey;
          end = this.startAreaKey;
        }
        for (start; start <= end; start++) {
          this.updateTimeItem(start);
        }
        this.updateDailyReport();
      }
      this.dailyReportDataCache = null;
      this.startAreaKey = null;
      this.endAreaKey = null;
      this.targetDataList = null;

      this.getOverEightHours();
    },

    /**
     * ブロック単位削除
     */
    deleteBlock: function (index) {
      let i = this.targetDataList[index];
      if (i == 0) {
        return;
      }
      let beforeIndex = index;
      let afterIndex = index + 1;

      for (beforeIndex; beforeIndex >= 0; beforeIndex--) {
        if (this.targetDataList[beforeIndex] != 1) {
          break;
        }
        this.$set(this.targetDataList, beforeIndex, 0);
      }
      for (afterIndex; afterIndex <= 95; afterIndex++) {
        if (this.targetDataList[afterIndex] != 1) {
          break;
        }
        this.$set(this.targetDataList, afterIndex, 0);
      }
      this.updateDailyReport();
    },

    //--------------------------------
    //-- コンテキストメニュー操作
    //--------------------------------
    //クリア押下時
    handleClear: function () {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      //確認ダイアログ表示
      this.$refs.confirmClear.openDialog();
    },
    //クリア実行
    clearDailyReport: function () {
      let me = this;
      this.putData(config.URL.DAILY_REPORT, "/clear", this.dailyReportData)
        .then((response) => {
          if (response.result) {
            //
          } else {
            me.showErrorMsg(response.detail.error, "日報クリア");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.getDailyReport();
        });
    },
    // 前者コピー押下時
    handelBeforeWorkerDailyReport: function () {
      if (!this.isWorkerEditMode || this.isAbsenceValid) {
        // 編集不可モード
        return;
      }
      if (this.rowIndex == 0) {
        this.$alert("前者が存在しません。", "", {
          confirmButtonText: "OK",
        });
      } else {
        this.$confirm("前者の日報データをコピーします。", "前者コピー", {
          confirmButtonText: "はい",
          cancelButtonText: "キャンセル",
        })
          .then(() => {
            this.copyBeforeWorkerInfo();
          })
          .catch(() => {
            return;
          });
      }
    },
    //前者コピー
    copyBeforeWorkerInfo: function () {
      let beforeWorkerIndex = this.rowIndex - 1;
      let beforeWorker = this.workerList[beforeWorkerIndex];
      let param = {
        construction_cd: this.worker.construction_cd,
        target_date: this.workDate,
        worker_cd: this.worker.worker_cd,
        worker_type: this.worker.worker_type,
        employee_type: this.worker.employee_type,
        copy_worker_type: beforeWorker.worker_type,
        copy_worker_cd: beforeWorker.worker_cd,
      };
      let me = this;
      this.putData(config.URL.DAILY_REPORT, "/copy_worker_daily_report", param)
        .then((response) => {
          if (response.result) {
            //
          } else {
            me.showErrorMsg(response.detail.error, "前者コピー");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.getDailyReport();
        });
    },
    // 前回作業日コピー押下時
    showCopyWorkModal: function () {
      if (!this.isWorkerEditMode || this.isAbsenceValid) {
        // 編集不可モード
        return;
      }
      let me = this;
      //選択されている担当者のから直近の日付データを取得する
      let param = {
        construction_cd: this.worker.construction_cd,
        worker_cd: this.worker.worker_cd,
        worker_type: this.worker.worker_type,
        work_date: this.workDate,
      };
      this.recentDailyReportDateList = [];
      this.getDataList(config.URL.DAILY_REPORT + "/getRecentDateList", param)
        .then((response) => {
          if (response.result) {
            // スケジュールセット
            me.recentDailyReportDateList = response.container;
            me.$refs.dateSelectModal.showModal();
          } else {
            me.showErrorMsg(response.detail.error, "前回作業日取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    //前回作業日コピー
    dailyReportCopy: function (targetValue) {
      let param = {
        construction_cd: this.worker.construction_cd,
        target_date: this.workDate,
        worker_cd: this.worker.worker_cd,
        worker_type: this.worker.worker_type,
        employee_type: this.worker.employee_type,
        copy_date: targetValue,
      };
      let me = this;
      this.putData(config.URL.DAILY_REPORT, config.PROCESS.COPY, param)
        .then((response) => {
          if (response.result) {
            //
          } else {
            me.showErrorMsg(response.detail.error, "前回作業日コピー");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.getDailyReport();
        });
    },
    // 基本時間セット
    handleBaseTimeSet: function (rowItem, rowType) {
      // --編集可能状態かをチェック
      if (!this.isWorkerEditMode || this.isAbsenceValid) {
        // 編集不可モード
        return;
      }

      this.$confirm("基本時間をセットしますか?", "基本時間", {
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      })
        .then(() => {
          this.baseTimeSet(rowItem, rowType);
        })
        .catch(() => {});
    },
    /**
     * 基本時間のセット
     */
    baseTimeSet: function (rowItem, rowType) {
      let me = this;

      if (
        this.baseTimes.baseWorkTime == null ||
        this.baseTimes.baseWorkTime.length <= 0 ||
        this.baseTimes.baseBreakTime == null ||
        this.baseTimes.baseBreakTime.length <= 0
      ) {
        return;
      }

      //休憩の基本時間セット
      let breakTimeData = this.dailyReportData.workContentList.filter((el) => el.type == this.WORK_TYPE_BREAK);
      me.targetDataList = breakTimeData[0].timeData;
      this.baseTimes.baseBreakTime.forEach(function (item, index) {
        if (item == 1) {
          me.updateTimeItem(index);
        }
      });
      me.updateDailyReport();

      //初期化
      me.targetDataList = null;
      if (rowType != this.WORK_TYPE_BREAK) {
        //選択行が休憩以外の場合、業務基本時間セット
        me.targetDataList = rowItem.timeData;
        this.baseTimes.baseWorkTime.forEach(function (item, index) {
          if (item == 1) {
            me.updateTimeItem(index);
          }
        });
        me.updateDailyReport();
        //初期化
        me.targetDataList = null;
      }
      this.getOverEightHours();
    },

    /**
     * 削除
     */
    handleDailyReportDelete: function () {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      //確認ダイアログ表示
      this.$refs.confirmDelete.openDialog();
    },
    //--------------------------------
    //  タイムチャート計算
    //--------------------------------
    // 時間数取得
    getTimeCount: function (timeItem) {
      if (timeItem == 1) {
        return 1;
      } else {
        return 0;
      }
    },
    // 分をパーセンテージに変換
    minutesToPercentage: function (value) {
      let re = this.timeConvertMinToPercentage(value);
      if (re == "0.00") {
        return "";
      }
      return re;
    },
    //8h外切替時間を取得する
    getOverEightHours: function () {
      let rowCount = 0; //区分数
      let count = 0; //時間カウント
      let lo = this;

      this.dailyReportData.workContentList.forEach(function (element) {
        if (element.type != lo.WORK_TYPE_BREAK) {
          //休憩を除く区分
          rowCount++;
        }
      });

      let overEightHour = 0;
      for (let timeIndex = 0; timeIndex < 95; timeIndex++) {
        // 0時から24時まで順にカウントしていく
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
          count += this.getTimeCount(this.dailyReportData.workContentList[rowIndex].timeData[timeIndex]);
        }
        if (count >= 32) {
          //8時間越
          overEightHour = timeIndex;
          break;
        }
      }
      this.overEightHour = overEightHour;
    },
    //チャートのレイアウト変更描画クラス取得
    getColClass: function (type, timeZone, value) {
      if (this.overEightHour == null) {
        this.getOverEightHours();
      }
      let setClass = " tdTime ";

      //--1時間ごと横線描画用
      if (timeZone % 4 == 3) {
        setClass += " endHour";
      }
      if (timeZone % 4 == 0) {
        setClass += " startHour";
      }
      //時間チャート最終列
      if (timeZone == 95) {
        setClass += " Nken_BorderRightBold ";
      }

      //
      if (value == 0) {
        return setClass;
      }
      if (value == config.VALUE.TIME_TYPE_ANOTHOR_CONSTRUCTION) {
        setClass += " anothorConstructionTime";
        return setClass;
      }

      //8h外判定
      let isEightOver = false;
      if (this.overEightHour != 0 && this.overEightHour < timeZone) {
        isEightOver = true;
      }

      //
      if (type == this.WORK_TYPE_BREAK) {
        setClass += " breakTimeBackcolor";
      } else if (timeZone < 20) {
        setClass += " midnightIn";
      } else if (timeZone < 68) {
        if (isEightOver) {
          setClass += " eightOver";
        } else {
          setClass += " baseIn";
        }
      } else if (timeZone < 88) {
        if (isEightOver) {
          setClass += " eightOver";
        } else {
          setClass += " baseIn";
        }
      } else if (timeZone < 96) {
        if (this.overEightHour == 0 || timeZone <= this.overEightHour) {
          setClass += " midnightIn";
        } else {
          setClass += " midnightOut";
        }
      }

      return setClass;
    },
    // インデックス範囲の合計分を取得（行単位合計）
    getSumTimeMinutes: function (timeDataList, fromIndex, endIndex) {
      let sumMinutes = 0;
      for (let i = fromIndex; i <= endIndex; i++) {
        sumMinutes += this.getTimeCount(timeDataList[i]);
      }
      return sumMinutes * 15;
    },
    //内深夜の合計(全行)時間取得
    getSumTimeMidNightInAll: function () {
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }
      this.dailyReportData.workContentList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMidnightIn(item.timeData);
        }
      });
      return sumMinutes;
    },
    //内深夜の合計(行単位)時間取得
    getSumTimeMidnightIn(item) {
      //0~19
      if (this.timeChartEdit) {
        return "";
      }
      let sumMinutes = 0;
      //  0-5時
      sumMinutes += this.getSumTimeMinutes(item, 0, 19);
      // 22-24時
      let endIndex = 95;
      if (this.overEightHour != 0 && this.overEightHour < 95) {
        endIndex = this.overEightHour;
      }
      sumMinutes += this.getSumTimeMinutes(item, 88, endIndex);
      return sumMinutes;
    },
    //8h内の合計の合計(全行)時間取得
    getSumTimeBaseInAll(rowList) {
      //20~67
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }

      rowList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeBaseIn(item.timeData);
        }
      });
      return sumMinutes;
    },
    //8h内の合計(行単位)時間取得
    getSumTimeBaseIn(item) {
      //20~67
      if (this.timeChartEdit) {
        return "";
      }
      let end = 87;
      if (this.overEightHour != null && this.overEightHour != 0 && end > this.overEightHour) {
        end = this.overEightHour;
      }
      let sumMinutes = this.getSumTimeMinutes(item, 20, end);
      return sumMinutes;
    },
    //8h外の合計(全行)時間取得
    getSumTimeBaseOutAll(rowList) {
      //68~87
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }
      if (this.overEightHour == null || this.overEightHour == 0) {
        return "";
      }
      rowList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMinutes(item.timeData, lo.overEightHour + 1, 87);
        }
      });
      let re = this.timeConvertMinToPercentage(sumMinutes);
      return re;
    },
    //外深夜の合計(全行)時間取得
    getSumTimeMidnightOutAll(rowList) {
      let sumMinutes = 0;
      let lo = this;
      if (this.timeChartEdit) {
        return "";
      }
      rowList.forEach(function (item) {
        if (item.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMidnightOut(item.timeData);
        }
      });
      return sumMinutes;
    },
    //外深夜の合計(行単位)時間取得
    getSumTimeMidnightOut(item) {
      //88~95
      let sumMinutes = 0;
      if (this.timeChartEdit) {
        return "";
      }

      if (this.overEightHour != 0) {
        //8時間を超えていて、超えた位置が深夜以降の場合
        if (this.overEightHour >= 88) {
          sumMinutes = this.getSumTimeMinutes(item, this.overEightHour + 1, 95);
        } else {
          sumMinutes = this.getSumTimeMinutes(item, 88, 95);
        }
      }

      return sumMinutes;
    },
    //8h外の合計(行単位)時間取得
    getSumTimeBaseOut(item) {
      //68~87
      if (this.timeChartEdit) {
        return "";
      }
      if (this.overEightHour == null || this.overEightHour == 0) {
        return "";
      }

      // let sumMinutes = this.getSumTimeMinutes(item, 68, 87);
      let sumMinutes = this.getSumTimeMinutes(item, this.overEightHour + 1, 87);
      let re = this.timeConvertMinToPercentage(sumMinutes);
      if (re == "0.00") {
        return "";
      }
      return re;
    },

    //合計分を取得（行単位合計）
    getSumTimeRow(item) {
      //0~95
      if (this.timeChartEdit) {
        return "";
      }
      let sumMinutes = this.getSumTimeMinutes(item, 0, 95);
      let re = this.timeConvertMinToPercentage(sumMinutes);
      if (re == "0.00") {
        return "";
      }
      return re;
    },
    //インデックス範囲の合計分を取得（全勤務行合計）
    getSumTimeTimeZoneAll(fromIndex, endIndex) {
      if (this.timeChartEdit) {
        return "";
      }
      let lo = this;
      let sumMinutes = 0;
      this.dailyReportData.workContentList.forEach((elsement) => {
        if (elsement.type != lo.WORK_TYPE_BREAK) {
          sumMinutes += lo.getSumTimeMinutes(elsement.timeData, fromIndex, endIndex);
        }
      });
      let re = this.timeConvertMinToPercentage(sumMinutes);
      return re;
    },
    //--------------------------------
    // 手当変更時
    //--------------------------------
    // 職長手当変更時
    togleForeman: function (item) {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      if (item.isForeman == this.VALID) {
        item.isForeman = 0;
      } else {
        item.isForeman = 1;
      }
      this.updateDailyReport();
    },
    // 送迎（往）変更時
    togleTransfer1: function (item) {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      if (item.isTransferGo == this.VALID) {
        item.isTransferGo = 0;
      } else {
        item.isTransferGo = 1;
      }
      this.updateDailyReport();
    },
    // 送迎（複）手当変更時
    togleTransfer2: function (item) {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      if (item.isTransferBack == this.VALID) {
        item.isTransferBack = 0;
      } else {
        item.isTransferBack = 1;
      }
      this.updateDailyReport();
    },
    // 無勤変更時
    togleAbsence: function (item) {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      if (item.isAbsence == this.VALID) {
        item.isAbsence = 0;
      } else {
        this.$confirm("データをクリアして、無勤に設定しますか。", "", {
          confirmButtonText: "OK",
          cancelButtonText: "キャンセル",
        })
          .then(() => {
            //クリアと無勤有効化
            this.setValidAbsence();
          })
          .catch(() => {});
      }
    },
    //クリアと無勤有効化
    setValidAbsence: function () {
      let me = this;
      this.putData(config.URL.DAILY_REPORT, "/validAbsence", this.dailyReportData)
        .then((response) => {
          if (response.result) {
            //
          } else {
            me.showErrorMsg(response.detail.error, "無勤設定");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.getDailyReport();
        });
    },
    //勤務者人数 UPボタン
    upWorkerCount: function () {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      if (this.isNullOrEmpty(this.dailyReportData.workerCount)) {
        this.dailyReportData.workerCount = 1;
      } else {
        this.dailyReportData.workerCount = parseInt(this.dailyReportData.workerCount, 10) + 1;
      }
      this.updateDailyReport();
    },
    //勤務者人数 dDOWNボタン
    downWorkerCount: function () {
      if (!this.isWorkerEditMode) {
        // 編集不可モード
        return;
      }
      if (this.isNullOrEmpty(this.dailyReportData.workerCount)) {
        this.dailyReportData.workerCount = 0;
      } else if (this.dailyReportData.workerCount > 0) {
        this.dailyReportData.workerCount = parseInt(this.dailyReportData.workerCount, 10) - 1;
      }
      this.updateDailyReport();
    },
    //--------------------------------
    //--------------------------------
    // 区分ごとの背景色を設定
    getTypeClass: function (item) {
      let className = "";
      if (item == this.WORK_TYPE_CONSTRUCTION) {
        className += "workTypeConstruction";
      } else if (item == this.WORK_TYPE_AUXILIARY) {
        className += "workTypeAncillary";
      } else if (item == this.WORK_TYPE_DRIVE) {
        className += "workTypeDriving";
      }
      return className;
    },
    // モード（削除・編集）別レイアウト変更クラス
    getModeClassForWorkerName: function () {
      let re = "workStateWorkername ";
      if (this.isWorkerEditMode) {
        re += " bgEditMode";

        if (this.isDeleteMode) {
          re += " bgDeleteMode";
        }
      }

      return re;
    },
    //勤務時間ヘッダーレイアウトクラス(0～23)
    getWorkTimeHeader: function (timeHour) {
      let re = "";
      if (timeHour <= 4) {
        re += " midnightBackcolor ";
      } else if (timeHour >= 22) {
        re += " midnightBackcolor ";
      }
      if (timeHour == 23) {
        re += " Nken_BorderRightBold ";
      }
      return re;
    },
  },
};
</script>

<style>
/* コンテキストメニュー */
.ctx-menu {
  min-width: 180px;
}

.btnEditEnd {
  font-size: 0.7rem;
  padding: 5px;
}

input.partnerCompanyWorkerCount {
  font-size: 0.75rem;
  height: 100%;
  width: 60px;
  padding: 1px;
}

.btnEditWorkerCont {
  line-height: 28px;
  height: 28px;
  width: 20px;
}
</style>
