<template>
  <div id="home" class="main" style>
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="共有スケジュール"></BaseHeader>
    </header>
    <main>
      <div class="main_area">
        <b-container fluid>
          <div class="p-2">
            <div>
              <div>
                <b-card
                  header-bg-variant="primary"
                  header-text-variant="white"
                  class="baseCardArea scheduleArea border-0"
                >
                  <div>
                    <div>
                      <b-button-group>
                        <b-button @click="beforeWeek">＜＜</b-button>
                        <b-button @click="nextWeek">＞＞</b-button>
                      </b-button-group>
                      <b-button class="ml-1" @click="nowWeek">今週と翌週</b-button>
                    </div>
                    <div class="mt-2">
                      <b-table-simple bordered>
                        <!-- 曜日 -->
                        <b-tr>
                          <b-th>曜</b-th>
                          <b-th class="thDay sundayColor">日</b-th>
                          <b-th class="thDay">月</b-th>
                          <b-th class="thDay">火</b-th>
                          <b-th class="thDay">水</b-th>
                          <b-th class="thDay">木</b-th>
                          <b-th class="thDay">金</b-th>
                          <b-th class="thDay saturdayColor">土</b-th>
                        </b-tr>
                        <!-- （１）日付 -->
                        <b-tr v-if="showDateList.length">
                          <b-th style="width: 2rem">日</b-th>
                          <b-th class="thDay sundayColor">
                            {{
                            showDateList[0].day
                            }}
                          </b-th>
                          <b-th class="thDay">{{ showDateList[1].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[2].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[3].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[4].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[5].day }}</b-th>
                          <b-th class="thDay saturdayColor">
                            {{
                            showDateList[6].day
                            }}
                          </b-th>
                        </b-tr>
                        <!-- （１）スケジュール内容 -->
                        <b-tr>
                          <b-th class="thContent align-top"></b-th>
                          <b-td
                            :class="
                              item.isToday
                                ? 'todayBackColor align-top'
                                : 'align-top'
                            "
                            v-for="item in showDateList1"
                            :key="item.targetDate"
                          >
                            <div class="scheduleContent">{{ item.content }}</div>
                          </b-td>
                        </b-tr>
                        <!-- （２）日付 -->
                        <b-tr v-if="showDateList.length">
                          <b-th style="width: 2rem">日</b-th>
                          <b-th class="thDay sundayColor">
                            {{
                            showDateList[7].day
                            }}
                          </b-th>
                          <b-th class="thDay">{{ showDateList[8].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[9].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[10].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[11].day }}</b-th>
                          <b-th class="thDay">{{ showDateList[12].day }}</b-th>
                          <b-th class="thDay saturdayColor">
                            {{
                            showDateList[13].day
                            }}
                          </b-th>
                        </b-tr>
                        <!-- （２）スケジュール内容 -->
                        <b-tr>
                          <b-th class="thContent align-top"></b-th>
                          <b-td
                            :class="
                              item.isToday
                                ? 'todayBackColor align-top'
                                : 'align-top'
                            "
                            v-for="item in showDateList2"
                            :key="item.targetDate"
                          >
                            <div class="scheduleContent">{{ item.content }}</div>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </main>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader";
import moment from "moment";
import config from "../const/config";

export default {
  name: "Home",
  components: {
    BaseHeader,
  },
  data() {
    return {
      startDate: "",
      baseDateList: [],
      showDateList: [],
    };
  },
  computed: {
    showDateList1: function () {
      if (this.showDateList != null) {
        return this.showDateList.slice(0, 7);
      } else {
        return [];
      }
    },
    showDateList2: function () {
      if (this.showDateList != null) {
        return this.showDateList.slice(7, 14);
      } else {
        return [];
      }
    },
  },
  watch: {
    // 週変更時
    startDate: function () {
      this.createList();
    },
  },
  mounted: function () {
    // 週初日（日曜始まり, YYYY-MM-DD）を取得
    this.startDate = this.getStartWeek(moment());
  },
  methods: {
    nowWeek: function () {
      this.startDate = this.getStartWeek(moment());
    },
    // 次週へ変更
    nextWeek: function () {
      this.startDate = moment(this.startDate)
        .add(14, "days")
        .format("YYYY-MM-DD");
    },
    // 前週へ変更
    beforeWeek: function () {
      this.startDate = moment(this.startDate)
        .add(-14, "days")
        .format("YYYY-MM-DD");
    },
    createList: function () {
      this.baseDateList = [];
      // 本日（YYYY-MM-DD）を取得
      let today = this.getStrDateHyphen(moment());
      for (let i = 1; i <= 14; i++) {
        // 1週間分の空データ生成
        let dateValue = moment(this.startDate).add(i - 1, "d");
        this.baseDateList.push(this.initData(dateValue, today));
      }
      this.getSchedule();
    },
    // 日要素データ生成
    initData: function (dateValue, todayValue) {
      let setWeekDay = moment(dateValue).isoWeekday();
      let isToday = false;
      if (todayValue == this.getStrDateHyphen(dateValue)) {
        isToday = true;
      }
      return {
        date: this.getStrDateHyphen(dateValue),
        day: this.getStrMd(dateValue),
        weekDay: setWeekDay,
        hasSchedule: false,
        content: "",
        isToday: isToday,
      };
    },
    /**
     * スケジュール情報の取得（一週間分）
     */
    getSchedule: function () {
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      //
      let lo = this;
      // --スケジュールをサーバから取得
      //今週末取得
      let endTargetDate = moment(this.startDate)
        .add(13, "days")
        .format("YYYY-MM-DD");
      // パラメータ作成
      let param = {
        start_date: this.startDate,
        end_date: endTargetDate,
      };
      // リクエスト
      this.getWithParams(config.URL.SCHEDULE, param)
        .then((response) => {
          if (response.result) {
            lo.createShowDate(response.container);
          }
        })
        .catch((e) => {
          lo.showServerError(e);
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    createShowDate: function (scheduleList) {
      //-- スケジュール情報を表示データにセット
      let setDateList = []; //表示用リスト

      this.baseDateList.forEach(function (element) {
        let data = scheduleList.filter(
          (el) => el.schedule_date == element.date
        );
        if (data && data.length > 0) {
          //データあり
          let addDate = { ...element };
          addDate.hasSchedule = true;
          let content = "";
          data.forEach(function(sItem){
            if(content != ""){
              content += "\r\n\r\n";
            }
            content += sItem.content
          })
          addDate.content = content;
          setDateList.push(addDate);
        } else {
          //データ無し
          setDateList.push({ ...element });
        }
      });
      this.showDateList = setDateList;
    },
  },
};
</script>

<style>
#home .scheduleArea .thDay {
  width: 190px;
}

#home .scheduleArea .thContent,
#home .scheduleArea .tdContent {
  height: 200px;
  overflow: hidden;
  word-break: break-all;
}

.scheduleArea table .scheduleContent {
  height: 280px;
  min-height: 280px;
  overflow-y: auto;
  white-space: pre-wrap;
}
</style>
