<template>
  <div class="Nken_SideMenu">
    <!-- タイトルエリア -->
    <div class="Nken_SideMenu__SystemTitleArea">
      <div class="Nken_SystemTitle__CompanyName">中村建設</div>
      <div class="Nken_SystemTitle__SystemName">日報システム</div>
    </div>

    <!--  -->
    <div class="Nken_SideMenu__LinkBtnArea">
      <SideMenuBtn
        propBtnText="共有スケジュール"
        propsLinkPagePath="/Home"
        propBtnIcon="calendar3"
        propBtnIconVariant="primary"
        :propsRelatedPages="['/Home']"></SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="スケジュール入力"
        propsLinkPagePath="/Schedule"
        propBtnIcon="calendar3"
        propBtnIconVariant="primary"
        :propsRelatedPages="['/Schedule']">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="日報入力"
        propsLinkPagePath="/WorkBase"
        propBtnIcon="journals"
        propBtnIconVariant="success"
        :propsRelatedPages="['/WorkRegist', '/WorkBase']"
        :propAuth="userInfo.authorityDailyReport"
        @clickLinkBtn="clickWorkBase()"></SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="日報確認"
        propsLinkPagePath="/WorkReference"
        propBtnIcon="journals"
        propBtnIconVariant="success"
        :propsRelatedPages="['/WorkReference']">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="有給入力"
        propsLinkPagePath="/PaidEdit"
        propBtnIcon="calendar2-check"
        propBtnIconVariant="success"
        :propsRelatedPages="['/PaidEdit']"
        :propAuth="userInfo.authorityPaidEdit">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="有給確認"
        propsLinkPagePath="/Paid"
        propBtnIcon="calendar2-check"
        propBtnIconVariant="success"
        :propsRelatedPages="['/Paid']">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="共有フォルダ"
        propsLinkPagePath="/SharedFolder"
        propBtnIcon="folder-check"
        propBtnIconVariant="info"
        :propsRelatedPages="['/SharedFolder']"
        :propAuth="userInfo.authoritySharedFolder">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="データ移出"
        propsLinkPagePath="/DataExport"
        propBtnIcon="box-arrow-right"
        propBtnIconVariant="dark"
        :propsRelatedPages="['/DataExport']"
        :propAuth="userInfo.authorityOutput">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="日報データ移出"
        propsLinkPagePath="/DailyWorkAndPaidCsvExport"
        propBtnIcon="box-arrow-right"
        propBtnIconVariant="dark"
        :propsRelatedPages="['/DailyWorkAndPaidCsvExport']"
        :propAuth="userInfo.authorityOutput">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="データ移入"
        propsLinkPagePath="/DataImport"
        propBtnIcon="box-arrow-in-left"
        propBtnIconVariant="dark"
        :propsRelatedPages="['/DataImport']"
        :propAuth="userInfo.authorityInput">
      </SideMenuBtn>
      <!--  -->
      <SideMenuBtn
        propBtnText="特殊設定"
        propsLinkPagePath="/Setting"
        propBtnIcon="tools"
        propBtnIconVariant="dark"
        :propsRelatedPages="['/Setting']"
        :propAuth="userInfo.authorityAdmin">
      </SideMenuBtn>
      <!--  -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SideMenuBtn from "./SideMenuButton.vue";

export default {
  name: "SideMenu",
  components: { SideMenuBtn },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    clickWorkBase: function () {
      //有給入力画面を、初期状態で表示する為、KEYをクリア
      this.$store.commit("setWorkRegistKey", {
        construction_cd: null,
        work_date: null,
      });
    },
  },
};
</script>

<style>
.Nken_SideMenu {
  height: 100%;
  width: 190px;
  background-color: #ffffff;
  border-right: 1px solid rgb(221, 221, 221);
}

.Nken_SideMenu__LinkBtnArea {
  padding-top: 1.5rem;
}

.Nken_SideMenu__SystemTitleArea {
  background: var(--mainColor) !important;
  color: white !important;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.Nken_SystemTitle__CompanyName {
  font-size: 1.75rem;
  font-weight: 600;
}

.Nken_SystemTitle__SystemName {
  font-size: 0.85rem;
  font-weight: 600;
}
</style>
