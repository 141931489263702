<template>
  <b-modal v-model="isModalShow" size :centered="true" hide-footer header-class="modal_header">
    <!-- モーダルヘッダー -->
    <template v-slot:modal-header>
      <h5 class="modal-title py-0">前回作業日コピー</h5>
      <b-button class="close" @click="hideModal" aria-label="閉じる">
        <span aria-hidden="true">×</span>
      </b-button>
    </template>
    <!-- モーダルボディ -->
    <template v-slot:default>
      <div class="px-4 text-center">
        <div>
          <h5 class="subTitle">直近の現場作業日</h5>
        </div>
        <div class="mt-3">
          <b-radio-group
            stacked
            buttons
            button-variant="outline-secondary"
            class="w-75"
            v-model="selectDate"
            :options="copyBaseList"
          ></b-radio-group>
        </div>
        <div class="text-center mt-4">
          <b-button class="px-5" variant="success" @click="handleOk">OK</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */

import moment from "moment";

export default {
  name: "CopyWorkModal",
  props: ["propDateList"],
  data: function () {
    return {
      // モーダル表示状態
      isModalShow: false,
      targetDate: null,
      selectDate: null,
      copyBaseList: [],
    };
  },
  computed: {},
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        this.selectDate = null;
        this.setDateList();
      } else {
        // モーダル閉じるとき
      }
    },
  },
  methods: {
    setDateList: function () {
      let me = this;
      this.copyBaseList = [];
      if (this.propDateList != null) {
        this.propDateList.forEach((element) => {
          me.copyBaseList.push({
            value: element.targetDate,
            text: element.strTargetDate,
          });
        });
      }
    },
    //表示する
    showModal: function () {
      this.isModalShow = true;
    },
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.isModalShow = false;
      // this.$root.$emit("bv::hide::modal", "copy-work-modal", "");
    },
    handleOk: function () {
      this.$emit("parent-event", this.selectDate);
      this.hideModal();
    },
  },
};
</script>

<style>
.subTitle {
  color: rgb(75, 82, 75);
}
</style>