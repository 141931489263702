<template>
  <div>
    <b-modal
      id="edit-folder-modal"
      size="lg"
      :centered="true"
      hide-footer
      header-class="modal_header"
      :no-close-on-backdrop="false"
      v-model="isModalShow"
    >
      <!-- モーダルヘッダー -->
      <template v-slot:modal-header>
        <h5 class="modal-title py-0">フォルダ編集・削除</h5>
        <b-button class="close" @click="hideModal" aria-label="閉じる">
          <span aria-hidden="true">×</span>
        </b-button>
      </template>
      <!-- モーダルボディ -->
      <template v-slot:default>
        <div class="px-5">
          <div>
            <b-form-group label="対象フォルダ名">
              <b-input readonly type="text" v-model="targetFolderName"></b-input>
            </b-form-group>
            <b-form-group label="変更フォルダ名">
              <b-input type="text" autocomplete="off" v-model="folderName"></b-input>
            </b-form-group>
          </div>
          <div class="mt-5">
            <b-row :no-gutters="true">
              <b-col cols="6">
                <b-button @click="handleDelete" variant="danger">フォルダ削除</b-button>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-button @click="handleUpdate" variant="success">更新</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </b-modal>
    <Confirm
      ref="confirmDeleteFolder"
      title="フォルダを削除します。"
      :message="confirmMsgDelete"
      focusCancel="true"
      @parent-ok-event="deleteFolder()"
    ></Confirm>
  </div>
</template>

<script>
import config from "../../const/config";
import Confirm from "../Confirm";
export default {
  name: "EditFolderModal",
  components: { Confirm },
  props: { folderInfo: { type: Object } },
  data: function () {
    return {
      // モーダル表示状態
      isModalShow: false,
      targetFolderName: null,
      //フォルダ名
      folderName: null,
      confirmMsgDelete: "",
    };
  },
  watch: {
    isModalShow: function () {
      if (this.isModalShow) {
        this.folderName = null;
        this.targetFolderName = this.folderInfo.folderName;
      } else {
        // モーダル閉じるとき
        this.folderName = null;
        this.targetFolderName = null;
      }
    },
  },
  methods: {
    // ---閉じる(×)ボタン押下時(モーダル)
    hideModal: function () {
      this.isModalShow = false;
    },
    //削除ボタン押下
    handleDelete: function () {
    this.confirmMsgDelete = "・対象フォルダ \n" + this.targetFolderName;
    this.$refs.confirmDeleteFolder.openDialog();
    },
    //更新ボタン押下
    handleUpdate: function () {
      if (!this.isValid()) {
        return;
      }
      let msg = "";
      msg += "・変更前 \n" + this.targetFolderName + " \n ";
      msg += "・変更後 \n" + this.folderName + " \n ";
      this.$confirm(msg, "フォルダ名を更新します。", {
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
      })
        .then((result) => {
          if (result) {
            //フォルダ名更新
            this.updateFolderName();
          }
        })
        .catch(() => {});
    },
    //フォルダの削除
    deleteFolder: function () {
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.deleteData(config.URL.SHARED_FOLDER, this.folderInfo.folderId)
        .then((response) => {
          if (response.result) {
            //成功
            me.successDeleteFolder();
          } else {
            //失敗
            me.showErrorAlert(response.detail.error, "フォルダ削除");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //フォルダ削除成功
    successDeleteFolder: function () {
      this.showUpdateSuccess("成功しました。", "フォルダ削除");
      this.$emit("parent-event");
      this.hideModal();
    },
    // フォルダ名更新
    updateFolderName: function () {
      // パラメータ（フォルダIDと変更名）作成
      let req = {
        folderId: this.folderInfo.folderId,
        folderName: this.folderName,
      };
      // リクエスト
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.putData(config.URL.SHARED_FOLDER, "", req)
        .then((response) => {
          if (response.result) {
            //成功
            me.successUpdateFolderName();
          } else {
            //失敗
            me.showErrorAlert(response.detail.error, "フォルダ名更新");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //フォルダ名更新成功
    successUpdateFolderName: function () {
      this.showUpdateSuccess("成功しました。", "フォルダ名更新");
      this.$emit("parent-event");
      this.hideModal();
    },
    // リクエストの有効性判定
    isValid: function () {
      if (this.isNullOrEmpty(this.folderName)) {
        this.showErrorAlert("フォルダ名が入力されていません。", "フォルダ作成");
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
#worker-select-modal .workerSelectTable {
  max-height: 450px;
  font-size: 14px;
}

.el-message-box__message {
  white-space: pre-line;
  word-break: break-all;
}
</style>