<template>
  <div id="setting" class="main" style>
    <header class="sticky-top">
      <BaseHeader propHeaderTitle="特殊設定"></BaseHeader>
    </header>
    <main class="main_area">
      <b-container fluid>
        <div class="m-4">
          <b-tabs>
            <b-tab title="システム運用制御" active>
              <!-- <b-card
                header-bg-variant="primary"
                header-text-variant="white"
                header="システム運用制御"
                class="baseCardArea w-75 mt-3"
              >-->
              <div class="p-3">
                <div>
                  <b-row>
                    <b-col cols="6">
                      <b-table-simple bordered>
                        <b-tr>
                          <b-th colspan="2" class="themeColoreHeader">職員 締処理</b-th>
                        </b-tr>
                        <b-tr>
                          <b-th>勤怠年月</b-th>
                          <b-td>{{opeControlerData.staff_year_month}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>日報期間</b-th>
                          <b-td>{{opeControlerData.staff_daily_report_from}} ~ {{opeControlerData.staff_daily_report_to}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>処理状態</b-th>
                          <b-td>
                            <b-radio-group
                              v-model="opeControlerData.staff_closing_state"
                              :options="options"
                            ></b-radio-group>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                    <b-col cols="5">
                      <b-table-simple bordered>
                        <b-tr>
                          <b-th colspan="2" class="themeColoreHeader">日報入力 シート設定</b-th>
                        </b-tr>

                        <b-tr>
                          <b-th>日給シート</b-th>
                          <b-td>
                            <b-radio-group
                              v-model="opeControlerData.dailySalarySheetUse"
                              :options="useOptions"
                            ></b-radio-group>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                    <b-col cols="6">
                      <b-table-simple bordered>
                        <b-tr>
                          <b-th colspan="2" class="themeColoreHeader">作業員 締処理</b-th>
                        </b-tr>
                        <b-tr>
                          <b-th>勤怠年月</b-th>
                          <b-td>{{opeControlerData.fieldworker_year_month}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>日報期間</b-th>
                          <b-td>{{opeControlerData.fieldworker_daily_report_from}} ~ {{opeControlerData.fieldworker_daily_report_to}}</b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>処理状態</b-th>
                          <b-td>
                            <b-radio-group
                              v-model="opeControlerData.fieldworker_closing_state"
                              :options="options"
                            ></b-radio-group>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </div>
                <div>
                  <b-button variant="success" @click="updateOpeControl">確定登録</b-button>
                  <b-button class="ml-2" @click="getOpeControlData">リセット</b-button>
                </div>
              </div>
              <!-- </b-card> -->
            </b-tab>
            <b-tab title="勤務時間初期設定">
              <div class="p-4">
                <div>
                  <b-table-simple small bordered>
                    <b-tr>
                      <b-th class="themeColoreHeader p-2" colspan="98">勤務時間初期設定値（事業所勤務者）</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th></b-th>
                      <!-- 時刻 -->
                      <b-th colspan="4" class="midnightBackcolor">0</b-th>
                      <b-th colspan="4" class="midnightBackcolor">1</b-th>
                      <b-th colspan="4" class="midnightBackcolor">2</b-th>
                      <b-th colspan="4" class="midnightBackcolor">3</b-th>
                      <b-th colspan="4" class="midnightBackcolor">4</b-th>
                      <b-th colspan="4" class="thBaseTime">5</b-th>
                      <b-th colspan="4" class="thBaseTime">6</b-th>
                      <b-th colspan="4" class="thBaseTime">7</b-th>
                      <b-th colspan="4" class="thBaseTime">8</b-th>
                      <b-th colspan="4" class="thBaseTime">9</b-th>
                      <b-th colspan="4" class="thBaseTime">10</b-th>
                      <b-th colspan="4" class="thBaseTime">11</b-th>
                      <b-th colspan="4" class="thBaseTime">12</b-th>
                      <b-th colspan="4" class="thBaseTime">13</b-th>
                      <b-th colspan="4" class="thBaseTime">14</b-th>
                      <b-th colspan="4" class="thBaseTime">15</b-th>
                      <b-th colspan="4" class="thBaseTime">16</b-th>
                      <b-th colspan="4" class="thBaseTime">17</b-th>
                      <b-th colspan="4" class="thBaseTime">18</b-th>
                      <b-th colspan="4" class="thBaseTime">19</b-th>
                      <b-th colspan="4" class="thBaseTime">20</b-th>
                      <b-th colspan="4" class="thBaseTime">21</b-th>
                      <b-th colspan="4" class="midnightBackcolor">22</b-th>
                      <b-th colspan="4" class="midnightBackcolor">23</b-th>
                      <b-th class="text-center">時間計</b-th>
                    </b-tr>
                    <!-- 事業所勤務者：就業時間行 -->
                    <b-tr>
                      <b-td class="workTimeTypeName text-center">就業</b-td>
                      <b-td
                        v-for="(item,index) in officeWorkerWorkTime.timeData"
                        :key="index"
                        :class="getTimeDataClass(true,item)"
                        @mousedown="tdClick(officeWorkerWorkTime.timeData,officeWorkerBreakTime.timeData,index)"
                        @mouseup="tdMousuup"
                        @mouseover="tdMousover(officeWorkerWorkTime.timeData,index)"
                      ></b-td>
                      <b-td
                        v-show="officeWorkerWorkTime.timeData == null"
                        colspan="96"
                        class="text-center"
                      ></b-td>
                      <b-td
                        class="tdSumTime workTimeBackcolor"
                      >{{getSumTime(officeWorkerWorkTime.timeData)}}</b-td>
                    </b-tr>
                    <!-- 事業所勤務者：休憩時間 -->
                    <b-tr>
                      <b-td class="workTimeTypeName text-center">休憩</b-td>
                      <b-td
                        v-for="(item,index) in officeWorkerBreakTime.timeData"
                        :key="index"
                        :class="getTimeDataClass(false,item)"
                        @mousedown="tdClick(officeWorkerBreakTime.timeData,officeWorkerWorkTime.timeData,index)"
                        @mouseup="tdMousuup"
                        @mouseover="tdMousover(officeWorkerBreakTime.timeData,index)"
                      ></b-td>
                      <b-td
                        v-show="officeWorkerBreakTime.timeData == null"
                        colspan="96"
                        class="text-center"
                      ></b-td>
                      <b-td
                        class="tdSumTime breakTimeBackcolor"
                      >{{getSumTime(officeWorkerBreakTime.timeData)}}</b-td>
                    </b-tr>
                  </b-table-simple>
                </div>
                <div>
                  <b-table-simple small bordered>
                    <b-tr>
                      <b-th class="themeColoreHeader p-2" colspan="98">勤務時間初期設定値（現場勤務者）</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th></b-th>
                      <!-- 時刻 -->
                      <b-th colspan="4" class="midnightBackcolor">0</b-th>
                      <b-th colspan="4" class="midnightBackcolor">1</b-th>
                      <b-th colspan="4" class="midnightBackcolor">2</b-th>
                      <b-th colspan="4" class="midnightBackcolor">3</b-th>
                      <b-th colspan="4" class="midnightBackcolor">4</b-th>
                      <b-th colspan="4" class="thBaseTime">5</b-th>
                      <b-th colspan="4" class="thBaseTime">6</b-th>
                      <b-th colspan="4" class="thBaseTime">7</b-th>
                      <b-th colspan="4" class="thBaseTime">8</b-th>
                      <b-th colspan="4" class="thBaseTime">9</b-th>
                      <b-th colspan="4" class="thBaseTime">10</b-th>
                      <b-th colspan="4" class="thBaseTime">11</b-th>
                      <b-th colspan="4" class="thBaseTime">12</b-th>
                      <b-th colspan="4" class="thBaseTime">13</b-th>
                      <b-th colspan="4" class="thBaseTime">14</b-th>
                      <b-th colspan="4" class="thBaseTime">15</b-th>
                      <b-th colspan="4" class="thBaseTime">16</b-th>
                      <b-th colspan="4" class="thBaseTime">17</b-th>
                      <b-th colspan="4" class="thBaseTime">18</b-th>
                      <b-th colspan="4" class="thBaseTime">19</b-th>
                      <b-th colspan="4" class="thBaseTime">20</b-th>
                      <b-th colspan="4" class="thBaseTime">21</b-th>
                      <b-th colspan="4" class="midnightBackcolor">22</b-th>
                      <b-th colspan="4" class="midnightBackcolor">23</b-th>
                      <b-th class="text-center">時間計</b-th>
                    </b-tr>
                    <!-- 現場勤務者：勤務時間チャート -->
                    <b-tr>
                      <b-td class="workTimeTypeName text-center">就業</b-td>
                      <b-td
                        v-for="(item,index) in constructionWorkerWorkTime.timeData"
                        :key="index"
                        :class="getTimeDataClass(true,item)"
                        @mousedown="tdClick(constructionWorkerWorkTime.timeData,constructionWorkerBreakTime.timeData,index)"
                        @mouseup="tdMousuup"
                        @mouseover="tdMousover(constructionWorkerWorkTime.timeData,index)"
                      ></b-td>
                      <b-td
                        v-show="constructionWorkerWorkTime.timeData == null"
                        colspan="96"
                        class="text-center"
                      ></b-td>
                      <b-td
                        class="tdSumTime workTimeBackcolor"
                      >{{getSumTime(constructionWorkerWorkTime.timeData)}}</b-td>
                    </b-tr>
                    <!-- 現場勤務者：休憩チャート -->
                    <b-tr>
                      <b-td class="workTimeTypeName text-center">休憩</b-td>
                      <b-td
                        v-for="(item,index) in constructionWorkerBreakTime.timeData"
                        :key="index"
                        :class="getTimeDataClass(false,item)"
                        @mousedown="tdClick(constructionWorkerBreakTime.timeData,constructionWorkerWorkTime.timeData,index)"
                        @mouseup="tdMousuup"
                        @mouseover="tdMousover(constructionWorkerBreakTime.timeData,index)"
                      ></b-td>
                      <b-td
                        v-show="constructionWorkerBreakTime.timeData == null"
                        colspan="96"
                        class="text-center"
                      ></b-td>
                      <b-td
                        class="tdSumTime breakTimeBackcolor"
                      >{{getSumTime(constructionWorkerBreakTime.timeData)}}</b-td>
                    </b-tr>
                  </b-table-simple>
                </div>
                <div>
                  <b-button variant="success" @click="updateBaseWorkTime">確定登録</b-button>
                  <b-button class="ml-2" @click="getBaseWorkTime">リセット</b-button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
// import store from "../store";
import BaseHeader from "../components/BaseHeader";
import moment from "moment";
import config from "../const/config";

export default {
  name: "setting",
  components: {
    BaseHeader,
  },
  data() {
    return {
      selectUse: 1,
      useOptions: [
        { value: 1, text: "使用する" },
        { value: 0, text: "使用しない" },
      ],
      options: [
        { value: 0, text: "日報入力可" },
        { value: 9, text: "日報締処理中" },
      ],
      staffProcessingState: 0,
      workerProcessingState: 0,
      opeControlerData: this.getInitOpeControlData(),
      //--勤務時間初期設定
      //勤務基本時間
      workTime: null,
      //事務所
      officeWorkerWorkTime: { timeData: null },
      officeWorkerBreakTime: { timeData: null },
      //現場
      constructionWorkerWorkTime: { timeData: null },
      constructionWorkerBreakTime: { timeData: null },
      //
      timeChartEdit: false,
      targetDataList: null,
      notTargetDataList: null,
      startAreaKey: null,
      endAreaKey: null,
    };
  },
  mounted: function () {
    this.getBaseWorkTime();
    this.getOpeControlData();
  },
  methods: {
    //----システム運用制御
    getInitOpeControlData: function () {
      return {
        staff_closing_state: null,
        staff_year_month: "",
        staff_daily_report_from: "",
        staff_daily_report_to: "",
        fieldworker_closing_state: "",
        fieldworker_year_month: "",
        fieldworker_daily_report_from: "",
        fieldworker_daily_report_to: "",
        dailySalarySheetUse: "",
      };
    },
    getOpeControlData: function () {
      this.getInitOpeControlData();
      let me = this;
      this.getDataList(config.URL.SETTING + config.PROCESS.GET_OPE_CONTROL)
        .then((response) => {
          if (response.result) {
            // console.log(response.container);
            me.opeControlerData = response.container;
          } else {
            me.showErrorMsg(response.detail.error, "システム運用情報取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    updateOpeControl: function () {
      // リクエスト
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(
        config.URL.SETTING + config.PROCESS.UPDATE_OPE_CONTROL,
        this.opeControlerData
      )
        .then((response) => {
          if (response.result) {
            // console.log(response.container);
            me.showUpdateSuccess();
          } else {
            //TODO error発生
            me.showErrorMsg(response.detail.error, "登録・更新処理");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
    //----勤務時間初期設定
    initBaseWorkTimeData: function () {
      this.workTime = null;
      let initTime = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      //事務所
      this.officeWorkerWorkTime = { timeData: initTime };
      this.officeWorkerBreakTime = { timeData: initTime };
      //現場
      this.constructionWorkerWorkTime = { timeData: initTime };
      this.constructionWorkerBreakTime = { timeData: initTime };
    },
    deleteBlock: function (index) {
      let i = this.targetDataList[index];
      if (i == 0) {
        return;
      }
      let beforeIndex = index;
      let afterIndex = index + 1;

      for (beforeIndex; beforeIndex >= 0; beforeIndex--) {
        if (this.targetDataList[beforeIndex] != 1) {
          break;
        }
        this.$set(this.targetDataList, beforeIndex, 0);
      }
      for (afterIndex; afterIndex <= 95; afterIndex++) {
        if (this.targetDataList[afterIndex] != 1) {
          break;
        }
        this.$set(this.targetDataList, afterIndex, 0);
      }
    },
    updateReqTarget: function (index) {
      this.$set(this.targetDataList, index, "1");
      if (this.notTargetDataList[index] == "1") {
        this.$set(this.notTargetDataList, index, "0");
      }
    },
    rangeUpdateReqTarget: function () {
      if (this.startAreaKey != null && this.endAreaKey != null) {
        let start = this.startAreaKey;
        let end = this.endAreaKey;
        if (start > end) {
          start = this.endAreaKey;
          end = this.startAreaKey;
        }
        for (start; start <= end; start++) {
          this.updateReqTarget(start);
        }
      }
    },
    // --勤務時間チャート変更操作(クリック時)
    tdClick: function (targetTimeData, notTargetTimeData, timeIndex) {
      // 対象とする区分行情報保持
      this.targetDataList = targetTimeData;
      //対象としない区分の情報保持（後勝で打ち消すため）
      this.notTargetDataList = notTargetTimeData;

      if (this.targetDataList[timeIndex] == "1") {
        // 削除モード
        this.deleteBlock(timeIndex);
        this.targetDataList = null;
        this.notTargetDataList = null;
        return;
      }

      //クリック地点から、選択有効化
      this.timeChartEdit = true;
      this.startAreaKey = timeIndex;
      this.endAreaKey = timeIndex;
      this.rangeUpdateReqTarget();
    },
    // --勤務時間チャート変更操作（離す時）
    tdMousuup: function () {
      //選択無効化
      this.timeChartEdit = false;
      this.rangeUpdateReqTarget();

      this.startAreaKey = null;
      this.endAreaKey = null;
      this.targetDataList = null;
      this.notTargetDataList = null;
    },
    // --勤務時間チャート変更操作
    tdMousover: function (targetTimeData, timeIndex) {
      if (!this.timeChartEdit) {
        return;
      }
      if (this.targetDataList != targetTimeData) {
        return;
      }
      // --タイムチャート変更可能の場合
      this.endAreaKey = timeIndex;
      this.rangeUpdateReqTarget();
    },
    // 勤務基本時間を取得する
    getBaseWorkTime: function () {
      this.initBaseWorkTimeData();
      let me = this;
      this.getDataList(config.URL.SETTING + config.PROCESS.GET_BASE_WORK_TIME)
        .then((response) => {
          if (response.result) {
            me.workTime = response.container;
            me.setAssignWorkTimeData();
          } else {
            me.showErrorMsg(response.detail.error, "勤務基本時間取得");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        });
    },
    // 取得した勤務基本時間を描画データに割り当てる
    setAssignWorkTimeData: function () {
      if (this.workTime == null) {
        this.officeWorkerWorkTime = null;
        this.officeWorkerBreakTime = null;
        this.constructionWorkerWorkTime = null;
        this.constructionWorkerBreakTime = null;
        return;
      }
      let me = this;
      this.workTime.forEach((element) => {
        if (element.catergory_m_cd == config.VALUE.CONSTRUCTION_TYPE_OFFICE) {
          //事業所勤務者
          if (element.catergory_s_cd == config.VALUE.CATEGORY_WORK_TIME) {
            //勤務
            me.officeWorkerWorkTime = element;
          } else {
            //休憩
            me.officeWorkerBreakTime = element;
          }
        } else if (
          element.catergory_m_cd == config.VALUE.CONSTRUCTION_TYPE_FIELD
        ) {
          //現場勤務者
          if (element.catergory_s_cd == config.VALUE.CATEGORY_WORK_TIME) {
            //勤務
            me.constructionWorkerWorkTime = element;
          } else {
            //休憩
            me.constructionWorkerBreakTime = element;
          }
        }
      });
    },
    getSumTime: function (timeData) {
      let sumMinutes = this.getSumTimeMinutes(timeData);
      let re = this.timeConvertMinToPercentage(sumMinutes);
      if (re == "0.00") {
        return "";
      }
      return re;
    },
    // インデックス範囲の合計分を取得（行単位合計）
    getSumTimeMinutes: function (timeDataList) {
      let sumMinutes = 0;
      if (timeDataList == null) {
        return 0;
      }
      for (let i = 0; i < timeDataList.length; i++) {
        sumMinutes += parseFloat(timeDataList[i]);
      }
      return sumMinutes * 15;
    },
    getTimeDataClass: function (isWorkTime, timeItem) {
      let setClass = "";
      if (isWorkTime) {
        if (timeItem == "1") {
          setClass += "workTimeBackcolor";
        }
      } else {
        if (timeItem == "1") {
          setClass += "breakTimeBackcolor";
        }
      }
      return setClass;
    },
    // 勤務時間初期設定の更新
    updateBaseWorkTime: function () {
      //
      // リクエスト
      let me = this;
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.postData(
        config.URL.SETTING + config.PROCESS.UPDATE_BASE_WORK_TIME,
        this.workTime
      )
        .then((response) => {
          if (response.result) {
            me.showUpdateSuccess();
          } else {
            me.showErrorMsg(response.detail.error, "勤務時間初期設定更新");
          }
        })
        .catch((e) => {
          me.showServerError(e);
        })
        .finally(() => {
          me.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
        });
    },
  },
};
</script>

<style>
#setting .main_area {
  min-width: 1500px;
  width: 1600px;
}
#setting th {
  font-weight: 400;
}
#setting .thBaseTime {
  background-color: rgba(255, 255, 255, 0.685);
  text-align: center;
  width: 50px;
}
#setting .midnightBackcolor {
  text-align: center;
  background-color: rgba(199, 199, 199, 0.356);
  width: 50px;
}
#setting .workTimeTypeName {
  width: 75px;
  max-width: 75px;
}

#setting .tdSumTime {
  text-align: center;
  width: 75px;
  max-width: 75px;
}

#setting .workTimeBackcolor {
  background: rgb(151, 208, 255);
}

#setting .breakTimeBackcolor {
  background: rgba(253, 255, 119, 0.726);
}
</style>
