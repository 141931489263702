<template>
  <div id="data-import" class="main" style>
    <header class="sticky-top">
      <BaseHeader
        propHeaderTitle="データ移入 （ 給与システム ＞＞ WEB日報入力 ）"
      ></BaseHeader>
    </header>
    <main class="main_area">
      <b-container fluid>
        <div class="m-4" style="width: 700px">
          <div>
            <b-form-group>
              <b-form-file
                v-model="files"
                :multiple="true"
                :file-name-formatter="formatNames"
                browse-text="選択"
                placeholder="ファイルを選択（ ドロップして選択することもできます。 ）"
                accept=".csv"
              ></b-form-file>
            </b-form-group>
            <h6>選択中のファイル</h6>
            <b-card style="min-height: 200px" class="mt-2">
              <div v-for="(item, index) in files" :key="index">
                ・{{ item.name }}
              </div>
            </b-card>
          </div>

          <div class="mt-3">
            <b-button variant="success" @click="handleImportStart"
              >処理開始</b-button
            >
          </div>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader";
import config from "../const/config";

export default {
  name: "data-import",
  components: {
    BaseHeader,
  },
  data() {
    return {
      files: null,
      visible: true,
    };
  },
  computed: {},
  watch: {},
  mounted: function () {},
  methods: {
    formatNames(files) {
      return `${files.length} ファイルが選択されています。`;
    },
    handleImportStart: function () {
      if (this.isErrorImportFile()) {
        return;
      }
      this.$confirm("インポートを開始します。", "マスタインポート", {
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
      })
        .then((result) => {
          if (result) {
            this.mstImport();
          }
        })
        .catch(() => {});
    },
    isErrorImportFile: function () {
      if (this.files == null || this.files.length <= 0) {
        this.$alert("ファイルが選択されていません。", "", {
          confirmButtonText: "OK",
        });
        return true;
      } else if (this.files.length > config.VALUE.IMPORT_FILE_COUNT) {
        this.$alert("インポート対象のファイル数を超過しています。", "", {
          confirmButtonText: "OK",
        });
        return true;
      }

      return false;
    },
    mstImport: function () {
      let uploadCondition = new FormData();
      this.files.forEach((element) => {
        uploadCondition.append("multipartFile", element);
      });
      this.$store.dispatch(config.STORE.INDEX.ACTION.startLoading);
      this.uploadFilePost(
        config.URL.DATA_IMPORT,
        config.PROCESS.UPLOAD,
        uploadCondition
      )
        .then((response) => {
          if (response.result) {
            this.$alert("完了しました。", "インポート", {
              confirmButtonText: "OK",
            });
          } else {
            this.showErrorAlert(response.detail.error, "インポートエラー");
          }
        })
        .catch(() => {
          this.uncontinuableAlert = config.MESSAGE.SERVER_ERROR;
        })
        .finally(() => {
          this.$store.dispatch(config.STORE.INDEX.ACTION.stopLoading);
          this.files = null;
        });
    },
  },
};
</script>

<style>
#data-import .tdCheckImportTarget {
  width: 20px;
}

#data-import .custom-file {
  height: 10rem;
}
#data-import .custom-file-label {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  height: 10rem;
  cursor: pointer;
}
#data-import .custom-file-label::after {
  height: 10rem;
  line-height: 10rem;
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
