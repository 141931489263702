/**
 * ユーザー情報ストア
 */

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    userId: null,
    userName: null,
    authorityDailyReport: 0,
    authorityPaidEdit: 0,
    authorityInput: 0,
    authorityOutput: 0,
    authoritySharedFolder: 0,
    authorityAdmin: 0,
    authority7: 0,
    authority8: 0
  },
  mutations: {
    setUserInfo(state, payload) {
      state.loggedIn = true;
      state.userId = payload.userId;
      state.userName = payload.userName;

      state.authorityDailyReport = payload.authorityDailyReport;
      state.authorityPaidEdit = payload.authorityPaidEdit;
      state.authorityInput = payload.authorityInput;
      state.authorityOutput = payload.authorityOutput;
      state.authoritySharedFolder = payload.authoritySharedFolder;
      state.authorityAdmin = payload.authorityAdmin;
      state.authority7 = payload.authority7;
      state.authority8 = payload.authority8;
    },
    logOut(state) {
      state.loggedIn = false;
      state.userId = null;
      state.userName = null;

      state.authorityDailyReport = 0;
      state.authorityPaidEdit = 0;
      state.authorityInput = 0;
      state.authorityOutput = 0;
      state.authoritySharedFolder = 0;
      state.authorityAdmin = 0;
      state.authority7 = 0;
      state.authority8 = 0;
    }
  },
  actions: {
  }
};
