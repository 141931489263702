<template>
  <div class="workSelectNote">
    <b-row no-gutters>
      <b-col cols="11">
        <b-form-input
          ref="focusThis"
          :readonly="propIsDisabled"
          type="search"
          autocomplete="off"
          size="sm"
          :value="value"
          @input="onInput"
          @keydown.enter="setEvent"
          @blur="setEvent"
        ></b-form-input>
        <!-- <datalist id="work-note-list">
          <option v-for="(item,index) in propOptions" :key="index">{{ item }}</option>
        </datalist>-->
      </b-col>
      <b-col cols="1">
        <el-dropdown  trigger="click" @command="handleCommand" class="mt-1">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item,index) in propOptions"
              :key="index"
              :command="item"
            >{{item}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "WorkSelectNote",
  props: ["value", "propIsDisabled", "propOptions"],
  data: function () {
    return {
      options: [],
    };
  },
  methods: {
    handleCommand: function (command) {
      this.$emit("input", command);
      this.$refs.focusThis.focus();
    },
    onInput: function (value) {
      this.$emit("input", value);
    },
    setEvent: function () {
      if (!this.propIsDisabled) {
        this.$emit("setEvent");
      }
    },
  },
};
</script>

<style>
.workSelectNote .form-control:disabled,
.workSelectNote .form-control[readonly] {
  background: white;
}

.workSelectNote input :focus {
  border: none !important;
  outline: none !important;
}
</style>